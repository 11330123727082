export const apiKeys = api => ({
  fetch: ({ workspaceId }) => api.get(`/v1/${workspaceId}/keys/`),

  create: ({ workspaceId, title }) =>
    api.post(`/v1/${workspaceId}/keys/`, {
      title
    }),

  update: ({ workspaceId, title, apiKeyId }) =>
    api.put(`/v1/${workspaceId}/keys/${apiKeyId}/`, {
      title
    }),

  revoke: ({ workspaceId, apiKeyId }) =>
    api.post(`/v1/${workspaceId}/keys/${apiKeyId}/revoke/`)
});
