import React, { Fragment, useEffect } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useSelector } from 'react-redux';

import {
  EMPLOYEE,
  ENVIRONMENT_GREEN,
  ENVIRONMENT_PROD,
  ENVIRONMENT_PURPLE,
  ENVIRONMENT_GOLD,
  ENVIRONMENT_STAGING,
  MANAGER
} from 'constants/index';

import { getUser } from 'store/user';
import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { getRouterUrl } from 'store/router';

import useAmplitude from 'hooks/amplitude/use-amplitude';
import config from 'config';
import getOs from 'utils/get-os';

const RU = 'ru';

const ProductFruitsProvider = ({ children }) => {
  const user = useSelector(getUser);
  const employee = useSelector(getUserEmployee);
  const workspace = useSelector(getActiveWorkspace);
  const routerUrl = useSelector(getRouterUrl);

  const amplitude = useAmplitude();

  const mockProductfruitsWorkspaceCodeByEnv = {
    [ENVIRONMENT_PURPLE]: 'gCfZBzfxqJwpr8Iu', // snpAIiy7fWkcqDBC - localhost, gCfZBzfxqJwpr8Iu - purple
    [ENVIRONMENT_GREEN]: 'gCfZBzfxqJwpr8Iu',
    [ENVIRONMENT_GOLD]: 'gCfZBzfxqJwpr8Iu',
    [ENVIRONMENT_STAGING]: '9kj9gzgKMnu8XoDZ',
    [ENVIRONMENT_PROD]: 'QSeuXX3dUiqDcE2W'
  };
  const workspaceCode =
    config.REACT_APP_PRODUCTFRUITS_WORKSPACE_CODE ||
    mockProductfruitsWorkspaceCodeByEnv[config.REACT_APP_ENVIRONMENT];

  const { id, createdAt, email, phone, firstName, lastName, language } = user;
  const { roles = [], workspaceId } = employee || {};
  const { createdAt: workspaceCreatedAt } = workspace || {};

  const isManager = roles.find(r => r.type === MANAGER);
  const resultRoles = roles
    .map(role => role.type)
    .filter(r => (isManager ? r !== EMPLOYEE : true));

  const userInfo = {
    username: id,
    signUpAt: createdAt,
    email,
    firstname: firstName,
    lastname: lastName,
    props: {
      workspace_id: workspaceId,
      workspace_type: 'company',
      workspace_created_at: workspaceCreatedAt,
      phone,
      role: resultRoles,
      os: getOs(),
      user_language: language
    }
  };
  const canShowProductFruits = user.id && employee.id && workspace;

  const forwardEvent = (retryCount = 5) => {
    if (window.productFruitsIsReady && retryCount > 0) {
      return window.productFruits.api.analytics.forwardEvent((event, data) => {
        amplitude.logEvent({ event, params: data });
      });
    }

    return setTimeout(() => forwardEvent(retryCount - 1), 1000);
  };

  const fixOverflow = () => {
    const html = document.body.parentNode;

    if (routerUrl.workspaceId) {
      html.style.overflow = 'hidden';
    } else {
      html.style.overflow = 'auto';
    }
  };

  const changeHintDigit9Icon = () => {
    let container = null;

    setTimeout(() => {
      container = document.querySelector('.productfruits--container');

      if (!container) {
        return changeHintDigit9Icon();
      }

      const iconBase64 =
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNyA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41Nzc2NCAzLjkxNzYyTDAuNTc3NjM3IDcuODE0NzRMMC41Nzc2MzcgMC4wMjA1MDc4TDYuNTc3NjQgMy45MTc2MloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=';
      const stylesheet = new CSSStyleSheet();
      stylesheet.replaceSync(
        `.productfruits--hint-ico-digit-9  { -webkit-mask-image: url("${iconBase64}") !important; -webkit-mask-position-x: 6px !important; mask-image: url("${iconBase64}") !important; mask-position-x: 6px !important;}`
      );

      container.shadowRoot.adoptedStyleSheets = [stylesheet];
      return null;
    }, [1000]);
  };

  useEffect(() => {
    if (canShowProductFruits) {
      forwardEvent();

      changeHintDigit9Icon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowProductFruits]);

  useEffect(() => {
    fixOverflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerUrl.workspaceId]);

  return (
    <>
      {canShowProductFruits && (
        <ProductFruits
          workspaceCode={workspaceCode}
          language={RU}
          user={userInfo}
        />
      )}

      {children}
    </>
  );
};

export default ProductFruitsProvider;
