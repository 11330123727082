import { useForm } from 'react-hook-form';
import moment from 'moment';

import { DATE_TIME_FORMAT_FM } from 'constants/index';

import { convertMessageToString } from 'components/common/comments/converters';

import mapUserToSelectValue from 'utils/map-user-to-select';

const mapValueForNewFields = value => ({
  value: value.id,
  label: value
});

const useFormDefaultValues = (defaultValues, hasInputEstimate, apartValues) => {
  const getInitialValues = () => ({
    ...defaultValues,
    dateStart:
      defaultValues &&
      defaultValues.dateStart &&
      moment(defaultValues.dateStart, DATE_TIME_FORMAT_FM).toDate(),
    dateEnd:
      defaultValues &&
      defaultValues.dateEnd &&
      moment(defaultValues.dateEnd, DATE_TIME_FORMAT_FM).toDate(),

    description: {
      description: convertMessageToString(defaultValues?.description),
      fileList: defaultValues?.fileList || []
    },

    hours: hasInputEstimate ? apartValues.hours.toString() : undefined,
    minutes: hasInputEstimate ? apartValues.minutes.toString() : undefined,

    tags: defaultValues?.tags || [],
    relations: defaultValues?.relations || [],

    responsible: mapUserToSelectValue(defaultValues?.responsible || []),
    controller: defaultValues?.controller
      ? mapValueForNewFields(defaultValues.controller)
      : null,

    project: defaultValues?.project
      ? {
          value: defaultValues.project.id,
          label: defaultValues.project
        }
      : undefined,
    sprint: defaultValues?.sprint
      ? {
          value: defaultValues.sprint.id,
          label: defaultValues.sprint
        }
      : undefined,

    storyPoint: defaultValues?.storyPoint || undefined,
    isStoryPointEstimation: defaultValues?.isStoryPointEstimation || false,

    slaObject: defaultValues?.taskInfo?.slaObject
      ? {
          type: defaultValues.taskInfo.slaObjectType || null,
          item: defaultValues.taskInfo.slaObject
            ? {
                label: defaultValues.taskInfo.slaObject,
                value: defaultValues.taskInfo.slaObject.id
              }
            : null
        }
      : null,
    slaObjectType: defaultValues?.taskInfo?.slaObjectType || null,
    contact: defaultValues?.taskInfo?.contact
      ? mapValueForNewFields(defaultValues.taskInfo.contact)
      : null
  });

  const methods = useForm({
    defaultValues: getInitialValues()
  });

  return methods;
};

export default useFormDefaultValues;
