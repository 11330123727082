import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification, Button, Typography } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
  CHANNEL_ACTIVATED_EVENT,
  NOTIFICATIONS,
  TYPE_DISCUSSION
} from 'constants/index';

import Icon from 'components/common/icon';
import NotificationsView from 'components/notifications-view';
import { convertMessageToNode } from 'components/common/comments/converters';
import { transformChatsToJoinedGroups } from 'components/common/chat/use-chat';
import { getChannelKindByMessageIsFrom } from 'components/contacts-view/utils';

import {
  getWorkspaceId,
  setActiveId,
  getAllWorkspaces,
  getIsCurrentWorkspaceLoaded,
  getIsWorkspacesLoaded
} from 'store/workspace';
import {
  getQueueNotifications,
  closeQueueNotification
} from 'store/notifications';
import {
  fetchUnreadedNotificationsCount,
  getOperatorIsReady
} from 'store/operator';
import { getIsOnlyCompanyProfile } from 'store/billing';

import { useWebsocketOperatorContext } from 'providers';
import useNotificationsService from 'services/notifications';
import useRoutesService from 'services/routes';
import { socket } from 'socket';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import getActiveWorkspaces from 'utils/get-active-workspaces';

import { getRouterUrl } from '../../../store/router';

import styles from './notifications-provider.module.scss';

const { Text, Paragraph } = Typography;

const KEY_QUEUE = `queue-notification`;

export const NotificationsProvider = () => {
  const dispatch = useDispatch();

  const workspaces = useSelector(getAllWorkspaces);
  const workspaceId = useSelector(getWorkspaceId);
  const notifications = useSelector(getQueueNotifications);
  const routerUrl = useSelector(getRouterUrl);
  const isReady = useSelector(getOperatorIsReady);
  const isOnlyCompanyPofile = useSelector(getIsOnlyCompanyProfile);
  const isCurrentWorkspaceLoaded = useSelector(getIsCurrentWorkspaceLoaded);
  const isWorkspacesLoaded = useSelector(getIsWorkspacesLoaded);

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [hovered, setHovered] = useState(false);

  const socketOperator = useWebsocketOperatorContext();
  const routes = useRoutesService();
  const notificationsService = useNotificationsService();
  const amplitude = useAmplitude();

  const el = useRef();

  const { t } = useTranslation(['NotificationsBell', 'Common']);

  const workspacesList = getActiveWorkspaces(workspaces);
  const roomUuids = workspacesList.reduce((acc, curr) => {
    const uuids = transformChatsToJoinedGroups(curr.user.chats);

    return [...acc, ...uuids];
  }, []);

  const totalItems = notifications.length;
  const activeItem = notifications[activeItemIndex];

  const next = () => setActiveItemIndex(index => index + 1);
  const prev = () => setActiveItemIndex(index => index - 1);

  const fetchUnreadedCount = () =>
    dispatch(fetchUnreadedNotificationsCount({ roomUuids }));

  const onRead = useCallback(
    () =>
      socketOperator.readNotificationMessage({
        roomUuid: activeItem.roomUuid,
        messageUuid: activeItem.uuid,
        channelKind: getChannelKindByMessageIsFrom(activeItem.isFrom),
        channelUuid: activeItem.channelId,
        isRead: true
      }),
    [activeItem, socketOperator]
  );

  const closeNotification = useCallback(
    n => dispatch(closeQueueNotification(n)),
    [dispatch]
  );

  const onCloseAll = useCallback(() => {
    notifications.map(item => closeNotification(item));
    setActiveItemIndex(0);
    notification.close(KEY_QUEUE);
  }, [closeNotification, notifications]);

  const onClickNotification = useCallback(() => {
    const isDiscussionNotification = activeItem.entityType === TYPE_DISCUSSION;

    if (!activeItem.isRead && activeItem.uuid && !isDiscussionNotification) {
      onRead();
    }

    notificationsService.handle(activeItem);

    onCloseAll();
  }, [activeItem, notificationsService, onCloseAll, onRead]);

  const onClose = useCallback(() => {
    closeNotification(activeItem);

    if (activeItemIndex > 0) {
      prev();
    }

    if (totalItems === 1) {
      notification.close(KEY_QUEUE);
    }
  }, [activeItem, activeItemIndex, closeNotification, totalItems]);

  const toSettings = useCallback(() => {
    const { workspaceId: wid } = activeItem.info;
    const redirectWorkspaceId = workspaces[wid] ? wid : workspaceId;

    dispatch(setActiveId({ workspaceId: redirectWorkspaceId }));

    routes.toUserWorkspace({
      employeeId: workspaces[redirectWorkspaceId].user.id,
      view: NOTIFICATIONS
    });

    onCloseAll();
  }, [activeItem, dispatch, onCloseAll, routes, workspaceId, workspaces]);

  const onMouseEnter = useCallback(() => {
    setHovered(true);

    if (el.current) {
      el.current.classList.remove(styles.opacity);
    }
  }, []);

  const onMouseLeave = useCallback(() => setHovered(false), []);

  const openNotifications = useCallback(
    () =>
      notification.open({
        key: KEY_QUEUE,
        className: styles.root,
        top: 54,
        style: {
          width: 420,
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 384 - 420
        },
        placement: 'topRight',
        description: (
          <div
            ref={el}
            className={classnames(styles.card)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className={classnames(styles.flexStart, styles.body)}>
              <div
                className={classnames(styles.flexStart, styles.justifyBetween)}
              >
                <Paragraph
                  ellipsis={{ rows: 4 }}
                  className={styles.content}
                  onClick={onClickNotification}
                >
                  {convertMessageToNode(
                    (activeItem && activeItem.content) || ''
                  )}
                </Paragraph>
                <div className={styles.flexWrap}>
                  <Button
                    shape="circle"
                    type="link"
                    className={styles.btn}
                    style={{ marginRight: 5 }}
                    onClick={toSettings}
                  >
                    <Icon type="setting" size={16} className={styles.icon} />
                  </Button>
                  <Button
                    shape="circle"
                    type="link"
                    className={styles.btn}
                    onClick={onClose}
                  >
                    <Icon type="close" size={16} className={styles.icon} />
                  </Button>
                </div>
              </div>

              {totalItems > 1 && (
                <div
                  className={classnames(
                    styles.flexWrap,
                    styles.justifyBetween,
                    styles.pagination
                  )}
                >
                  <div className={styles.arrowNavContainer}>
                    <Button
                      onClick={prev}
                      className={styles.arrowNav}
                      disabled={activeItemIndex < 1}
                      icon={<LeftOutlined />}
                      size="small"
                      type="text"
                    />
                    <Text className={styles.text}>
                      {t('CurrentFromTotal', {
                        ns: 'Common',
                        current: activeItemIndex + 1,
                        total: totalItems
                      })}
                    </Text>
                    <Button
                      onClick={next}
                      className={styles.arrowNav}
                      disabled={activeItemIndex >= notifications.length - 1}
                      icon={<RightOutlined />}
                      size="small"
                      type="text"
                    />
                  </div>
                  <Button size="small" type="link" onClick={onCloseAll}>
                    {t('CloseAllNotificationsBtn')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ),
        duration: 0
      }),
    [
      activeItem,
      onClickNotification,
      onClose,
      onCloseAll,
      onMouseEnter,
      onMouseLeave,
      toSettings,
      totalItems
    ]
  );

  useEffect(() => {
    socket.on('new-user-notification', ({ templateUid }) => {
      if (+templateUid === 128) {
        amplitude.logEvent({ event: CHANNEL_ACTIVATED_EVENT });
      }
    });
  }, []);

  useEffect(() => {
    if (isCurrentWorkspaceLoaded || isWorkspacesLoaded) {
      fetchUnreadedCount();
    }
  }, [isCurrentWorkspaceLoaded, isWorkspacesLoaded]);

  useEffect(() => {
    if (isReady && roomUuids) {
      socketOperator.joinChannels(roomUuids);
    }

    return () => {
      if (isReady) {
        socketOperator.leaveChannels(roomUuids, true);
      }
    };
  }, [isReady]);

  useEffect(() => {
    if (totalItems > 0 && !isOnlyCompanyPofile) {
      openNotifications();
    } else {
      notification.close(KEY_QUEUE);
    }
  }, [totalItems, activeItemIndex]);

  useEffect(() => {
    let timerClose;

    const timer = setTimeout(() => {
      if (el.current && !hovered && routerUrl.isTabVisible) {
        el.current.classList.add(styles.opacity);

        timerClose = setTimeout(() => {
          onCloseAll();
        }, 1000);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerClose);
    };
  }, [totalItems, hovered, routerUrl.isTabVisible]);

  return <NotificationsView />;
};

export default NotificationsProvider;
