import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';
import { CopyLink as CopyContent } from 'components/common/copy-link';

import { getUserEmployee } from 'store/workspace';

import { useScrollBottom } from 'hooks';
import { useAgentSupport } from 'providers/agent-support-provider';

import { AgentSupportPrompts } from '../agent-support-prompts';
import { AgentSupportIconWrap } from '../agent-support-icon-wrap';

import styles from './AgentSupportMessageThread.module.scss';

const { Paragraph, Text } = Typography;

export const AgentSupportMessageThread = () => {
  const { t } = useTranslation(['AIAssistant', 'AgentSupport']);

  const employee = useSelector(getUserEmployee);

  const { isTyping, sendPrompt, messages, prompts } = useAgentSupport();

  const [scrollRef, , scrollToBottom] = useScrollBottom({
    dependencies: [messages.length, isTyping]
  });

  const formatTime = createdAt => {
    const now = moment();
    const messageTime = moment(createdAt);
    const isToday = now.isSame(messageTime, 'day');

    if (isToday) {
      return messageTime.format(TIME_FORMAT);
    }

    return messageTime.format(DATE_FORMAT);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  return (
    <div ref={scrollRef} className={styles.root}>
      {!messages.length && (
        <AgentSupportPrompts sendPrompt={sendPrompt} prompts={prompts} />
      )}

      {messages.map(({ text, isUser, createdAt, id }, index) => (
        <Fragment key={id}>
          {isUser && (
            <div className={styles.meMessageWrap}>
              <CopyContent
                link={text}
                tooltipClassName={styles.copyTooltip}
                iconSize={20}
                iconColor="black-55"
              />

              <div className={styles.meMessage}>
                <Paragraph className={styles.username} weight="semibold">
                  {employee.lastName} {employee.firstName}
                </Paragraph>

                <Text>{text}</Text>

                <Paragraph
                  className={styles.time}
                  size="small"
                  color="black-55"
                >
                  {formatTime(createdAt)}
                </Paragraph>
              </div>
            </div>
          )}

          {!isUser && (
            <div className={styles.agentMessageWrap}>
              <AgentSupportIconWrap size="big" />

              <div className={styles.agentMessage}>
                <Paragraph className={styles.username} weight="semibold">
                  {t('AgentSupportTitle', { ns: 'AgentSupport' })}
                </Paragraph>

                <Text>
                  {text.split('\n').map((line, i) => (
                    <Fragment key={i}>
                      <Text>{line}</Text>

                      <br />
                    </Fragment>
                  ))}
                </Text>

                <Paragraph
                  className={styles.time}
                  size="small"
                  color="black-55"
                >
                  {formatTime(createdAt)}
                </Paragraph>
              </div>

              <CopyContent
                tooltipClassName={styles.copyTooltip}
                className={styles.copyContent}
                link={text}
                iconSize={20}
                iconColor="black-55"
              />
            </div>
          )}

          {isTyping && index === messages.length - 1 && (
            <div className={styles.agentTyping}>
              <Text className={styles.dots}>...</Text>

              <Text color="black-45">
                {t('AgentSupportTyping', { ns: 'AgentSupport' })}
              </Text>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
