import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  SCHEDULER_ON,
  SCHEDULER_OFF,
  SCHEDULER_NONE,
  TYPE_TASK_TEMPLATE
} from 'constants/index';

import Icon from 'components/common/icon';
import { useTheme } from 'components/common/theme';
import ActionsDropdown from 'components/common/actions';
import ShareTemplateModal from 'components/common/share-template-modal';
import { TagList } from 'components/common/tags';

import { shareTemplate } from 'store/tasks';
import { getTagsByEntity } from 'store/tags';

import Repeating from './repeating';
import { getTaskIconData } from '../../utils';

import styles from './styles.module.scss';

const { Title } = Typography;

const getRepeatingState = schedulerConfig => {
  switch (true) {
    case schedulerConfig && schedulerConfig.isActive:
      return SCHEDULER_ON;

    case schedulerConfig && !schedulerConfig.isActive:
      return SCHEDULER_OFF;

    default:
      return SCHEDULER_NONE;
  }
};

export const Card = ({ template, onEdit, onDelete, onClick }) => {
  const { variables } = useTheme();

  const [showShareModal, setShowShareModal] = useState(false);

  const { t } = useTranslation('AddTaskType');

  const { id, title, kind, schedulerConfig, isScheduler, accessType } =
    template;

  const tags = useSelector(state =>
    getTagsByEntity(state)({ entityType: TYPE_TASK_TEMPLATE, entityId: id })
  );

  const { iconData, limitedIconData } = getTaskIconData({
    kind,
    accessType
  });

  const onClickCard = () => {
    if (isScheduler) {
      return onEdit();
    }

    return onClick(tags);
  };

  const actions = [
    {
      allow: !isScheduler,
      title: t('EditTaskTemplateAction'),
      onClick: onEdit
    },
    {
      allow: isScheduler,
      title: t('CopyRepetitiveTaskAction'),
      onClick: () => onClick(tags)
    },
    {
      allow: true,
      title: t('ShareTaskTemplateAction'),
      onClick: () => setShowShareModal(true)
    },
    {
      allow: true,
      title: t('DeleteTaskTemplateAction'),
      style: { color: variables.color.red },
      onClick: onDelete,
      isConfirm: true,
      confirmText: t('DeleteTemplatePopoverHeading'),
      placement: 'topLeft'
    }
  ];

  return (
    <>
      <div
        className={styles.root}
        onClick={onClickCard}
        data-qa="qa-bzqwinlt8m0ahhf"
      >
        <div className={styles.header} data-qa="qa-qx6hdav7eqewmng">
          <Icon
            component={limitedIconData ? limitedIconData.icon : iconData.icon}
          />

          <Repeating state={getRepeatingState(schedulerConfig)} />

          <div
            onClick={event => event.stopPropagation()}
            className={styles.actions}
            data-qa="qa-fl0x1c4in6i6h7f"
          >
            <ActionsDropdown actions={actions} />
          </div>
        </div>

        <Title
          level={2}
          ellipsis={{ rows: 2 }}
          className={styles.title}
          data-qa="qa-alrfk2bpb44fpj5"
        >
          {title}
        </Title>

        <TagList
          entityType={TYPE_TASK_TEMPLATE}
          entityId={id}
          displayedCount={1}
          shortAddButton
        />
      </div>

      <ShareTemplateModal
        id={id}
        visible={showShareModal}
        share={shareTemplate}
        onClose={() => setShowShareModal(false)}
      />
    </>
  );
};

Card.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    kind: PropTypes.string,
    schedulerConfig: PropTypes.object
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func
};

Card.defaultProps = {
  template: {},
  onEdit: () => {},
  onDelete: () => {},
  onClick: () => {}
};

export default Card;
