import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { EMAIL } from 'constants/index';

import {
  hasContent as checkHasContent,
  convertMessageToNode,
  convertMessageToString,
  renderDescriptions
} from 'components/common/comments/converters';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import sanitizeHTML from 'utils/sanitize-html';

import FullEmailModal from './full-email-modal';
import Sender from './sender';

import styles from './message.module.scss';

const Content = ({
  content,
  htmlContent,
  subject,
  isFrom,
  isComment,
  logContent,
  isLog,
  isGptMessage,
  fileList,
  sender,
  isNotificationSource,
  headerWidth,
  showHistory,
  setShowHistory,
  hasLogHistory
}) => {
  const [visibleFullEmail, setVisibleFullEmail] = useState(false);

  const { t } = useTranslation(['Contacts', 'Common']);

  const nodeContent = convertMessageToNode(content);
  const stringContent = convertMessageToString(content);
  const parsedHtml =
    htmlContent &&
    htmlContent
      .replace(/\\xa0/g, ' ')
      .replace(/(\\[a-z])|(\[')|('\])|(\\')|(a0)/g, '');

  const htmlString = useMemo(() => {
    if (htmlContent) {
      // eslint-disable-next-line no-undef
      const parser = new DOMParser();
      const doc = parser.parseFromString(parsedHtml, 'text/html');

      const mainContent = doc.getElementsByTagName('body')[0];

      return mainContent.innerHTML;
    }

    return null;
  }, [htmlContent, parsedHtml]);

  const hasContent = checkHasContent(content) || !!subject;

  const renderedContent = useMemo(() => {
    if (isFrom === EMAIL) {
      return (
        <>
          {parsedHtml ? (
            <div
              className={styles.htmlContent}
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(htmlString)
              }}
            />
          ) : (
            <Typography.Text ellipsis>{stringContent}</Typography.Text>
          )}

          <Button
            type="link"
            className={styles.openEmail}
            onClick={() => setVisibleFullEmail(true)}
          >
            {t('OpenFull')}
          </Button>
        </>
      );
    }

    if (isLog || isGptMessage) {
      return renderDescriptions(logContent);
    }

    return nodeContent;
  }, [
    htmlString,
    isFrom,
    isGptMessage,
    isLog,
    logContent,
    nodeContent,
    parsedHtml,
    stringContent,
    t
  ]);

  const textIndent =
    isLog && headerWidth > 0 ? `${headerWidth + 6}px` : undefined;

  if (!hasContent) {
    return fileList.length ? null : t('FileRemoved', { ns: 'Common' });
  }

  return (
    <>
      <div
        className={classnames(styles.content, {
          [styles.isLogWithHistory]: isLog && hasLogHistory
        })}
      >
        {subject && !isComment && (
          <Typography.Paragraph className={styles.subject}>
            {`${t('EmailTheme')}: ${subject}`}
          </Typography.Paragraph>
        )}

        <div
          className={classnames(styles.contentText, {
            [styles.isLog]: isLog
          })}
          style={{ textIndent }}
        >
          {isNotificationSource && sender && <Sender sender={sender} />}

          {renderedContent}
        </div>

        {hasLogHistory && !showHistory && (
          <Button
            type="link"
            size="small"
            className={styles.historyOpenBtn}
            onClick={() => setShowHistory(true)}
            style={{ fontWeight: 400 }}
          >
            {t('SeeDetails', { ns: 'Common' })}
          </Button>
        )}
      </div>

      <FullEmailModal
        nodeContent={nodeContent}
        htmlContent={parsedHtml}
        subject={subject}
        visible={visibleFullEmail}
        onClose={() => setVisibleFullEmail(false)}
      />
    </>
  );
};

export default Content;
