import { useEffect, useState, useRef } from 'react';

export const useMountedState = initialValue => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [state, setState] = useState(initialValue);

  const setMountedState = value => {
    if (isMountedRef.current) {
      setState(value);
    }
  };
  return [state, setMountedState];
};
