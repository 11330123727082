export const LOGIN_EVENT = 'login';
export const SIGN_UP_EVENT = 'sign_up';

export const EDIT_TASK_EVENT = 'edit_task';
export const DELETE_TASK_EVENT = 'delete_task';
export const DELETE_SUBTASK_EVENT = 'delete_subtask';
export const EDIT_TASK_TEMPLATE_EVENT = 'edit_task_template';
export const EDIT_SUBTASK_EVENT = 'edit_subtask';
export const ADD_TASK_EVENT = 'add_task';
export const ADD_SUBTASK_EVENT = 'add_subtask';
export const ADD_TASK_TEMPLATE_EVENT = 'add_task_template';
export const DELETE_TASK_TEMPLATE_EVENT = 'delete_task_template';
export const ADD_TASK_COMMENT_EVENT = 'add_task_comment';
export const FINISH_SUBTASK_EVENT = 'finish_subtask';
export const FINISH_TASK_EVENT = 'finish_task';
export const ADD_TASK_DESCRIPTION_EVENT = 'add_task_description';
export const ACCESS_LEVEL_EVENT = 'access_level';

export const ADD_ORDER_EVENT = 'add_order';
export const EDIT_ORDER_EVENT = 'edit_order';
export const ADD_ORDER_COMMENT_EVENT = 'add_order_comment';
export const FINISH_ORDER_EVENT = 'finish_order';
export const EDIT_ORDER_RATING_EVENT = 'edit_order_rating';
export const ADD_ORDER_RATING_EVENT = 'add_order_rating';

export const ADD_CONTACT_EVENT = 'add_contact';
export const CONTACT_INVITATION_EVENT = 'contact_invitation';
export const COLLEAGUE_INVITATION_EVENT = 'colleague_invitation';

export const JOIN_REQUEST_ACCEPTED_EVENT = 'join_request_accepted';
export const JOIN_REQUEST_REJECTED_EVENT = 'join_request_rejected';
export const JOIN_REQUEST_SENT_EVENT = 'join_request_sent';

export const ADD_CHANNEL_EVENT = 'add_channel';
export const CHANNEL_ACTIVATED_EVENT = 'channel_activated';

export const EDIT_COMPANY_DETAILS_EVENT = 'edit_company_details';
export const EDIT_PRICE_LIST_EVENT = 'edit_price_list';
export const PUBLIC_CONTRACT_ON_EVENT = 'public_contract_on';

export const EDIT_PROJECT_EVENT = 'edit_project';
export const ADD_PROJECT_EVENT = 'add_project';
export const DELETE_PROJECT_EVENT = 'delete_project';
export const ADD_ORDER_CONTROLLER_EVENT = 'add_order_controller';

export const REG_BOSS_INVITATION_EVENT = 'reg_boss_invitation';
export const REG_SUBORDINATE_INVITATION_EVENT = 'reg_subordinate_invitation';
export const MANUAL_COLLEAGUE_INVITATION_EVENT = 'manual_colleague_invitation';

export const PHONE_VERIFICATION_START_EVENT = 'phone_verification_start';
export const PHONE_VERIFICATION_FINISH_EVENT = 'phone_verification_finish';

export const CLICK_TASK_ATTACHMENT_EVENT = 'click_task_attachment';
export const CLICK_ORDER_ATTACHMENT_EVENT = 'click_order_attachment';

export const CLICK_ATTACH_FILE_EVENT = 'click_attach_file';
export const CLICK_CREATE_FILE_EVENT = 'click_create_file';
export const CLICK_ADD_FILE_EVENT = 'click_add_file';

export const CLICK_SUPPORT_SECTION_EVENT = 'click_support_section';

export const STARTING_QUESTION = 'starting_question';
export const VIDEO_WATCHED = 'video_watched';

// property name
export const CO_EXECUTOR = 'co_executor';
export const TASK_TAG = 'task_tag';

export const MAIN_MENU_CLICK = 'main_menu_click';

// AI assistant
export const ASSISTANT_RICHARD_INVOCATION = 'assistant_richard_invocation';
export const AI_CHAT_PROMPT = 'ai_chat_prompt';

// Workspace Google Drive
export const EDITABLE_FILE_UPLOAD = 'editable_file_upload';

export const CHAT_HISTORY_OPEN = 'chat_history_open';

export const AI_TECHSUPPORT_AGENT = 'ai_techsupport_agent';

export const WS_CONNECTION_LIMITED_WARNING_SHOWN =
  'ws_connection_limited_warning_shown';

// object sla
export const ADD_REQUEST_WITH_OBJECT_SLA = 'add_request';
