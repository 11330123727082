import { useSelector } from 'react-redux';

import {
  DETAILS_ASSET,
  DETAILS_REQUEST,
  DETAILS_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_EMPLOYEE,
  TYPE_ORDER,
  TYPE_PROJECT,
  TYPE_REQUEST,
  TYPE_TASK
} from 'constants/index';

import { getRouterLocation } from 'store/router';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

const useEntityLink = ({ entityType, entityId }) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });
  const location = useSelector(getRouterLocation);
  const { modal, selectedRequest } = location.query;

  const createLink = (query, isOrigin) => {
    const url = `${
      isOrigin ? window.location.origin : window.location.pathname
    }${query}`;

    return url;
  };

  const openInNewTab = url => {
    window.open(url, '_blank');
  };

  const getLink = () => {
    switch (entityType) {
      case TYPE_TASK:
        if (modal === DETAILS_TASK && selectedRequest === entityId.toString()) {
          return null;
        }
        return createLink(
          modals.tasks.showDetails({
            id: entityId
          })
        );
      case TYPE_REQUEST:
        if (
          modal === DETAILS_REQUEST &&
          selectedRequest === entityId.toString()
        ) {
          return null;
        }
        return createLink(
          modals.requests.showDetails({
            id: entityId
          })
        );
      case TYPE_CONTACT:
        return createLink(routes.toContact({ id: entityId }), true);
      case TYPE_ASSET:
        if (
          modal === DETAILS_ASSET &&
          selectedRequest === entityId.toString()
        ) {
          return null;
        }
        return createLink(
          modals.assets.showDetails({
            id: entityId
          })
        );
      case TYPE_ORDER:
        return routes.toOrderStatusFromLog({
          orderStatusId: entityId,
          isReplace: true
        });
      case TYPE_PROJECT:
        return createLink(routes.toProject(entityId), true);
      case TYPE_EMPLOYEE:
        return createLink(routes.toEmployee(entityId), true);
      default:
        return null;
    }
  };

  return { entityLink: getLink(), openInNewTab };
};

export default useEntityLink;
