import React from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import Form from 'components/common/feedback-modal/form';
import Typography from 'components/common/typography';
import {
  initialLoadingAnimation,
  InterviewIcon
} from 'components/common/icons';
import Icon from 'components/common/icon';
import {
  validateMaxLength,
  validateMinLength
} from 'components/common/hook-form';

import disableActionsInExternalServices from 'utils/disable-actions-in-external-services';

import useFeedback from './useFeedback';

import styles from './FeedbackPanel.module.scss';

const { Title, Paragraph, Text } = Typography;

const STAR_COUNT = 5;

export const FeedbackPanel = () => {
  const { t } = useTranslation(['ApplicationFeedback', 'Errors']);

  const {
    isLoading,
    isLoadingSubmit,
    data,
    onSubmitFeedback,
    setRating,
    rating
  } = useFeedback();

  if (isLoading) {
    return (
      <div className={styles.wrapLoader}>
        <Lottie
          animationData={initialLoadingAnimation}
          loop
          className={styles.loader}
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {data && (
        <>
          {data.rating && (
            <Icon
              className={styles.successIcon}
              component={InterviewIcon}
              style={{ marginBottom: 10 }}
            />
          )}

          <Title level={1} weight="bold" className={styles.title}>
            {t(data.rating ? 'ThankForFeedbackHeading' : 'LeftFeedbackHeading')}
          </Title>

          {data.rating ? (
            <div className={styles.successInfo}>
              <Paragraph>{data.entityTitle}</Paragraph>

              <Paragraph className={styles.ratingInfo}>
                <Text className={styles.myRating}>
                  {t('ThankForFeedbackDesc')}
                </Text>{' '}
                <Icon color="yellow" type="star" theme="filled" size={32} />
                <Text className={styles.ratingNumber}>{data.rating}</Text>
              </Paragraph>

              <Paragraph className={styles.content}>{data.content}</Paragraph>
            </div>
          ) : (
            <div className={styles.successInfo}>
              <Paragraph style={{ marginBottom: 24 }}>
                {t('LeftFeedbackDesc')}
              </Paragraph>
            </div>
          )}

          {!data.rating && (
            <Form
              starCount={STAR_COUNT}
              rating={rating}
              setRating={setRating}
              onSubmit={onSubmitFeedback}
              btnTitle={t('SendBtn')}
              btnProps={{
                loading: isLoadingSubmit,
                className: styles.btnSubmit
              }}
              inputProps={{
                placeholder: t('FeedbackPlchldr'),
                className: styles.input,
                rules: {
                  required:
                    rating < STAR_COUNT
                      ? t('RequiredField', { ns: 'Errors' })
                      : false,
                  minLength: validateMinLength(10),
                  maxLength: validateMaxLength(1000)
                }
              }}
              disabledStars={disableActionsInExternalServices({
                workspaceId: data.workspaceId
              })}
            />
          )}
        </>
      )}
    </div>
  );
};
