import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { TYPE_TASK, VIDEOS } from 'constants/index';

import VideoBtn from 'components/common/video-btn';
import { transformRelationsToSelectValue } from 'components/common/controls/custom-select/relations-select/utils';
import Typography from 'components/common/typography';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import {
  fetchTemplateRelationsLocal,
  fetchTemplateAgreementStepsLocal
} from 'store/tasks';

import useData from './use-data';
import List from './list';
import Card from './card';
import TemplateEditorDrawer from './editor-drawer';

import styles from './template-view.module.scss';

export const TemplateView = ({
  onSubmit,
  drawerVisible,
  isSchedulerTemplates,
  onlyTaskKindAllowed,
  values,
  showBacklogWarning
}) => {
  const {
    data: { templates = [], isLoading, currentlyEditableTemplate },
    deleteTemplate,
    editTemplate,
    onEditDrawerClose
  } = useData({ active: drawerVisible, isSchedulerTemplates });

  const dispatch = useDispatch();

  const { t } = useTranslation('AddTaskType');

  const editDrawerVisible = !!currentlyEditableTemplate;

  const createTaskFromTemplate = async template => {
    const relations = await dispatch(
      fetchTemplateRelationsLocal({ id: template.id })
    );

    const { agreementSteps } = await dispatch(
      fetchTemplateAgreementStepsLocal({ id: template.id })
    );

    const transformSteps = (agreementSteps || []).map((step, index) => ({
      isEdit: true,
      employees: step[index].map(e => ({ value: e.id, label: e }))
    }));

    onSubmit({
      ...template,
      fileList: (template.fileList || []).map(addValidityDateState),
      dateEnd: values.taskInfo ? values.dateEnd : undefined,
      dateStart: undefined,
      schedulerConfig: {
        ...(template.schedulerConfig || {}),
        isActive: false
      },
      agreementSteps: transformSteps,
      relations: transformRelationsToSelectValue(relations),
      parentAccessType: template.accessType
    });
  };

  const filteredTemplates = templates.filter(({ kind }) =>
    onlyTaskKindAllowed || values.taskInfo ? kind === TYPE_TASK : true
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      {values.taskInfo && (
        <Alert
          message={t('SLATaskTypeWarning')}
          type="warning"
          className={styles.alertSla}
        />
      )}

      {showBacklogWarning && (
        <Alert
          message={t('SprintsAndBacklogWarning')}
          type="warning"
          className={styles.alertSla}
        />
      )}

      {!!filteredTemplates.length && (
        <List>
          {filteredTemplates.map(template => (
            <Card
              template={template}
              key={template.id}
              data-qa="qa-qq9rh54uy1d4olp"
              onEdit={() => editTemplate(template)}
              onDelete={() => deleteTemplate(template)}
              onClick={tags => createTaskFromTemplate({ ...template, tags })}
            />
          ))}
        </List>
      )}

      {!isLoading && !filteredTemplates.length && (
        <>
          <VideoBtn slug={VIDEOS.whatIsTemplate} className={styles.videoBtn} />

          <Typography.Text>
            {isSchedulerTemplates ? (
              <>
                {t('NoRepetitiveTasksDesc')}
                <br />
                {t('AddRepetitiveTaskDesc')}
              </>
            ) : (
              <>
                {t('NoTemplatesHeading')}
                <br /> {t('NoTemplatesDescMob')}
              </>
            )}
          </Typography.Text>
        </>
      )}

      <TemplateEditorDrawer
        visible={editDrawerVisible}
        template={currentlyEditableTemplate}
        onClose={onEditDrawerClose}
      />
    </>
  );
};

TemplateView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  drawerVisible: PropTypes.bool,
  isSchedulerTemplates: PropTypes.bool,
  values: PropTypes.object
};

TemplateView.defaultProps = {
  drawerVisible: false,
  isSchedulerTemplates: false,
  values: {}
};

export default memo(TemplateView);
