import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message as antdMessage, notification } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';

import {
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_UNASSEMLED,
  CONTACT_STATUS_VERIFIED,
  DIALOG_TYPE_COMMENT,
  DIALOG_TYPE_LOG,
  NOTIFICATION,
  ONLINE_CHAT,
  TYPE_CONTACT,
  TYPE_EMPLOYEE,
  UPSERVICE,
  WEBSITE,
  WIDGET
} from 'constants/index';

import { getUserEmployee } from 'store/workspace';
import { getTabKey } from 'store/router';
import {
  getContacts,
  fetchContactByChatLocal,
  editContactByRoomUuid
} from 'store/contacts';
import { fetchChannelsLocal, fetchUserChannelGroups } from 'store/channels';
import { getIsOnlyUserProfile } from 'store/user';
import {
  getChatMessages,
  getIsNeedToReconnect,
  getSendingMessageData,
  joinChatRooms,
  setEntityChatIsMessagesLoading,
  chatMessageNew,
  setIsNeedToReconnect,
  setSendingMessageData,
  setEntityChatHasNewMessages,
  chatMessageRead,
  messageReactionSet,
  messageReactionUnset,
  setOperatorIsReady,
  getOperatorIsReady,
  sendDraftMessage,
  notificationMessageRead,
  allNotificationMessagesRead,
  setEntityChatRoomUuids,
  getSendingDraftMessages,
  chatMessageNewOk,
  setIsNotSendedMessage,
  failedRemovedMessage,
  employeeTyping,
  notificationsCount
} from 'store/operator';
import { getUILanguage, getUITimeZone } from 'store/ui';

import useAmplitude from 'hooks/amplitude/use-amplitude';
import { config } from 'config';
import { getFullName } from 'utils/get-fio';
import useAudio from 'hooks/common/use-audio';
import { socket as sioSocket } from 'socket';
import { showNoticeMessage, WARNING_NOTICE_TYPE } from 'services/notice';

import newMessageAudioUrl from './new-message.mp3';
import { useAlertExternalChatNewMessage } from './hooks/useAlertExternalChatNewMessage';

const CONTACT_INFO_FIELDS = {
  name: 'firstName',
  phone: 'phone',
  email: 'email'
};

const MESSAGE_REACTION_KIND_SET = 'set';
const MESSAGE_REACTION_KIND_UNSET = 'unset';

const WEBSOCKET_NORMAL_CLOSURE_CODE = 1000;
const WEBSOCKET_SERVICE_RECONNECT_CODE = 4000;

const MESSAGE_SENDING_TIMEOUT_MS = 10000;
const MAX_CONNECTION_RETRY_COUNT = 10;

const PING = 'ping';
const PONG = 'pong';
const PING_INTERVAL = 5000;
const PING_TIMEOUT = 2000;

const MESSAGE_WITHOUT_RESPONSE_ERROR =
  'Message was sent but no response was received';
const NOTIFICATION_WITHOUT_RESPONSE_ERROR =
  'Notification was sent but no response was received';
const NO_JOIN_ROOMS_ERROR =
  'Message was not sent because the room is not connected';

const HEART_BEAT_ERROR =
  'WebSocket heartbeat failed: Pong response was not received';
const RECONNECT_ATTEMPT_FAILED = 'reconnect_attempt_failed';
const HEARTBEAT_FAILED = 'heartbeat_failed';

// events
const CHAT_MESSAGE_NEW = 'chat-message-new';
const CHAT_MESSAGE_NEW_OK = 'chat-message-new-ok';

const REMOVE_FAILED_MESSAGE = 'remove-failed-message';
const FAILED_MESSAGE_REMOVED = 'failed-message-removed';

const SEND_CHAT_MESSAGE = 'send-chat-message';

const NEED_CONTACT_INFO = 'need-contact-info';
const NEW_CONTACT_INFO = 'new-contact-info';

const CLIENT_STATUS_CHANGED = 'client-status-changed';

const NEED_FEEDBACK = 'need-feedback';

const READ_CHAT_MESSAGE = 'read-chat-message';
const CHAT_MESSAGE_READ = 'chat-message-read';

const JOIN_CHANNELS = 'join-channels';
const LEAVE_CHANNELS = 'leave-channels';

const MESSAGE_REACTION = 'message-reaction';
const MESSAGE_REACTION_SET = 'message-reaction-set';
const MESSAGE_REACTION_UNSET = 'message-reaction-unset';

const READ_NOTIFICATION_MESSAGE = 'read-notification-message';
const NOTIFICATION_MESSAGE_READ = 'notification-message-read';

const READ_ALL_NOTIFICATION_MESSAGES = 'read-all-notification-messages';
const ALL_NOTIFICATION_MESSAGES_READ = 'all-notification-messages-read';

const SET_LANGUAGE = 'set-language';
const SET_TIME_ZONE = 'set-time-zone';

const EMPLOYEE_TYPING = 'employee-typing';

// sio events
const USER_CHANNELS_ADDED = 'user-channels-added';
const USER_CHANNELS_REMOVED = 'user-channels-removed';

const GET_NOTIFICATIONS_COUNT = 'get_notifications_count';
const NOTIFICATIONS_COUNT = 'notifications_count';
const ALERT_EXTERNAL_CHAT_NEW_MESSAGE = 'alert-external-chat-new-message';

const getAppId = kind => {
  if (kind === WEBSITE) {
    return WIDGET;
  }

  return kind;
};

// ENTITY CHECK
// DELET contactId
const transformEntityToSend = ({ id, type, chats }) => ({
  contactId: id,
  entityId: id,
  entityType: type,
  chats: chats.map(chat => ({
    appId: getAppId(chat.channelKind),
    roomUuid: chat.uuid
  }))
});

const mapFileList = ({
  fileId,
  id,
  response,
  name,
  title,
  type,
  mimeType,
  mimetype,
  validityDate,
  fileSize,
  size
}) => ({
  file_id: (response || {}).id || fileId || id,
  title: name || title,
  mimetype: type || mimeType || mimetype,
  file_size: fileSize || size,
  validityDate
});

export const WebsocketOperatorContext = createContext({
  disconnect: () => {},
  reconnect: () => {},
  joinRooms: () => {},
  sendMessage: () => {},
  readMessage: () => {},
  fetchMessages: () => {},
  requestContactInfo: () => {},
  requestFeedback: () => {},
  joinChannels: () => {},
  leaveChannels: () => {},
  messageReaction: () => {},
  readNotificationMessage: () => {},
  readAllNotificationMessages: () => {},
  removeFailedMessage: () => {},
  messageReactionKind: {
    set: MESSAGE_REACTION_KIND_SET,
    unset: MESSAGE_REACTION_KIND_UNSET
  },
  channelGroups: []
});

export const WebsocketOperatorProvider = ({ children }) => {
  const dispatch = useDispatch();

  const contactEntries = useSelector(getContacts);
  const employee = useSelector(getUserEmployee);
  const tabKey = useSelector(getTabKey);

  const isNeedToReconnect = useSelector(getIsNeedToReconnect);
  const sendingMessageData = useSelector(getSendingMessageData);
  const isReady = useSelector(getOperatorIsReady);
  const sendingDraftMessages = useSelector(getSendingDraftMessages);

  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  const language = useSelector(getUILanguage);
  const timeZone = useSelector(getUITimeZone);

  const amplitude = useAmplitude();
  const handleAlertExternalChatNewMessage = useAlertExternalChatNewMessage({
    dispatch
  });

  const [incomingEvent, setIncomingEvent] = useState(null);
  const [systemOnlineChannelUuid, setSystemOnlineChannelUuid] = useState(null);
  const [channelGroups, setChannelGroups] = useState([]); // [{uuid, allowToLeave}]
  const [isNeedToConnect, setIsNeedToConnect] = useState(false);
  const [readableNotificationUuids, setReadableNotificationUuids] = useState(
    {}
  ); // to track the lack of response from the backend

  const ws = useRef({});
  const userChannelGroupsGenerator = useRef({});

  const pingIntervalRef = useRef(null);
  const pongTimeoutRef = useRef(null);

  const [, toggleAudio] = useAudio(newMessageAudioUrl);

  const { t } = useTranslation(['Errors', 'Contacts', 'ScreenErrors']);

  const send = useCallback((name, data, retryCount = 5) => {
    const payload = JSON.stringify([name, data].filter(Boolean));

    if (ws.current.readyState === WebSocket.OPEN) {
      const bindedSend = ws.current.send.bind(ws.current);

      return bindedSend(payload);
    }

    if (
      (!(ws.current instanceof WebSocket) ||
        ws.current.readyState !== WebSocket.CONNECTING) &&
      retryCount > 0 &&
      name !== LEAVE_CHANNELS
    ) {
      console.log(
        `Websocket connection is opened by sending an event - ${name}`
      );
      setIsNeedToConnect(true);
    }

    if (retryCount > 0) {
      return setTimeout(() => send(name, data, retryCount - 1), 1000);
    }

    return console.log(
      'The number of attempts to send an event to the unified chat is out of bounds'
    );
  }, []);

  const fetchSystemOnlineChannelUuid = useCallback(async () => {
    const { results } = await dispatch(
      fetchChannelsLocal({ kind: ONLINE_CHAT, offset: 0, limit: 1, lite: true })
    );

    const onlineChannelUuid = (results[0] || {}).uuid;
    setSystemOnlineChannelUuid(onlineChannelUuid);

    return onlineChannelUuid;
  }, [dispatch]);

  const getNotificationsCount = useCallback(
    debounce(payload => {
      send(GET_NOTIFICATIONS_COUNT, payload);
    }, 1000),
    []
  );

  const joinRooms = useCallback(
    async contacts => {
      const contactsWithChats = contacts.filter(
        contact => !!contact.chats.length
      );

      const data = contactsWithChats.map(contact => {
        dispatch(
          setEntityChatIsMessagesLoading({
            value: true,
            entityId: contact.id,
            entityType: TYPE_CONTACT
          })
        );

        return transformEntityToSend({
          id: contact.id,
          type: TYPE_CONTACT,
          chats: contact.chats
        });
      });

      if (data.length) {
        let onlineChannelUuid = systemOnlineChannelUuid;

        if (!onlineChannelUuid) {
          onlineChannelUuid = await fetchSystemOnlineChannelUuid();
        }

        dispatch(
          joinChatRooms({
            data,
            params: { onlineChannelUuid, withSource: false, withParent: false }
          })
        );
      }
    },
    [dispatch, fetchSystemOnlineChannelUuid, systemOnlineChannelUuid]
  );

  const joinChannels = useCallback(
    (chatOrChannelUuids = [], allowToLeave = true) => {
      setChannelGroups(prev => [
        ...prev,
        ...chatOrChannelUuids.map(g => ({ uuid: g, allowToLeave }))
      ]);

      send(JOIN_CHANNELS, { channelIds: chatOrChannelUuids });
    },
    [send]
  );

  // checking allowToLeave is needed so that reactions in the list of notifications work in real time
  const leaveChannels = useCallback(
    (chatOrChannelUuids = [], isDisconnect = false) => {
      const allowToLeaveUuids = isDisconnect
        ? chatOrChannelUuids
        : chatOrChannelUuids.reduce((acc, curr) => {
            if (channelGroups.find(g => g.uuid === curr && !g.allowToLeave)) {
              return acc;
            }

            acc.push(curr);

            return acc;
          }, []);

      if (allowToLeaveUuids.length) {
        setChannelGroups(groups =>
          groups.filter(g => !allowToLeaveUuids.includes(g.uuid))
        );

        send(LEAVE_CHANNELS, {
          channelIds: allowToLeaveUuids
        });
      }
    },
    [channelGroups, send]
  );

  const sendMessage = useCallback(
    ({
      channelKind,
      channelUuid,
      entityId,
      entityType,
      isFromControls,
      fileList = [],
      parent,
      username,
      uuid,
      ...message
    }) => {
      const chat = channelGroups.find(
        g => g.uuid === message.roomUuid || g.uuid === channelUuid
      );

      if (!chat && isFromControls) {
        notification.warning({
          message: t('SomethingWentWrong'),
          description: t('YouCantSendMessage', { ns: 'ScreenErrors' })
        });

        Sentry.captureException(new Error(NO_JOIN_ROOMS_ERROR));

        return false;
      }

      const data = {
        ...message,
        parentId: (parent || {}).uuid,
        fileList: fileList.map(mapFileList),
        sender: {
          fullName: getFullName(employee),
          avatarUrl: (employee.avatarFile || {}).url,
          username: (message.sender && message.sender.username) || username
        },
        payload: employee.id.toString(),
        appId: getAppId(channelKind),
        channelId: channelUuid,
        uuid: uuid || crypto.randomUUID()
      };

      dispatch(
        sendDraftMessage({
          entityId,
          entityType,
          isNotSended: false,
          isDraft: true,
          isSending: true,
          ...data,
          parent,
          createdAt: moment(new Date()).utc().format(),
          fileList: data.fileList.map(f => ({ ...f, fileId: f.file_id })),
          isFrom: data.appId === ONLINE_CHAT ? ONLINE_CHAT : UPSERVICE
        })
      );

      send(SEND_CHAT_MESSAGE, data);

      return true;
    },
    [channelGroups, dispatch, employee, send, t]
  );

  const fetchMessages = useCallback(
    async ({
      entityId,
      entityType,
      chats,
      offset = 0,
      limit = 10000,
      readable,
      withSource,
      withParent,
      isRefetch,
      withAggregation,
      filters,
      cancelable
    }) => {
      dispatch(
        setEntityChatIsMessagesLoading({ entityId, entityType, value: true })
      );

      const data = transformEntityToSend({
        id: entityId,
        type: entityType,
        chats
      });

      const onlineChat = chats.find(c => c.channelKind === ONLINE_CHAT);

      let onlineChannelUuid = onlineChat
        ? onlineChat.channelUuid
        : systemOnlineChannelUuid;

      if (!onlineChannelUuid) {
        onlineChannelUuid = await fetchSystemOnlineChannelUuid();
      }

      const chat = await dispatch(
        getChatMessages({
          data: [{ ...data, limit, offset, filters }],
          notificationsRoomUuid:
            readable && entityType === TYPE_EMPLOYEE ? chats[0].uuid : null,
          isRefetch,
          cancelable,
          params: {
            onlineChannelUuid,
            readable,
            withSource,
            withParent,
            withAggregation
          }
        })
      );

      return chat;
    },
    [dispatch, fetchSystemOnlineChannelUuid, systemOnlineChannelUuid]
  );

  const readMessage = useCallback(
    ({ roomUuid, messageUuid, channelKind, channelUuid }) =>
      send(READ_CHAT_MESSAGE, {
        roomUuid,
        messageUuid,
        appId: getAppId(channelKind),
        channelId: channelUuid
      }),
    [send]
  );

  const requestContactInfo = useCallback(
    ({ roomUuid, channelKind, entityType, entityId, channelUuid }) => {
      sendMessage({
        entityType,
        entityId,
        roomUuid,
        text: [{ text: t('RequestContactsMessage', { ns: 'Contacts' }) }],
        kind: DIALOG_TYPE_LOG,
        channelKind,
        channelUuid
      });

      send(NEED_CONTACT_INFO, {
        roomUuid,
        appId: getAppId(channelKind),
        channelId: channelUuid
      });
    },
    [send, sendMessage, t]
  );

  const requestFeedback = useCallback(
    ({ roomUuid, channelKind, entityType, entityId, channelUuid }) => {
      sendMessage({
        entityType,
        entityId,
        roomUuid,
        text: [{ text: t('RequestFeedbackMessage', { ns: 'Contacts' }) }],
        kind: DIALOG_TYPE_LOG,
        channelKind,
        channelUuid
      });

      send(NEED_FEEDBACK, {
        roomUuid,
        appId: getAppId(channelKind),
        channelId: channelUuid
      });
    },
    [send, sendMessage, t]
  );

  const messageReaction = useCallback(
    ({ roomUuid, messageUuid, kind, channelKind, code, channelUuid }) =>
      send(MESSAGE_REACTION, {
        messageUuid,
        roomUuid,
        sender: {
          fullName: getFullName(employee),
          avatarUrl: (employee.avatarFile || {}).url,
          employee: {
            id: employee.id,
            position: employee.position
          }
        },
        kind,
        channelId: channelUuid,
        code,
        appId: getAppId(channelKind)
      }),
    [employee, send]
  );

  const handleNotificationMessageRead = useCallback(
    data => {
      dispatch(notificationMessageRead(data));

      getNotificationsCount({
        appId: data.appId,
        channelId: data.channelId,
        userId: employee.userId
      });
    },
    [dispatch]
  );

  const handleAllNotificationMessagesRead = useCallback(
    data => {
      dispatch(allNotificationMessagesRead(data));

      getNotificationsCount({
        appId: data.appId,
        channelId: data.channelId,
        userId: employee.userId
      });
    },
    [dispatch]
  );

  const readNotificationMessage = useCallback(
    ({
      roomUuid,
      messageUuid,
      channelKind,
      channelUuid,
      isRead,
      parentNotificationUuid
    }) => {
      const data = {
        roomUuid,
        messageUuid,
        channelId: channelUuid,
        isRead,
        parentNotificationUuid
      };

      send(READ_NOTIFICATION_MESSAGE, {
        appId: getAppId(channelKind),
        ...data
      });

      const timeout = setTimeout(() => {
        Sentry.captureException(new Error(NOTIFICATION_WITHOUT_RESPONSE_ERROR));
      }, MESSAGE_SENDING_TIMEOUT_MS);

      setReadableNotificationUuids(prev => ({
        ...prev,
        [messageUuid]: timeout
      }));

      handleNotificationMessageRead(data);
    },
    [handleNotificationMessageRead, send]
  );

  const readAllNotificationMessages = useCallback(
    ({ roomUuid, channelKind, channelUuid }) => {
      send(READ_ALL_NOTIFICATION_MESSAGES, {
        appId: getAppId(channelKind),
        channelId: channelUuid,
        roomUuid
      });

      handleAllNotificationMessagesRead({ roomUuid });
    },
    [handleAllNotificationMessagesRead, send]
  );

  const handleMessageReactionSet = useCallback(
    data => dispatch(messageReactionSet(data)),
    [dispatch]
  );

  const handleMessageReactionUnset = useCallback(
    data => dispatch(messageReactionUnset(data)),
    [dispatch]
  );

  const setContactInfo = useCallback(
    async data => {
      const resultData = {};

      Object.keys(CONTACT_INFO_FIELDS).forEach(key => {
        if (data[key]) {
          resultData[CONTACT_INFO_FIELDS[key]] = data[key];
        }
      });

      dispatch(
        editContactByRoomUuid({ data: resultData, roomUuid: data.roomUuid })
      );
    },
    [dispatch]
  );

  const handleMessageNew = useCallback(
    async message => {
      const isMyMessage = employee.id === +message.payload;
      const isLogType = message.kind === DIALOG_TYPE_LOG;
      const isNotification = message.kind === NOTIFICATION;

      const needToHandleContactMessage =
        !isNotification &&
        !isMyMessage &&
        !isLogType &&
        (!message.destination ||
          message.destination.entityType === TYPE_CONTACT);

      let contact;

      if (needToHandleContactMessage) {
        const openedTabs = JSON.parse(localStorage.getItem('openedTabs'));
        const lastActiveTab = JSON.parse(
          localStorage.getItem('lasetActiveTab')
        );

        const tabsCondition =
          !lastActiveTab || !openedTabs.includes(lastActiveTab)
            ? openedTabs[0] === tabKey
            : lastActiveTab === tabKey;

        contact = contactEntries.find(c =>
          c.chats.map(chat => chat.uuid).includes(message.roomUuid)
        );

        if (
          !contact ||
          contact.status === CONTACT_STATUS_ARCHIVED ||
          contact.notify === undefined
        ) {
          contact = await dispatch(
            fetchContactByChatLocal({ roomUuid: message.roomUuid })
          );

          if (contact.status === CONTACT_STATUS_ARCHIVED) {
            contact = {
              ...contact,
              status:
                contact.phone || contact.email
                  ? CONTACT_STATUS_VERIFIED
                  : CONTACT_STATUS_UNASSEMLED
            };
          }
        }

        if (
          contact &&
          contact.notify &&
          message.isFrom !== 'upservice' &&
          (!contact.responsible || contact.responsible.id === employee.id) &&
          tabsCondition
        ) {
          toggleAudio();
        }
      }

      if (
        isNotification &&
        message.source &&
        (employee.chats || []).length &&
        message.roomUuid === employee.chats[0].uuid
      ) {
        joinChannels([message.source.roomUuid], false);

        dispatch(
          setEntityChatRoomUuids([
            {
              entityId: employee.id,
              entityType: TYPE_EMPLOYEE,
              chats: [{ roomUuid: message.source.roomUuid }]
            }
          ])
        );
      }

      dispatch(
        chatMessageNew({
          contact, // chatMessageNew in contact store
          message,
          isMyMessage,
          isNotification
        })
      );

      if (isNotification && (message || {}).isFrom === ONLINE_CHAT) {
        getNotificationsCount({
          appId: message.isFrom,
          channelId: message.channelId,
          userId: employee.userId
        });
      }
    },
    [
      contactEntries,
      dispatch,
      employee.chats,
      employee.id,
      joinChannels,
      tabKey,
      toggleAudio
    ]
  );

  const handleMessageNewOk = useCallback(
    message => {
      dispatch(chatMessageNewOk({ message }));
    },
    [dispatch]
  );

  const handleReadMessage = useCallback(
    async data => {
      dispatch(chatMessageRead(data));

      getNotificationsCount({
        appId: data.appId,
        channelId: data.channelId,
        userId: employee.userId
      });

      dispatch(
        setEntityChatHasNewMessages({ entityType: TYPE_CONTACT, value: false })
      );
    },
    [dispatch]
  );

  const setLanguage = useCallback(
    lng =>
      send(SET_LANGUAGE, {
        language: lng
      }),
    [send]
  );

  const setTimeZone = useCallback(
    tz =>
      send(SET_TIME_ZONE, {
        timeZone: tz
      }),
    [send]
  );

  const removeFailedMessage = useCallback(
    ({ uuid, roomUuid, channelKind, channelId }) => {
      send(REMOVE_FAILED_MESSAGE, {
        uuid,
        roomUuid,
        appId: getAppId(channelKind),
        channelId
      });
    },
    [send]
  );

  const handleFailedMessageRemoved = useCallback(
    ({ roomUuid, uuid }) => {
      dispatch(failedRemovedMessage({ roomUuid, uuid }));
    },
    [dispatch]
  );

  const handleEmployeeTyping = useCallback(
    data => {
      dispatch(employeeTyping(data));
    },
    [dispatch]
  );

  const handleNotificationsCount = useCallback(
    data => {
      dispatch(notificationsCount(data));
    },
    [dispatch]
  );

  const handleIncomingEvent = useCallback(
    event => {
      const [eventName, data] = JSON.parse(event.data);

      switch (eventName) {
        case JOIN_CHANNELS:
          if (userChannelGroupsGenerator.current.next) {
            try {
              userChannelGroupsGenerator.current.next();
            } catch (e) {
              console.log(e);
            }
          }
          break;
        case CHAT_MESSAGE_NEW_OK:
          handleMessageNewOk(data);
          break;
        case CHAT_MESSAGE_NEW:
          handleMessageNew(data);
          break;
        case CHAT_MESSAGE_READ:
        case READ_CHAT_MESSAGE:
          handleReadMessage(data);
          break;
        case CLIENT_STATUS_CHANGED:
          // MAYBE LATER
          break;
        case NEW_CONTACT_INFO:
          setContactInfo(data);
          break;
        case MESSAGE_REACTION_SET:
          handleMessageReactionSet(data);
          break;
        case MESSAGE_REACTION_UNSET:
          handleMessageReactionUnset(data);
          break;
        case NOTIFICATION_MESSAGE_READ: {
          const timeout = readableNotificationUuids[data.messageUuid];

          if (timeout) {
            clearTimeout(timeout);
          }

          handleNotificationMessageRead(data);
          break;
        }
        case ALL_NOTIFICATION_MESSAGES_READ:
          handleAllNotificationMessagesRead(data);
          break;
        case FAILED_MESSAGE_REMOVED:
          handleFailedMessageRemoved(data);
          break;
        case EMPLOYEE_TYPING:
          handleEmployeeTyping(data);
          break;
        case NOTIFICATIONS_COUNT:
          handleNotificationsCount(data);
        case ALERT_EXTERNAL_CHAT_NEW_MESSAGE:
          handleAlertExternalChatNewMessage(data);
          break;
        default:
          break;
      }
    },
    [
      handleAllNotificationMessagesRead,
      handleEmployeeTyping,
      handleFailedMessageRemoved,
      handleMessageNew,
      handleMessageNewOk,
      handleMessageReactionSet,
      handleMessageReactionUnset,
      handleNotificationMessageRead,
      handleReadMessage,
      readableNotificationUuids,
      setContactInfo
    ]
  );

  function* generateUserChannelGroups(groups) {
    const chunkSize = 1000;

    for (let i = 0; i < groups.length; i += chunkSize) {
      const chunk = groups.slice(i, i + chunkSize);

      yield joinChannels(chunk);
    }
  }

  const showWarningMessage = ({
    messageKey,
    namespace = 'Errors',
    duration = 0,
    code,
    reason
  }) => {
    antdMessage.destroy();

    showNoticeMessage({
      type: WARNING_NOTICE_TYPE,
      customContent: t(messageKey, { ns: namespace }),
      duration
    });

    amplitude.wsConnectionLimitedWarningShown({ code, reason });
  };

  const connect = useCallback(
    ({
      retryCount = MAX_CONNECTION_RETRY_COUNT,
      connectionWaitTime = 10000,
      closingTimeout = 5000,
      uuidToConnect
    } = {}) => {
      const token = localStorage.getItem('token');

      const socket = new WebSocket(
        `wss://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}/ws/operator/channels?access_token=${token}`
      );

      const stopHeartbeat = () => {
        clearInterval(pingIntervalRef.current);
        clearTimeout(pongTimeoutRef.current);
      };

      const startHeartbeat = wsSocket => {
        stopHeartbeat();

        pingIntervalRef.current = setInterval(() => {
          if (wsSocket.readyState === WebSocket.OPEN) {
            wsSocket.send(PING);

            pongTimeoutRef.current = setTimeout(() => {
              showWarningMessage({
                messageKey: 'ConnectionLimited',
                reason: HEARTBEAT_FAILED
              });

              Sentry.captureException(new Error(HEART_BEAT_ERROR));

              console.log('Websocket connection close: heartbeat', {
                ws: wsSocket
              });

              wsSocket.close();
            }, PING_TIMEOUT);
          }
        }, PING_INTERVAL);
      };

      socket.onopen = async event => {
        console.log('Websocket connection open', { event });

        dispatch(setOperatorIsReady(true));

        if (uuidToConnect) {
          joinChannels([uuidToConnect]);
        }

        const { groups } = await dispatch(fetchUserChannelGroups());

        userChannelGroupsGenerator.current = generateUserChannelGroups(groups);
        userChannelGroupsGenerator.current.next();
        startHeartbeat(socket);
      };

      socket.onclose = event => {
        console.log('Websocket connection onclose', { event });
        dispatch(setOperatorIsReady(false));

        stopHeartbeat();

        if (retryCount > 0 && event.code !== WEBSOCKET_NORMAL_CLOSURE_CODE) {
          console.log(
            `Websocket connection is reconnecting, number of reconnections: max = ${MAX_CONNECTION_RETRY_COUNT}, current = ${retryCount}`,
            { event }
          );

          return setTimeout(() => {
            connect({
              retryCount: retryCount - 1,
              closingTimeout
            });
          }, closingTimeout);
        }

        if (event.code !== WEBSOCKET_NORMAL_CLOSURE_CODE) {
          showWarningMessage({
            messageKey: 'ConnectionLimited',
            code: event.code,
            reason: RECONNECT_ATTEMPT_FAILED
          });
        }

        console.log('Websocket connection closed', { event });

        ws.current = {};

        return null;
      };

      socket.onmessage = event => {
        if (JSON.parse(event.data) === PONG) {
          clearTimeout(pongTimeoutRef.current);
        } else {
          setIncomingEvent(event);
        }
      };

      socket.onerror = () => socket.close();

      ws.current = socket;

      if (retryCount <= 0) {
        ws.current = {};

        return dispatch(setOperatorIsReady(false));
      }

      return setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          return socket;
        }

        return socket.close(WEBSOCKET_SERVICE_RECONNECT_CODE);
      }, connectionWaitTime);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, joinChannels]
  );

  const disconnect = useCallback(
    ({ needToLeaveChannels = true } = {}) => {
      console.log('Websocket connection was closed by the application itself');

      if (needToLeaveChannels) {
        leaveChannels(
          channelGroups.map(g => g.uuid),
          true
        );
      }

      ws.current.close(WEBSOCKET_NORMAL_CLOSURE_CODE);
      ws.current = {};
    },
    [channelGroups, leaveChannels]
  );

  const reconnect = useCallback(
    ({ uuidToConnect, needToLeaveChannels = true } = {}) => {
      console.log(
        'Websocket connection is reconnecting by the application itself'
      );

      if (ws.current.close) {
        disconnect({ needToLeaveChannels });
      }

      if (!isOnlyUserProfile) {
        connect({ uuidToConnect });
      }
    },
    [connect, disconnect, isOnlyUserProfile]
  );

  useEffect(() => {
    if (incomingEvent) {
      handleIncomingEvent(incomingEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingEvent]);

  // additional connection if websocket connection was closed but we send event
  useEffect(() => {
    if (isNeedToConnect) {
      connect();

      setIsNeedToConnect(false);
    }
  }, [connect, isNeedToConnect]);

  useEffect(() => {
    if (isNeedToReconnect && isReady) {
      reconnect();
    }

    dispatch(setIsNeedToReconnect(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToReconnect]);

  useEffect(() => {
    if (language && isReady) {
      setLanguage(language);
    }
  }, [isReady, language, setLanguage]);

  useEffect(() => {
    if (timeZone && isReady) {
      setTimeZone(timeZone);
    }
  }, [isReady, timeZone, setTimeZone]);

  useEffect(() => {
    if (sendingMessageData) {
      sendMessage({
        ...(sendingMessageData?.destination
          ? { destination: sendingMessageData.destination }
          : {}),
        entityType: sendingMessageData.entityType,
        entityId: sendingMessageData.entityId,
        subject: undefined,
        channelKind: ONLINE_CHAT,
        fileList: sendingMessageData.fileList,
        kind: sendingMessageData.kind || DIALOG_TYPE_COMMENT,
        roomUuid: sendingMessageData.onlineChat.uuid,
        channelUuid: sendingMessageData.onlineChat.channelUuid,
        text: [{ text: '' }]
      });

      dispatch(setSendingMessageData(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingMessageData]);

  useEffect(() => {
    const timeouts = [];

    if (sendingDraftMessages.length) {
      sendingDraftMessages.forEach(message => {
        const timeout = setTimeout(() => {
          dispatch(
            setIsNotSendedMessage({
              roomUuid: message.roomUuid,
              uuid: message.uuid
            })
          );

          Sentry.captureException(new Error(MESSAGE_WITHOUT_RESPONSE_ERROR));
        }, MESSAGE_SENDING_TIMEOUT_MS);

        timeouts.push(timeout);
      });
    }

    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingDraftMessages]);

  // connect
  useEffect(() => {
    if (!isOnlyUserProfile) {
      connect();
    }

    sioSocket.on(USER_CHANNELS_ADDED, ({ channelGroupUuids }) =>
      joinChannels(channelGroupUuids)
    );

    sioSocket.on(USER_CHANNELS_REMOVED, ({ channelGroupUuids }) =>
      leaveChannels(channelGroupUuids)
    );

    return () => {
      if (ws.current.close) {
        ws.current.close(WEBSOCKET_NORMAL_CLOSURE_CODE);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebsocketOperatorContext.Provider
      value={{
        disconnect,
        reconnect,
        joinRooms,
        sendMessage,
        removeFailedMessage,
        readMessage,
        fetchMessages,
        requestContactInfo,
        requestFeedback,
        joinChannels,
        leaveChannels,
        messageReaction,
        readNotificationMessage,
        readAllNotificationMessages,
        messageReactionKind: {
          set: MESSAGE_REACTION_KIND_SET,
          unset: MESSAGE_REACTION_KIND_UNSET
        },
        channelGroups
      }}
    >
      {children}
    </WebsocketOperatorContext.Provider>
  );
};

export default WebsocketOperatorProvider;
