import React from 'react';
import { useTranslation } from 'react-i18next';

import { REQUEST_FIELD_CONTACT, REQUEST_FIELD_CHANNEL } from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import Tooltip from 'components/common/tooltip';

import { FieldEditorItem } from 'providers/field-editor';
import useRoutesService from 'services/routes';
import { getFullName } from 'utils/get-fio';

import styles from './ContactChannelInfo.module.scss';

export const ContactChannelInfo = ({ request }) => {
  const { t } = useTranslation('Requests');

  const routes = useRoutesService({ returnUrl: true });

  const contact = request.taskInfo?.contact || null;
  const channel = request.taskInfo?.slaObject || null;

  if (!contact && !channel) {
    return null;
  }

  return (
    <div className={styles.root}>
      {contact && (
        <FieldEditorItem
          field={REQUEST_FIELD_CONTACT}
          defaultValues={{ REQUEST_FIELD_CONTACT: contact.id }}
          className={styles.item}
        >
          <DetailsField
            iconType="user-outlined"
            title={t('ContactField')}
            className={styles.detailsField}
          >
            <a
              href={routes.toContact({ id: contact.id })}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Typography.Text
                color="brand"
                style={{
                  marginRight: 8,
                  whiteSpace: 'nowrap',
                  display: 'block'
                }}
                ellipsis
              >
                <Tooltip title={getFullName(contact)}>
                  {getFullName(contact)}
                </Tooltip>
              </Typography.Text>
            </a>
          </DetailsField>
        </FieldEditorItem>
      )}

      {channel && (
        <FieldEditorItem
          field={REQUEST_FIELD_CHANNEL}
          defaultValues={{ REQUEST_FIELD_CHANNEL: channel.id }}
          className={styles.item}
        >
          <DetailsField
            iconType="phone-and-message"
            title={t('ChannelField')}
            className={styles.detailsField}
          >
            <Tooltip title={channel.name}>
              <Typography.Text ellipsis>{channel.name}</Typography.Text>
            </Tooltip>
          </DetailsField>
        </FieldEditorItem>
      )}
    </div>
  );
};
