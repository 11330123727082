import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

export const getRequests = state => state.requests;

export const getRequestEntries = createSelector(
  getRequests,
  requests => requests.entries
);

export const getIsRequestsLoading = createSelector(
  getRequests,
  requests => requests.isLoading
);

export const getIsRequestsLoaded = createSelector(
  getRequests,
  requests => requests.isLoaded
);

export const getItemsPerPage = createSelector(
  getRequests,
  requests => requests.itemsPerPage
);

export const getTotalItems = createSelector(
  getRequests,
  requests => requests.totalItems
);

export const getCurrentPage = createSelector(
  getRequests,
  requests => requests.currentPage
);

export const getSelectedRequests = createSelector(
  getRequests,
  requests => requests.selectedTasks
);

export const getRequestsHasMore = createSelector(
  getRequests,
  requests => requests.hasMore
);

export const getRequestsHasMoreWorklog = createSelector(
  getRequests,
  requests => requests.hasMoreWorklogList
);

export const getRequestFactory = createSelector(getRequestEntries, entries =>
  memoize(requestId => entries.find(request => request.id === requestId))
);

export const getError = createSelector(getRequests, requests => requests.error);

export const getHasError = createSelector(getError, error => error !== null);

export const getAllRequestsLength = createSelector(
  getRequestEntries,
  entries => entries.length
);

export const getIsBacklog = createSelector(
  getRequests,
  requests => requests.isBacklog
);

export const getRequestViewType = createSelector(
  getRequests,
  requests => requests.viewType
);

// --filters-- //
export const getFilterRequests = createSelector(
  getRequests,
  requests => requests.filter
);

export const getProjectFilterRequests = createSelector(
  getRequests,
  requests => requests.projectFilter
);

export const getBacklogFilterRequests = createSelector(
  getRequests,
  requests => requests.backlogFilter
);

export const getFilterSearchTasks = createSelector(
  getFilterRequests,
  filter => filter.search
);

export const getFilterFavoriteTasks = createSelector(
  getFilterRequests,
  filter => filter.isFavorite
);

export const getFilterTypeTasks = createSelector(
  getFilterRequests,
  filter => filter.type
);

export const getFilterStatusTasks = createSelector(
  getFilterRequests,
  filter => filter.status || []
);

export const getFilterSprintTasks = createSelector(
  getFilterRequests,
  filter => filter.sprint
);

export const getFilterOrderingTasks = createSelector(
  getFilterRequests,
  filter => filter.ordering
);

export const getFilterAuthorTasks = createSelector(
  getFilterRequests,
  filter => filter.authors || []
);

export const getFilterResponsibleTasks = createSelector(
  getFilterRequests,
  filter => filter.responsibles || []
);

export const getFilterControllerTasks = createSelector(
  getFilterRequests,
  filter => filter.controllers || []
);

export const getFilterCreatedDateRangeTasks = createSelector(
  getFilterRequests,
  filter => filter.createdDateRange
);

export const getFilterStartedDateRangeTasks = createSelector(
  getFilterRequests,
  filter => filter.startedDateRange
);

export const getFilterEndedDateRangeTasks = createSelector(
  getFilterRequests,
  filter => filter.endedDateRange
);

export const getFilterCompletedDateRangeTasks = createSelector(
  getFilterRequests,
  filter => filter.completedDateRange
);

export const getFilterProjectTasks = createSelector(
  getFilterRequests,
  filter => filter.project
);

export const getFilterTagsTasks = createSelector(
  getFilterRequests,
  filter => filter.tags || []
);

export const getFilterOrdersTasks = createSelector(
  getFilterRequests,
  filter => filter.orders
);

export const getFilterContactsTasks = createSelector(
  getFilterRequests,
  filter => filter.contacts || []
);

export const getFilterSubordinatesTasks = createSelector(
  getFilterRequests,
  filter => filter.subordinates
);

export const getFilterExpired = createSelector(
  getFilterRequests,
  filter => filter.expired
);

export const getFilterSprintIsNull = createSelector(
  getFilterRequests,
  filter => filter.sprintIsNull
);

export const getBacklogFilterSprintIsNull = createSelector(
  getBacklogFilterRequests,
  filter => filter.sprintIsNull
);

export const getBacklogFilterProject = createSelector(
  getBacklogFilterRequests,
  filter => filter.project
);

export const getRequestsPageData = createSelector(
  getRequests,
  requests => requests.pageData
);

export const getRequestsIsAllLoading = createSelector(
  getRequests,
  requests => requests.isAllLoading
);
