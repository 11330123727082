import { combineActions } from 'redux-actions';
import { unionBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ITEMS_PER_PAGE,
  TASK,
  BACKLOG,
  STATUSES_ACTIVE,
  ATTACHMENT_TYPE_ENTITY,
  ALL,
  PROJECT_TASKS,
  TASK_STATUS_UPDATED,
  TYPE_TASK,
  CONTAINS_ANY_OPTION,
  STATUS_FOR_EXECUTION,
  STATUS_BACKLOG,
  TASK_ORDERING_ACTIONS,
  TASK_ORDERING_DATE_END,
  TASK_VIEW_TYPE,
  TASK_VIEW_TYPE_LIST,
  TASK_ORDERING_COMPLETED_AT,
  REQUESTS
} from 'constants/index';

import { getTasksInitialPageData } from 'components/requests-view/view/board/utils';

import { makeSubscribeLocationChange } from 'store/router';
import { setActiveId, readComments } from 'store/workspace';
import { combineActionsThunk } from 'store/actions-thunk';
import {
  createAssetRecord,
  fetchAssetTaskBookingData,
  updateAssetRecord
} from 'store/calendar';

import handleActions from 'utils/redux-actions';
import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';
import { OLD_REQUEST_CANCELED } from 'utils/make-cancalable-request';
import { getInitialValueStorage } from 'hooks/common/use-local-storage';

import {
  fetchRequests,
  createRequest,
  setRequestFavorite,
  editRequestFields,
  deleteRequest,
  changeManager,
  createWorklog,
  clearFilter,
  setFilterSearch,
  setFilterAuthor,
  setFilterCompletedDateRange,
  setFilterContacts,
  setFilterController,
  setFilterCreatedDateRange,
  setFilterStartedDateRange,
  setFilterEndedDateRange,
  setFilterExpired,
  setFilterOrderStatus,
  setFilterProject,
  setFilterResponsible,
  setFilterStatus,
  setFilterSubordinates,
  setFilterType,
  setFilterFavorite,
  setFilterMyTasks,
  updateStatus,
  selectRequest,
  deselectRequest,
  sendTasksInWork,
  setFilterSprint,
  updateWorklog,
  deleteWorklog,
  setEstimate,
  fetchRequest,
  fetchSubtaskList,
  fetchWorklogList,
  fetchAssignments,
  fetchRelations,
  setBacklogFilterAuthors,
  setBacklogFilterResponsibles,
  setBacklogFilterProject,
  clearBacklogFilter,
  setBacklogFilterSubordinates,
  setBacklogFilterSprint,
  deselectAll,
  setBacklogFilterSprintIsNull,
  fetchAttachments,
  setBacklogFilterFavorite,
  setFilterSprintIsNull,
  agreementAction,
  setFilterOrdering,
  fetchAgreementSteps,
  setRequestViewed,
  setRequestViewedApproving,
  setBacklogFilterSearch,
  fetchCoResponsibles,
  setFilterIsAutoCreated,
  setBacklogFilterHideSubtasks,
  setPointEstimate,
  setFilterAsset,
  changeTaskAttachments,
  setFilterTag,
  setBacklogFilterTag,
  setFilterMembers,
  setBacklogFilterMembers,
  setFilterTasks,
  setFilterRequests,
  setBacklogFilterRelations,
  setFilterHideSubtasks,
  changeRequestViewType,
  editRequestDates,
  setTasksIsAllLoading,
  clearTasksEntries,
  reorderTasksEntries,
  resetReorderingTasksEntries,
  partialRequestUpdate,
  addTasksToSprint,
  setBacklogFilterCreatedDateRange,
  updateSubtasksDates,
  setRequestData
} from './actions';
import {
  unsubscribeAttachment,
  subscribeAttachment,
  subscribeAsset,
  unsubscribeAsset
} from '../subscriptions';
import { changeValidityDate } from '../attachments';

export const initialFilter = {
  search: '',
  type: [],
  status: STATUSES_ACTIVE,
  sprints: [],
  authors: [],
  responsibles: [],
  controllers: [],
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  asset: [],

  startedDateRange: {},
  completedDateRange: {},
  endedDateRange: {},

  ordering:
    TASK_ORDERING_ACTIONS[
      TASK_ORDERING_ACTIONS.findIndex(a => a.key === TASK_ORDERING_DATE_END)
    ],

  subordinates: false, // Мое подразделение
  expired: false,
  sprintIsNull: undefined,
  myTasks: undefined, // Для того, чтобы во входящих не отдавались задачи предыдущего ответственного
  isAutoCreated: false, // Для скрытия автоматически созданных задач
  isFavorite: undefined,
  projects: [],
  orderStatus: [],
  contacts: [],
  members: [],
  tasks: [],
  requests: [],
  channelChats: [],
  hideSubtasks: false
};

export const emptyFilter = {
  ...initialFilter,
  expired: null,
  status: null
};

export const backlogInitialFilter = {
  sprint: null,
  hideSubtasks: false,
  authors: [],
  members: [],
  responsibles: [],
  subordinates: false,
  sprintIsNull: null,
  isFavorite: undefined,
  search: '',
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  relations: {
    task: [],
    request: [],
    orderStatus: [],
    asset: [],
    contact: [],
    channelChat: []
  },
  createdDateRange: {}
};

export const sprintInitialFilter = {
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  authors: [],
  responsibles: [],
  hideSubtasks: true,
  createdDateRange: {},
  relations: {
    task: [],
    request: [],
    orderStatus: [],
    asset: [],
    contact: []
  },
  taskStatus: []
};

const initialState = {
  isAllLoading: false,
  isLoading: false,
  isLoaded: false,
  error: null,

  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  hasMore: true,
  hasMoreWorklogList: true,

  // Используем для сброса пагинации при переходах по страницам (все задачи, бэклог, задачи проекта)
  isBacklog: false,
  isProject: false,

  viewType: getInitialValueStorage(TASK_VIEW_TYPE, {
    value: TASK_VIEW_TYPE_LIST
  }),

  filter: {
    ...initialFilter,
    ...getInitialValueFilterStorage(REQUESTS, initialFilter)
  },
  projectFilter: {
    ...initialFilter,
    ...getInitialValueFilterStorage(PROJECT_TASKS, initialFilter)
  },
  backlogFilter: {
    ...backlogInitialFilter,
    ...getInitialValueFilterStorage(BACKLOG, backlogInitialFilter),
    relations: {
      ...backlogInitialFilter.relations,
      ...getInitialValueFilterStorage(BACKLOG, backlogInitialFilter).relations
    }
  },
  sprintFilter: sprintInitialFilter,

  selectedTasks: [],
  entries: [],
  pageData: getTasksInitialPageData(),
  sprintTasks: {}
};

const getIndexById = (id, list) =>
  (list || []).findIndex(item => item.id === id);

const getParentId = (list, id) =>
  ((list || []).filter(Boolean).find(({ id: itemId }) => itemId === id) || {})
    .parent;

const updateParentWorklogTotal = ({ taskId, list, value }) => {
  const parentId = getParentId(list, taskId);
  const parentIndex = list.findIndex(l => (l || {}).id === parentId);

  if (parentIndex !== -1) {
    list[parentIndex].workLog += value;
    list[parentIndex].workLogTotal += value;
  }
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(REQUESTS, ALL),

    [setActiveId]: () => initialState,

    [setTasksIsAllLoading]: (state, { payload }) => {
      state.isAllLoading = payload;

      return state;
    },

    [fetchRequest.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(({ id }) => id === payload.id);

      const fetchedTask = { ...payload, isLoaded: true };

      if (index === -1) {
        state.entries = [fetchedTask, ...state.entries];
        state.totalItems += 1;
      }

      const task = state.entries[index];
      state.entries[index] = {
        ...task,
        ...fetchedTask,
        isNeedToFetch: false
      };

      return state;
    },

    [fetchRequests.START]: (state, { args }) => {
      state.isLoading = true;
      state.isLoaded = false;

      const isBacklogRequest = (args || {}).isBacklog || false;
      const isProjectRequest = (args || {}).isProject || false;

      if (
        state.isBacklog !== isBacklogRequest ||
        state.isProject !== isProjectRequest
      ) {
        state.entries = [];
      }

      return state;
    },

    [fetchRequests.FAILED]: (state, { payload }) => {
      if (payload && payload.type === OLD_REQUEST_CANCELED) {
        return state;
      }

      state.error = payload;

      return state;
    },

    [fetchRequests.SUCCEEDED]: (state, { payload, args }) => {
      state.isLoading = false;
      state.isLoaded = true;

      const isBacklogRequest = (args || {}).isBacklog || false;
      const isProjectRequest = (args || {}).isProject || false;

      let results = unionBy([...state.entries], [...payload.results], 'id');

      if (
        state.isBacklog !== isBacklogRequest ||
        state.isProject !== isProjectRequest
      ) {
        results = [...payload.results];
      }

      state.isBacklog = isBacklogRequest;
      state.isProject = isProjectRequest;

      state.totalItems = payload.totalItems;
      state.entries = results;
      state.hasMore = payload.totalItems > results.length;

      if (args.status) {
        state.pageData[args.status] = {
          ...state.pageData[args.status],
          number: args.page,
          totalItems: payload.totalItems,
          totalPages: Math.ceil(payload.totalItems / ITEMS_PER_PAGE)
        };
      }

      return state;
    },

    [createRequest.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.parent);

      state.totalItems += 1;
      state.pageData[payload.status].totalItems += 1;

      if (payload.sprint && state.sprintTasks[payload.sprint.id]) {
        state.sprintTasks[payload.sprint.id].entries = [
          payload,
          ...state.sprintTasks[payload.sprint.id].entries
        ];
      }

      if (index === -1) {
        state.entries = [payload, ...state.entries];

        return state;
      }

      state.entries[index].children.unshift(payload);

      return state;
    },

    [deleteRequest]: (state, { payload }) => {
      const taskStatus = state.entries.find(
        ({ id }) => id === payload.id
      ).status;

      const filtredEntries = state.entries.filter(
        item => !(item.id === payload.id || item.parent === payload.id)
      );

      state.totalItems -= state.entries.length - filtredEntries.length;
      state.pageData[taskStatus].totalItems -= 1;

      state.entries = filtredEntries;

      return state;
    },

    [combineActionsThunk(
      editRequestFields.SUCCEEDED,
      agreementAction.SUCCEEDED,
      setRequestFavorite.SUCCEEDED,
      partialRequestUpdate.SUCCEEDED
    )]: (state, { payload, args }) => {
      const { additionalData } = payload;

      const index = state.entries.findIndex(task => task.id === payload.id);

      Object.keys(state.sprintTasks).forEach(sprintId => {
        const sprint = state.sprintTasks[sprintId];

        const taskIndex = sprint.entries.findIndex(
          task => task.id === args.taskId
        );

        if (taskIndex !== -1) {
          Object.keys(payload).forEach(key => {
            sprint.entries[taskIndex][key] = payload[key];
          });
        }
      });

      if (index === -1) {
        return state;
      }

      const task = state.entries[index];

      state.entries[index] = {
        ...task,
        ...payload
      };

      if (
        !isEmpty(additionalData) &&
        !additionalData.filesProcessingCompleted &&
        payload.parent
      ) {
        const parentIndex = state.entries.findIndex(
          t => t.id === payload.parent
        );

        if (parentIndex !== -1) {
          state.entries[parentIndex].additionalData.filesProcessingCompleted =
            false;
        }
      }

      if (args.oldStatus) {
        const { status, oldStatus } = args;

        state.pageData[oldStatus].totalItems -= 1;
        state.pageData[status].totalItems += 1;
      }

      return state;
    },

    [editRequestDates.SUCCEEDED]: (state, { payload }) => {
      payload.forEach(task => {
        const index = state.entries.findIndex(t => t.id === task.id);

        if (index !== -1) {
          state.entries[index].dateStart = task.dateStart;
          state.entries[index].dateEnd = task.dateEnd;
        }
      });

      return state;
    },

    [addTasksToSprint.SUCCEEDED]: (state, { payload }) => {
      const { tasks } = payload;

      Object.entries(state.sprintTasks).forEach(([, value]) => {
        const taskIndex = value.entries.findIndex(task =>
          tasks.includes(task.id)
        );

        if (taskIndex !== -1) {
          value.entries.splice(taskIndex, 1);

          value.totalItems -= 1;
        }
      });

      return state;
    },

    [fetchAgreementSteps.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.id);

      if (index === -1) {
        return state;
      }

      const task = state.entries[index];

      state.entries[index] = {
        ...task,
        agreementSteps: payload.agreementSteps
      };

      return state;
    },

    [setEstimate.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.entries.findIndex(task => task.id === payload.id);

      if (index !== -1) {
        state.entries[index].estimation = payload.estimation;

        if (state.entries[index].parent) {
          const parentIndex = state.entries.findIndex(
            task => task.id === state.entries[index].parent
          );

          if (parentIndex !== -1) {
            state.entries[parentIndex].estimationTotal += args.diff;
          }
        }
      }

      return state;
    },

    [setPointEstimate.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.id);

      if (index !== -1) {
        state.entries[index].storyPoint = payload.storyPoint;
      }

      return state;
    },

    [changeManager.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(item => item.id !== payload.id);
      state.totalItems -= 1;

      return state;
    },

    [fetchRelations.SUCCEEDED]: (state, { args, payload }) => {
      const index = state.entries.findIndex(item => item.id === args.id);

      if (index === -1) {
        return state;
      }

      state.entries[index].relations = payload;

      return state;
    },

    [sendTasksInWork.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(item => !payload.includes(item.id));
      state.selectedTasks = [];

      return state;
    },

    [updateStatus.SUCCEEDED]: (state, { payload, args }) => {
      const { additionalData } = payload;

      const index = state.entries.findIndex(item => item.id === payload.id);

      if (index !== -1) {
        Object.keys(payload).forEach(key => {
          state.entries[index][key] = payload[key];
        });
      }

      if (args.oldStatus) {
        const { status, oldStatus } = args;

        state.pageData[oldStatus].totalItems -= 1;
        state.pageData[status].totalItems += 1;
      }

      if (
        !isEmpty(additionalData) &&
        !additionalData.filesProcessingCompleted &&
        payload.parent
      ) {
        const parentIndex = state.entries.findIndex(
          t => t.id === payload.parent
        );

        if (parentIndex !== -1) {
          state.entries[parentIndex].additionalData.filesProcessingCompleted =
            false;
        }
      }

      Object.keys(state.sprintTasks).forEach(sprintId => {
        const sprint = state.sprintTasks[sprintId];

        const taskIndex = sprint.entries.findIndex(task => task.id === args.id);

        if (taskIndex !== -1) {
          if (args.status === STATUS_BACKLOG) {
            sprint.entries.splice(taskIndex, 1);

            sprint.totalItems -= 1;
          } else {
            Object.keys(payload).forEach(key => {
              sprint.entries[taskIndex][key] = payload[key];
            });
          }
        }
      });

      return state;
    },

    [TASK_STATUS_UPDATED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.taskId);

      if (index !== -1) {
        state.entries[index].isNeedToFetch =
          state.entries[index].status === STATUS_BACKLOG &&
          payload.status === STATUS_FOR_EXECUTION;
        state.entries[index].status = payload.status;
      }

      return state;
    },

    [fetchAttachments.SUCCEEDED]: (state, { args, payload }) => {
      const index = state.entries.findIndex(item => item.id === args.id);

      if (args.source !== ATTACHMENT_TYPE_ENTITY) {
        return state;
      }

      if (index === -1) {
        return state;
      }

      state.entries[index].fileList = payload;

      return state;
    },

    [changeTaskAttachments]: (state, { payload }) => {
      const index = state.entries.findIndex(
        item => item.fileId === payload.fileId
      );

      if (index !== -1) {
        if (payload.isDelete) {
          state.entries[index].fileList = (
            state.entries[index].fileList || []
          ).filter(({ fileId }) => fileId !== payload.attachment.fileId);
        } else {
          state.entries[index].fileList = [
            ...(state.entries[index].fileList || []),
            payload.attachment
          ];
        }
      }

      return state;
    },

    [fetchSubtaskList.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        item => item.id === payload.parentId
      );

      if (index !== -1) {
        state.entries[index].children = payload.results;
      }

      payload.results.forEach(subtask => {
        const idx = state.entries.findIndex(item => item.id === subtask.id);

        if (idx !== -1) {
          Object.keys(subtask).forEach(key => {
            state.entries[idx][key] = subtask[key];
          });
        }
      });

      return state;
    },

    [fetchAssignments.SUCCEEDED]: (state, { args, payload }) => {
      const index = state.entries.findIndex(item => item.id === args.id);

      if (index === -1) {
        return state;
      }

      state.entries[index].assignments = payload;

      return state;
    },

    [fetchWorklogList.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (index !== -1) {
        const results = unionBy(
          [...(state.entries[index].worklogList || [])],
          [...payload.results],
          'id'
        );

        state.entries[index].worklogList = results;
        state.hasMoreWorklogList = !!payload.next;
      }

      return state;
    },

    [readComments.SUCCEEDED]: (state, { payload }) => {
      if (!payload || payload.entity !== TASK) {
        return state;
      }

      const id = +payload.objectId;
      const index = state.entries.findIndex(task => task.id === id);

      if (index === -1) {
        return state;
      }

      state.entries[index].commentRecentId = payload.commentRecentId;

      return state;
    },

    [setRequestViewed.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.id);

      if (index !== -1) {
        state.entries[index].needToLog = false;
      }

      return state;
    },

    [setRequestViewedApproving.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.id);

      if (index !== -1) {
        state.entries[index].needToLogApprovingManager = false;
      }

      return state;
    },

    [fetchCoResponsibles.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.taskId);

      if (index !== -1) {
        state.entries[index].coResponsibles = payload.coResponsibles;
      }

      return state;
    },

    /** Filters */
    [combineActions(
      setFilterType,
      setFilterAuthor,
      setFilterController,
      setFilterResponsible,
      setFilterContacts,
      setFilterProject,
      setFilterOrderStatus,
      setFilterCreatedDateRange,
      setFilterStartedDateRange,
      setFilterEndedDateRange,
      setFilterCompletedDateRange,
      setFilterSubordinates,
      setFilterOrdering,
      setFilterExpired,
      setFilterSprint,
      setFilterSprintIsNull,
      setFilterSearch,
      setFilterStatus,
      setFilterFavorite,
      setFilterMyTasks,
      setFilterAsset,
      setFilterTag,
      setFilterMembers,
      setFilterTasks,
      setFilterRequests,
      setFilterHideSubtasks,
      clearFilter
    )]: state => {
      state.pageData = getTasksInitialPageData();
      state.currentPage = 1;
      state.entries = [];

      return state;
    },

    [setFilterHideSubtasks]: (state, { payload }) => {
      state.filter.hideSubtasks = payload;

      return state;
    },

    [setFilterTasks]: (state, { payload }) => {
      state.filter.tasks = payload;

      return state;
    },

    [setFilterRequests]: (state, { payload }) => {
      state.filter.requests = payload;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterOrdering]: (state, { payload }) => {
      state.filter.ordering = payload;

      if (payload.key.includes(TASK_ORDERING_COMPLETED_AT)) {
        state.filter.status = [];
      }

      return state;
    },

    [setFilterType]: (state, { payload }) => {
      state.filter.type = payload;

      return state;
    },

    [setFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setFilterStatus]: (state, { payload }) => {
      state.filter.status = payload;

      if (
        payload.length &&
        state.filter.ordering.key.includes(TASK_ORDERING_COMPLETED_AT)
      ) {
        state.filter.ordering = initialFilter.ordering;
      }

      return state;
    },

    [setFilterFavorite]: (state, { payload }) => {
      state.filter.isFavorite = payload;

      return state;
    },

    [setFilterAuthor]: (state, { payload }) => {
      state.filter.authors = payload;

      return state;
    },

    [setFilterController]: (state, { payload }) => {
      state.filter.controllers = payload;

      return state;
    },

    [setFilterResponsible]: (state, { payload }) => {
      state.filter.responsibles = payload;

      return state;
    },

    [setFilterAsset]: (state, { payload }) => {
      state.filter.asset = payload;

      return state;
    },

    [setFilterSubordinates]: (state, { payload }) => {
      state.filter.subordinates = payload;

      return state;
    },

    [setFilterExpired]: (state, { payload }) => {
      state.filter.expired = payload;

      return state;
    },

    [setFilterCreatedDateRange]: (state, { payload }) => {
      state.filter.createdDateRange = payload;

      return state;
    },

    [setFilterStartedDateRange]: (state, { payload }) => {
      state.filter.startedDateRange = payload;

      return state;
    },

    [setFilterEndedDateRange]: (state, { payload }) => {
      state.filter.endedDateRange = payload;

      return state;
    },

    [setFilterCompletedDateRange]: (state, { payload }) => {
      state.filter.completedDateRange = payload || [];

      return state;
    },

    [setFilterProject]: (state, { payload }) => {
      state.filter.projects = payload;

      return state;
    },

    [setFilterSprint]: (state, { payload }) => {
      state.filter.sprints = payload;
      state.filter.sprintIsNull = null;

      return state;
    },

    [setFilterSprintIsNull]: (state, { payload }) => {
      state.filter.sprintIsNull = payload;
      state.filter.sprints = [];

      return state;
    },

    [setFilterOrderStatus]: (state, { payload }) => {
      state.filter.orderStatus = payload;

      return state;
    },

    [setFilterContacts]: (state, { payload }) => {
      state.filter.contacts = payload;

      return state;
    },

    [setFilterMembers]: (state, { payload }) => {
      state.filter.members = payload;

      return state;
    },

    [setFilterMyTasks]: (state, { payload }) => {
      state.filter.myTasks = payload;

      return state;
    },

    [setFilterIsAutoCreated]: (state, { payload }) => {
      if (state.filter.isAutoCreated !== payload) {
        state.currentPage = 1;
        state.entries = [];

        state.filter.isAutoCreated = payload;
      }

      return state;
    },

    [clearFilter]: state => {
      // { ... } используем чтобы обновлять ссылку,
      // чтобы проходил запрос на обновление
      state.filter = {
        ...initialFilter,
        status: [],
        search: state.filter.search
      };

      return state;
    },

    // backlog
    [combineActions(
      setBacklogFilterAuthors,
      setBacklogFilterResponsibles,
      setBacklogFilterSprint,
      setBacklogFilterHideSubtasks,
      setBacklogFilterProject,
      setBacklogFilterSubordinates,
      setBacklogFilterSprintIsNull,
      setBacklogFilterSearch,
      setBacklogFilterFavorite,
      setBacklogFilterTag,
      setBacklogFilterMembers,
      setBacklogFilterRelations,
      setBacklogFilterCreatedDateRange,
      clearBacklogFilter
    )]: state => {
      state.currentPage = 1;
      state.entries = [];

      return state;
    },

    [setBacklogFilterSearch]: (state, { payload }) => {
      state.backlogFilter.search = payload;

      return state;
    },

    [setBacklogFilterAuthors]: (state, { payload }) => {
      state.backlogFilter.authors = payload;

      return state;
    },

    [setBacklogFilterMembers]: (state, { payload }) => {
      state.backlogFilter.members = payload;

      return state;
    },

    [setBacklogFilterTag]: (state, { payload }) => {
      state.backlogFilter.tag = payload;

      return state;
    },

    [setBacklogFilterResponsibles]: (state, { payload }) => {
      state.backlogFilter.responsibles = payload;

      return state;
    },

    [setBacklogFilterSprint]: (state, { payload }) => {
      state.backlogFilter.sprint = payload;
      state.backlogFilter.sprintIsNull = null;

      return state;
    },

    [setBacklogFilterHideSubtasks]: (state, { payload }) => {
      state.backlogFilter.hideSubtasks = payload;

      return state;
    },

    [setBacklogFilterProject]: (state, { payload }) => {
      state.backlogFilter.project = payload;

      return state;
    },

    [setBacklogFilterSubordinates]: (state, { payload }) => {
      state.backlogFilter.subordinates = payload;

      return state;
    },

    [setBacklogFilterSprintIsNull]: (state, { payload }) => {
      state.backlogFilter.sprintIsNull = payload;
      state.backlogFilter.sprint = null;

      return state;
    },

    [setBacklogFilterFavorite]: (state, { payload }) => {
      state.backlogFilter.isFavorite = payload;

      return state;
    },

    [setBacklogFilterRelations]: (state, { payload }) => {
      state.backlogFilter.relations[payload.key] = payload.value;

      return state;
    },

    [setBacklogFilterCreatedDateRange]: (state, { payload }) => {
      state.backlogFilter.createdDateRange = payload;

      return state;
    },

    [clearBacklogFilter]: state => {
      state.backlogFilter = {
        ...backlogInitialFilter,
        project: state.backlogFilter.project,
        search: state.backlogFilter.search
      };

      return state;
    },

    // Other
    [selectRequest]: (state, { payload }) => {
      state.selectedTasks = [payload, ...state.selectedTasks];

      return state;
    },

    [deselectRequest]: (state, { payload }) => {
      state.selectedTasks = state.selectedTasks.filter(
        item => item !== payload
      );

      return state;
    },

    [createWorklog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(task => task.id === payload.taskId);

      updateParentWorklogTotal({
        list: state.entries,
        value: payload.value,
        taskId: payload.taskId
      });

      if (index === -1) {
        return state;
      }

      const worklogList = state.entries[index].worklogList || [];
      state.entries[index].workLog += payload.value;
      state.entries[index].worklogList = [payload, ...worklogList];

      return state;
    },

    [updateWorklog.SUCCEEDED]: (state, { payload }) => {
      const index = getIndexById(payload.taskId, state.entries);

      if (index === -1) {
        return state;
      }

      if (payload.parent) {
        const indexParent = getIndexById(payload.parent, state.entries);

        if (indexParent !== -1) {
          state.entries[indexParent].workLog += payload.diff;
          state.entries[indexParent].workLogTotal += payload.diff;

          const indexWorklog = getIndexById(
            payload.id,
            state.entries[indexParent].worklogList
          );

          if (indexWorklog !== -1) {
            state.entries[indexParent].worklogList[indexWorklog] = payload;
          }
        }
      }

      const indexWorklog = getIndexById(
        payload.id,
        state.entries[index].worklogList
      );

      if (indexWorklog !== -1) {
        state.entries[index].worklogList[indexWorklog] = payload;
      }

      state.entries[index].workLog += payload.diff;

      return state;
    },

    [deleteWorklog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.taskId);

      updateParentWorklogTotal({
        list: state.entries,
        value: -payload.value,
        taskId: payload.taskId
      });

      if (index === -1) {
        return state;
      }

      const worklogList = state.entries[index].worklogList || [];
      state.entries[index].workLog -= payload.value;
      state.entries[index].worklogList = worklogList.filter(
        ({ id }) => id !== payload.worklogId
      );

      return state;
    },

    [deselectAll]: state => {
      state.selectedTasks = [];

      return state;
    },

    [changeRequestViewType]: (state, { payload }) => {
      state.viewType.value = payload;

      return state;
    },

    [setRequestData]: (state, { payload }) => {
      const { id, values } = payload;

      const index = state.entries.findIndex(item => item.id === id);

      if (index !== -1) {
        state.entries[index] = { ...state.entries[index], ...values };
      }

      return state;
    },

    // CALENDAR
    [combineActionsThunk(
      createAssetRecord.SUCCEEDED,
      updateAssetRecord.SUCCEEDED
    )]: (state, { args }) => {
      const index = state.entries.findIndex(
        task => task.id === args.record.objectId
      );

      if (index !== -1 && args.record.kind === TYPE_TASK) {
        state.entries[index] = {
          ...state.entries[index],
          dateStart: args.record.dateStart,
          dateEnd: args.record.dateEnd
        };
      }

      return state;
    },

    [fetchAssetTaskBookingData.SUCCEEDED]: (state, { args, payload }) => {
      const index = state.entries.findIndex(task => task.id === args.id);

      if (index !== -1) {
        state.entries[index].bookings = payload;
      }

      return state;
    },

    [clearTasksEntries]: (state, { payload }) => {
      if (!payload) {
        state.entries = [];
      }

      state.entries = state.entries.filter(entry => entry.status !== payload);

      return state;
    },

    [reorderTasksEntries]: (state, { payload }) => {
      const result = [...state.entries];

      const [removed] = result.splice(payload.startIndex, 1);

      result.splice(payload.endIndex, 0, {
        ...removed,
        status: payload.newStatus
      });

      state.entries = result;

      return state;
    },

    [resetReorderingTasksEntries]: (state, { payload }) => {
      state.entries = payload;

      return state;
    },

    [updateSubtasksDates.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        ({ parent }) => parent === payload.id
      );

      if (index !== -1) {
        state.entries[index].dateStart = payload.dateStart;
        state.entries[index].dateEnd = payload.dateEnd;
      }

      return state;
    },

    [combineActions(
      subscribeAttachment.SUCCEEDED,
      unsubscribeAttachment.SUCCEEDED
    )]: (state, { payload }) => {
      const { actionDeps } = payload;

      const index = state.entries.findIndex(
        ({ id }) => id === actionDeps.entityId
      );

      if (index !== -1) {
        const fileList = state.entries[index].fileList || [];
        const fileIndex = fileList.findIndex(
          file => file.fileId === payload.entityId
        );

        if (fileIndex !== -1) {
          fileList[fileIndex] = {
            ...fileList[fileIndex],
            isSubscribed: !fileList[fileIndex].isSubscribed
          };
        }

        state.entries[index].fileList = fileList;
      }

      return state;
    },

    [combineActions(subscribeAsset.SUCCEEDED, unsubscribeAsset.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const { actionDeps } = payload;

      const index = state.entries.findIndex(
        ({ id }) => id === actionDeps.entityId
      );

      if (index !== -1) {
        const relations = state.entries[index].relations || [];

        const relationIndex = relations.findIndex(
          relation => relation.objectId === Number(payload.entityId)
        );

        if (relationIndex !== -1) {
          relations[relationIndex] = {
            ...relations[relationIndex],
            relation: {
              ...relations[relationIndex].relation,
              isSubscribed: !relations[relationIndex].relation.isSubscribed
            }
          };
        }

        state.entries[index].relations = relations;
      }

      return state;
    },

    [changeValidityDate.SUCCEEDED]: (state, { payload }) => {
      const { actionDeps, attachment } = payload;

      const task = state.entries.find(({ id }) => id === actionDeps.entityId);
      if (task && task.fileList) {
        const file = task.fileList.find(f => f.fileId === attachment.fileId);

        if (file) {
          file.validityDate = attachment.validityDate;
        }
      }
      return state;
    }
  },
  initialState
);
