import React, { useRef, useState } from 'react';

import { ExpandHistoryActionsContext } from './expandhistoryActionsContext';

export const ExpandHistoryActionsProvider = ({ children }) => {
  const historyActionRefs = useRef({});

  const [historyActionsState, setHistoryActionsState] = useState({});
  const [isAllHistoryActionCollapsed, setIsAllHistoryActionCollapsed] =
    useState(false);

  const toggleAllHistoryActions = () => {
    setIsAllHistoryActionCollapsed(prevState => {
      const newState = !prevState;

      setHistoryActionsState(() =>
        Object.keys(historyActionRefs.current).reduce((acc, key) => {
          acc[key] = newState;
          return acc;
        }, {})
      );

      return newState;
    });
  };

  return (
    <ExpandHistoryActionsContext.Provider
      value={{
        isAllHistoryActionCollapsed,
        historyActionsState,
        historyActionRefs,
        setHistoryActionsState,
        toggleAllHistoryActions
      }}
    >
      {children}
    </ExpandHistoryActionsContext.Provider>
  );
};
