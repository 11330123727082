import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import VideoBtn from 'components/common/video-btn';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { FormEmployeeSelect } from 'components/common/hook-form';
import Avatar from 'components/common/avatar';
import { CloseIcon } from 'components/common/icon/icons';

import getFIO, { getFullName } from 'utils/get-fio';

import styles from './Form.module.scss';

export const Form = ({ isLoading, data, onSubmit, onDelete }) => {
  const methods = useForm();

  const { t } = useTranslation('Common');

  const {
    videoSlug,
    description,
    employeeListTitle,
    selectProps = {},
    submitText,
    employees
  } = data;

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        {videoSlug && <VideoBtn slug={videoSlug} className={styles.videoBtn} />}

        <Typography.Paragraph>{description}</Typography.Paragraph>

        <FormEmployeeSelect
          name="employees"
          label={t('ChooseEmployee')}
          data-qa="qa-jwaimxtkiwfn8tk"
          isMulti
          allowSetYourself={false}
          params={{
            exclude: employees.map(({ id }) => id),
            isPostMethod: true
          }}
          {...selectProps}
        />

        {!employees.length ? null : (
          <div className={styles.content}>
            <Typography.Paragraph>{employeeListTitle}</Typography.Paragraph>

            <div className={styles.employeeList}>
              {employees.map(e => (
                <div key={e.id} className={styles.employee}>
                  <div className={styles.infoWrap}>
                    <div className={styles.info}>
                      <Avatar
                        size={24}
                        className={styles.avatar}
                        src={e.avatarFile?.url}
                      >
                        {getFIO(e)}
                      </Avatar>

                      <Typography.Text ellipsis>
                        {getFullName(e)}
                      </Typography.Text>
                    </div>

                    {e?.taskRole && (
                      <Typography.Text ellipsis size="small" color="black">
                        {e.taskRole}
                      </Typography.Text>
                    )}
                  </div>

                  {e?.canDelete && (
                    <Button
                      icon={<CloseIcon />}
                      type="text"
                      mood="none"
                      className={styles.deleteBtn}
                      onClick={() => onDelete(e)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          width="expanded"
          loading={isLoading}
          className={styles.submitBtn}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
