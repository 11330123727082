import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET, TYPE_CHANNEL } from 'constants/index';

import CustomSelect from '../custom-select';
import AssetsSelect from '../assets-select';
import ChannelSelect from '../channel-select';

const OPTIONS = [
  {
    value: TYPE_CHANNEL,
    label: 'Channel',
    ns: 'Common'
  }
  // commented until future realization with sla for assets
  // {
  //   value: TYPE_ASSET,
  //   label: 'Asset',
  //   ns: 'TaskLinks'
  // }
];

export const ObjectSlaSelect = ({
  value: selectValue,
  onChange,
  defaultType,
  contact,
  isDisabled,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(
    () => OPTIONS.find(o => o.value === defaultType) || null
  );

  const [localValue, setLocalValue] = useState({
    [defaultType]: selectValue
  });

  useEffect(() => {
    if (defaultType && selectValue) {
      setCurrentType(OPTIONS.find(o => o.value === defaultType) || null);
      setLocalValue({
        [defaultType]: selectValue
      });
    }
  }, [selectValue]);

  const { t } = useTranslation(['Common', 'Requests']);

  const handleTypeChange = selectedType => {
    if (!selectedType) {
      setCurrentType(null);
      setLocalValue({});
      onChange(null);
      return;
    }

    setCurrentType(selectedType);
    setLocalValue({});
    onChange(null);
  };

  const getCurrentSelect = () => {
    if (!currentType) {
      return null;
    }

    const commonProps = {
      value: localValue[currentType.value],
      onChange: selectedItem => {
        setLocalValue({
          [currentType.value]: selectedItem
        });
        onChange({
          type: currentType.value,
          item: selectedItem
        });
      },
      isClearable: true,
      isDisabled,
      closeMenuOnSelect: true,
      style: { margin: '16px 0' }
    };

    if (currentType.value === TYPE_CHANNEL) {
      return (
        <ChannelSelect
          label={`${t('Channel', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
          addEntityButtonData={null}
          params={{ contact, ordering: 'name', lite: false }}
          valueText={t('ChooseChannel')}
          {...commonProps}
        />
      );
    }

    if (currentType.value === TYPE_ASSET) {
      return <AssetsSelect valueText={t('ChooseAsset')} {...commonProps} />;
    }

    return null;
  };

  return (
    <>
      <CustomSelect
        value={currentType}
        onChange={handleTypeChange}
        valueText={t('ChooseSLAEntity', { ns: 'Requests' })}
        options={OPTIONS}
        isClearable
        isDisabled={isDisabled}
        {...props}
      />

      {getCurrentSelect()}
    </>
  );
};
