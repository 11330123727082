import axios from 'axios';
import { LANGUAGE } from 'i18n-config';
import { errorResponseHandler } from 'api/utils';
import { handle400 } from 'api/v1';

import { config } from 'config';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_URL}/ai/api`,
  params: {
    access_token: localStorage.getItem('token')
  }
});

api.interceptors.request.use(
  configuration => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => errorResponseHandler(error, api, () => handle400(error.response))
);

export default () => ({
  sendMessage: ({ message }) =>
    api.post(`/v1/messages/`, {
      question: message.text
    })
});
