import {
  GrowthBook,
  GrowthBookProvider,
  FeaturesReady
} from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ENVIRONMENT_PROD } from 'constants/index';

import { getActiveWorkspace, getWorkspaceId } from 'store/workspace';
import { getUserEmail } from 'store/user';

import { config } from 'config';

const isProduction = config.REACT_APP_ENVIRONMENT === ENVIRONMENT_PROD;

const growthbook = new GrowthBook({
  apiHost: config.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: config.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction
});

growthbook.init({ streaming: true }).catch(error => {
  // TODO: add Sentry
  console.error('Failed to initialize GrowthBook:', error);
});

const GrowthBookClientProvider = ({ children }) => {
  const workspaceId = useSelector(getWorkspaceId);
  const workspace = useSelector(getActiveWorkspace);
  const userEmail = useSelector(getUserEmail);

  const { createdAt: workspaceCreatedAt } = workspace || {};

  useEffect(() => {
    growthbook.setAttributes({
      workspaceId,
      createdAt: workspaceCreatedAt,
      userEmail
    });
  }, [workspaceId, workspaceCreatedAt, userEmail]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <FeaturesReady timeout={2000}>{children}</FeaturesReady>
    </GrowthBookProvider>
  );
};

export default GrowthBookClientProvider;
