import React, { useState } from 'react';
import classnames from 'classnames';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PROJECTS, SPRINTS } from 'constants/index';

import Drawer from 'components/common/drawer';
import CollapsePanel from 'components/common/collapse-panel';
import BackBtn from 'components/common/back-btn';

import { getRouterUrlSubmenu } from 'store/router';

import { useTaskDataProvider } from 'hooks';
import useRoutesService from 'services/routes';

import { ExpandHistoryActionsProvider } from 'providers/expand-history-actions-provider/expandhistoryActionsProvider';
import Details from './details';
import List from './list';
import Tabs from './tabs';
import EmptyError from './empty-error';

import styles from './details.module.scss';

const TaskDetailDrawer = ({ visible, ...drawerProps }) => {
  const [afterChangeVisible, setAfterChangeVisible] = useState(false);
  const [task, isLoading, errorStatus] =
    useTaskDataProvider(afterChangeVisible);

  const { state = {} } = useLocation();
  const routes = useRoutesService();

  const subView = useSelector(getRouterUrlSubmenu);

  const { t } = useTranslation('Task');

  const onGoBack = () => {
    const isProjectView = subView.includes(PROJECTS);
    const isSprintsView = subView.includes(SPRINTS);

    if (isProjectView && subView.includes('progress')) {
      return routes.toProject((task.project || {}).id);
    }

    if (isProjectView && subView.includes('backlog')) {
      return routes.toBacklog((task.project || {}).id);
    }

    if (isSprintsView) {
      return routes.toSprints((task.project || {}).id);
    }

    return routes.toTasks();
  };

  return (
    <Drawer
      closable={false}
      destroyOnClose
      open={visible}
      fullWidth
      isContent
      afterOpenChange={setAfterChangeVisible}
      documentTitleProps={{ title: task && task.title, postfix: '' }}
      push={false}
      {...drawerProps}
    >
      <div className={styles.body} data-qa="qa-bfuem328ec7ws42">
        <CollapsePanel
          name="collapse-task-list"
          data-qa="qa-phcvg6d7p9dgsj3"
          head={
            <BackBtn
              style={{ padding: 0 }}
              data-qa="qa-3lupvjgdlbmt1rm"
              onClick={state.isFromNotification ? undefined : onGoBack}
            >
              {t(state.isFromNotification ? 'BackBtn' : 'BackToTasksBtn')}
            </BackBtn>
          }
          labels={{
            close: t('CollapseTaskListTip'),
            open: t('ExpandTaskListTip'),
            back: t('BackBtn')
          }}
          contentClassName={classnames(styles.block, styles.blockWidth)}
          className={styles.collapsePanel}
        >
          <List />
        </CollapsePanel>

        {task && afterChangeVisible && !isLoading && (
          <>
            <Details
              task={task}
              className={styles.block}
              data-qa="qa-9dy7bt2joxn1nuc"
            />
            <ExpandHistoryActionsProvider>
              <Tabs
                task={task}
                className={styles.block}
                data-qa="qa-yuqbo09zta1yvzk"
              />
            </ExpandHistoryActionsProvider>
          </>
        )}

        {(!task || isLoading) && !errorStatus && (
          <Spin className={styles.spin} />
        )}

        {!task && errorStatus && afterChangeVisible && (
          <EmptyError status={errorStatus} />
        )}
      </div>
    </Drawer>
  );
};

export default TaskDetailDrawer;
