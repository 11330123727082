import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { TASK_FIELD_DESCRIPTION, TYPE_REQUEST } from 'constants/index';

import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import DetailsField from 'components/common/details-field';
import FormNewEditor from 'components/common/hook-form/markdown';

import styles from './RequestFieldEditor.module.scss';

export const FormNewEditorWrapper = ({ id }) => {
  const { t } = useTranslation(['Common']);

  const { getValues, setValue } = useFormContext();
  const formValues = getValues();

  const { changeValidityDateStateValue } = useValidityDateFileList({
    fileList: formValues[TASK_FIELD_DESCRIPTION].fileList,
    onChange: updatedFileList =>
      setValue(TASK_FIELD_DESCRIPTION, {
        ...formValues[TASK_FIELD_DESCRIPTION],
        fileList: updatedFileList
      })
  });

  return (
    <>
      <DetailsField
        className={styles.detailsField}
        iconType="description"
        title={t('Description')}
      />
      <FormNewEditor
        name={TASK_FIELD_DESCRIPTION}
        actionsDeps={{
          taskId: id,
          sendCopyToComment: false
        }}
        destination={{
          entityId: id,
          entityType: TYPE_REQUEST
        }}
        resizeInput
        allowManageSubscribers
        hideValidityDateAction
        validityDateDeps={{ changeValidityDateStateValue }}
        highlightAttachmentsBackground
      />
    </>
  );
};
