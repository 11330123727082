import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { ObjectSlaSelect } from 'components/common/controls/custom-select/object-sla-select';

import FormItem from '../form-item';

export const FormObjectSlaSelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => <ObjectSlaSelect {...field} {...props} />}
      />
    </FormItem>
  );
};
