import React from 'react';
import classnames from 'classnames';

import Icon from 'components/common/icon';
import { AgentSupportIcon } from 'components/common/icon/icons';

import styles from './AgentSupportIconWrap.module.scss';

export const AgentSupportIconWrap = ({ size }) => (
  <Icon
    component={AgentSupportIcon}
    className={classnames(styles.icon, {
      [styles.middle]: size === 'middle',
      [styles.big]: size === 'big'
    })}
  />
);
