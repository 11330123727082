import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { fetchChannelsLocal } from 'store/channels';

const useRequestsTaskInfo = () => {
  const dispatch = useDispatch();

  const fetchChannelData = useCallback(
    async channelId => {
      const { results } = await dispatch(fetchChannelsLocal({ id: channelId }));
      return results[0];
    },
    [dispatch]
  );

  const getTaskInfo = useCallback(
    async ({ channelId, contactId, slaObjectType, slaObjectId }) => {
      if (!slaObjectType && !slaObjectId && !contactId) {
        return { taskInfo: {} };
      }

      const channel = await fetchChannelData(channelId);
      const isSlaActive = channel?.config?.slaConfig?.isActive;

      return createTaskInfo(
        contactId,
        slaObjectType,
        slaObjectId,
        isSlaActive,
        channel
      );
    },
    [fetchChannelData]
  );

  const createTaskInfo = (
    contactId,
    slaObjectType,
    slaObjectId,
    isSlaActive,
    channel
  ) => ({
    taskInfo: {
      executorSla: isSlaActive ? channel.config.slaConfig.executorSla : null,
      slaObjectType,
      slaObjectId,
      contactId: contactId || null
    }
  });

  return { getTaskInfo };
};

export default useRequestsTaskInfo;
