import React from 'react';

export const StoryIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8086 12.8373L11.0234 10.8236V5.98962C11.0234 5.90369 10.9531 5.83337 10.8672 5.83337H9.92773C9.8418 5.83337 9.77148 5.90369 9.77148 5.98962V11.3685C9.77148 11.4193 9.79492 11.4662 9.83594 11.4955L13.0664 13.851C13.1367 13.9017 13.2344 13.8861 13.2852 13.8178L13.8437 13.056C13.8945 12.9838 13.8789 12.8861 13.8086 12.8373Z"
      fill="currentColor"
    />
    <path
      d="M0.562575 8.30831L1.96401 8.41208L5.32426 8.6605C5.35892 8.6629 5.3922 8.67504 5.42027 8.69551C5.44834 8.71598 5.47006 8.74396 5.48294 8.77623C5.49581 8.8085 5.49932 8.84374 5.49305 8.87792C5.48679 8.91209 5.471 8.9438 5.44752 8.9694L2.84116 11.808C2.76193 11.897 2.61923 11.887 2.55256 11.7873L0.395938 8.59455C0.309395 8.46924 0.408354 8.29763 0.562575 8.30831Z"
      fill="currentColor"
    />
    <path
      d="M2.81217 10.6358C2.65556 8.72675 3.20934 6.82705 4.36726 5.30115C5.52519 3.77524 7.20574 2.73059 9.08654 2.36758C10.9673 2.00456 12.9159 2.34875 14.5586 3.33411C16.2012 4.31947 17.4222 5.87663 17.9873 7.70687C18.5524 9.53712 18.4219 11.5116 17.6208 13.2515C16.8196 14.9914 15.4043 16.3743 13.6462 17.1349C11.8882 17.8954 9.91124 17.9801 8.09459 17.3727C6.27795 16.7652 4.74955 15.5084 3.80256 13.8434"
      stroke="currentColor"
      strokeWidth="1.3"
    />
  </svg>
);

export default StoryIcon;
