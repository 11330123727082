import React, { useState } from 'react';
import api from 'api';

import useAmplitude from 'hooks/amplitude/use-amplitude';

import { AgentSupportContext } from './agentSupportContext';

export const AgentSupportProvider = ({ children }) => {
  const allowCollapseBtn = false;

  const [isOpen, setIsOpen] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [prompts, sendPrompt] = useState([]);

  const amplitude = useAmplitude();

  const sendMessage = async message => {
    try {
      setIsTyping(true);

      const res = await api.agentSupport.sendMessage({ message });

      setMessages(prevMessages => [
        ...prevMessages,
        {
          text: res.data.answer,
          createdAt: new Date(),
          isUser: false,
          id: crypto.randomUUID()
        }
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const toggleWidget = () => {
    setIsOpen(!isOpen);

    if (isOpen) {
      setIsCollapse(false);
    }
  };

  const handleSendMessage = async messageData => {
    amplitude.agentSupportSendMessageEvent();
    setMessages(prevMessages => [...prevMessages, messageData]);

    await sendMessage({ text: messageData.text });
  };

  const toggleCollapse = () => setIsCollapse(!isCollapse);

  const closeWidget = () => {
    setIsOpen(false);
    setIsCollapse(false);
    setMessages([]);
    sendPrompt([]);
  };

  return (
    <AgentSupportContext.Provider
      value={{
        isOpen,
        isCollapse,
        messages,
        sendPrompt,
        prompts,
        allowCollapseBtn,
        isTyping,
        toggleWidget,
        toggleCollapse,
        closeWidget,
        handleSendMessage
      }}
    >
      {children}
    </AgentSupportContext.Provider>
  );
};
