import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tooltip } from 'antd';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { useAgentSupport } from 'providers/agent-support-provider';

import { AgentSupportIconWrap } from '../agent-support-icon-wrap';

import styles from './AgentSupportHeader.module.scss';

const { Text } = Typography;

export const AgentSupportHeader = ({ classNameDnd }) => {
  const { t } = useTranslation(['AgentSupport', 'AIAssistant']);

  const { allowCollapseBtn, toggleCollapse, closeWidget } = useAgentSupport();

  return (
    <div className={styles.root}>
      <div className={classnames(classNameDnd, styles.innerHeader)}>
        <div className={styles.leftSide}>
          <AgentSupportIconWrap size="middle" />

          <div className={styles.infoWrap}>
            <Text color="black" weight="semibold">
              {t('AgentSupportTitle')}
            </Text>
          </div>
        </div>
      </div>

      <div className={styles.rightSide}>
        <Tooltip
          title={t('DialogsHistoryTip', { ns: 'AIAssistant' })}
          mouseEnterDelay={0.5}
        >
          <Button type="text" className={styles.button}>
            <Icon type="history" color="black-55" />
          </Button>
        </Tooltip>

        {allowCollapseBtn && (
          <Button
            type="text"
            onClick={toggleCollapse}
            className={styles.button}
          >
            <Icon type="minus" color="black-55" />
          </Button>
        )}

        <Button type="text" className={styles.button} onClick={closeWidget}>
          <Icon type="close" color="black-55" />
        </Button>
      </div>
    </div>
  );
};
