import React from 'react';

export const AgentSupportIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 2V3.5M7 9.5V16.25M11.5 9.5V16.25M4 12.5L7 11M11.5 11L14.5 12.5M7 14H11.5M7.75 6.5V6.5075M10.75 6.5V6.5075M4.75 5C4.75 4.60218 4.90804 4.22064 5.18934 3.93934C5.47064 3.65804 5.85218 3.5 6.25 3.5H12.25C12.6478 3.5 13.0294 3.65804 13.3107 3.93934C13.592 4.22064 13.75 4.60218 13.75 5V8C13.75 8.39782 13.592 8.77936 13.3107 9.06066C13.0294 9.34196 12.6478 9.5 12.25 9.5H6.25C5.85218 9.5 5.47064 9.34196 5.18934 9.06066C4.90804 8.77936 4.75 8.39782 4.75 8V5Z"
      stroke="#9F6BD7"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AgentSupportIcon;
