import React, { useState } from 'react';
import { Divider } from 'antd';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';

import { useAgentSupport } from 'providers/agent-support-provider';

import { AgentSupportHeader } from './components/agent-support-header';
import { AgentSupportMessageThread } from './components/agent-support-message-thread';
import { AgentSupportMessageInput } from './components/agent-support-message-input';
import { AgentSupportCollapseElement } from './components/agent-support-collapse-element';

import styles from './AgentSupportWidget.module.scss';

const CLASS_NAME_DND = 'agent-support-header-handle';

export const AgentSupportWidget = () => {
  const [width, setWidth] = useState(350);
  const [height, setHeight] = useState(520);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const { isOpen, toggleCollapse, isCollapse } = useAgentSupport();

  const handleDrag = (e, ui) => {
    const { x, y } = position;

    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  return (
    isOpen && (
      <div>
        {isCollapse && (
          <AgentSupportCollapseElement toggleCollapse={toggleCollapse} />
        )}

        {!isCollapse && (
          <Draggable
            axis="x"
            position={position}
            bounds="body"
            onDrag={handleDrag}
            handle={`.${CLASS_NAME_DND}`}
          >
            <Resizable
              className={styles.root}
              defaultSize={{
                width,
                height
              }}
              minWidth={350}
              minHeight={520}
              maxWidth={1000}
              maxHeight={900}
              onResizeStop={(event, direction, elementRef, delta) => {
                setWidth(prevVal => prevVal + delta.width);
                setHeight(prevVal => prevVal + delta.height);
              }}
            >
              <div className={styles.widgetWrap}>
                <AgentSupportHeader classNameDnd={CLASS_NAME_DND} />

                <Divider className={styles.divider} />

                <div className={styles.content}>
                  <AgentSupportMessageThread />

                  <div className={styles.messageInputWrap}>
                    <AgentSupportMessageInput />
                  </div>
                </div>
              </div>
            </Resizable>
          </Draggable>
        )}
      </div>
    )
  );
};
