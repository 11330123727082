import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalDialog } from 'antd';

import Icon from 'components/common/icon';

export const Modal = ({
  contentClassName,
  contentStyle,
  onClose,
  children,
  dataTestId,
  ...props
}) => (
  <ModalDialog
    width={740}
    footer={null}
    closeIcon={<Icon type="close" color="black-55" />}
    onOk={onClose}
    onCancel={onClose}
    {...props}
  >
    <div
      className={contentClassName}
      style={contentStyle}
      data-testid={dataTestId}
    >
      {children}
    </div>
  </ModalDialog>
);

Modal.propTypes = {
  contentClassName: PropTypes.string,
  onClose: PropTypes.func,
  contentStyle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dataTestId: PropTypes.string
};

Modal.defaultProps = {
  contentClassName: undefined,
  children: undefined,
  contentStyle: {},
  onClose: () => {},
  dataTestId: undefined
};

export default Modal;
