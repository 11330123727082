import React, { useState } from 'react';
import { Input as InputAntd } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';

import { useAgentSupport } from 'providers/agent-support-provider';

import styles from './AgentSupportMessageInput.module.scss';

const { TextArea } = InputAntd;
const { Text } = Typography;

export const AgentSupportMessageInput = () => {
  const { t } = useTranslation('AIAssistant');

  const [value, setValue] = useState('');

  const { handleSendMessage } = useAgentSupport();

  const handleSubmit = () => {
    const messageData = {
      text: value,
      createdAt: new Date(),
      isUser: true,
      id: crypto.randomUUID()
    };

    handleSendMessage(messageData);
    setValue('');
  };

  const handleEnterSubmit = event => {
    if (event.ctrlKey && event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <div className={styles.root}>
        <TextArea
          value={value}
          onChange={({ target }) => setValue(target.value)}
          placeholder={t('EnterYourTextPlchldr')}
          className={styles.textarea}
          onKeyDown={handleEnterSubmit}
          autoSize={{
            minRows: 2,
            maxRows: 5
          }}
        />

        <div className={styles.toolbar}>
          <Button
            disabled={!value.trim()}
            type="text"
            className={styles.sendButton}
            onClick={handleSubmit}
          >
            {t('SendBtn')}
          </Button>
        </div>
      </div>

      <Text className={styles.bottomText} color="black-35" size="small">
        {t('ChatGPTDesc')}
      </Text>
    </>
  );
};
