import { createAction } from 'redux-actions';
import moment from 'moment';

import { fetchTagsByEntity } from 'store/tags';

import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';

import createActionThunk from '../actions-thunk';
import api from '../../api';
import { getWorkspaceId } from '../workspace';
import {
  getAllRequestsLength,
  getItemsPerPage,
  getBacklogFilterRequests,
  getProjectFilterRequests,
  getFilterRequests,
  getRequestViewType
} from './selectors';
import mapValue from '../../utils/map-value';
import {
  STATUSES_EXCLUDE_BACKLOG,
  BACKLOG,
  TASK_VIEW_TYPE_GANTT,
  TYPE_REQUEST
} from '../../constants';

const getTasksParams = (
  state,
  access,
  projectId,
  status,
  axiosCancelTokenSource
) => {
  const filter = projectId
    ? getProjectFilterRequests(state)
    : getFilterRequests(state);

  // TODO: поправить момент со статусами (непонятно что происходит)
  const hasFilterStatus =
    Array.isArray(filter.status) && filter.status.length > 0; // если приходят значения из фильтра

  // в случае борда приходит статус STATUSES_EXCLUDE_BACKLOG
  const statusFilter = hasFilterStatus && filter.status.map(mapValue);
  const statusParams = status || statusFilter || STATUSES_EXCLUDE_BACKLOG;
  const authors = filter.authors.map(mapValue);
  const responsibles = filter.responsibles.map(mapValue);
  const asset = (filter.asset || []).map(mapValue);
  const controllers = filter.controllers.map(mapValue);
  const kind = filter.type.map(mapValue);

  const {
    isFavorite,
    sprintIsNull,
    expired,
    subordinates,
    myTasks,
    isAutoCreated,
    tag,
    hideSubtasks
  } = filter;

  const createdDateRange = (filter.createdDateRange || {}).value;
  const startedDateRange = (filter.startedDateRange || {}).value;
  const endedDateRange = (filter.endedDateRange || {}).value;
  const completedDateRange = (filter.completedDateRange || {}).value;

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  const project = projectId || filter.projects.map(mapValue);
  const orderStatus = filter.orderStatus.map(mapValue);
  const contacts = filter.contacts.map(mapValue);
  const members = filter.members.map(mapValue);
  const tasks = filter.tasks.map(mapValue);
  const requests = filter.requests.map(mapValue);
  const channelChats = filter.channelChats.map(mapValue);
  const sprints = filter.sprints.map(mapValue);
  const ordering = (filter.ordering || {}).key;

  return [
    filter.search,
    kind,
    statusParams,
    authors,
    responsibles,
    controllers,
    subordinates,
    expired,
    createdDateRange,
    startedDateRange,
    endedDateRange,
    completedDateRange,
    project,
    orderStatus,
    contacts,
    sprints,
    sprintIsNull,
    access,
    ordering,
    isFavorite,
    myTasks,
    isAutoCreated,
    hideSubtasks,
    asset,
    tagsCondition,
    tagsIds,
    members,
    tasks,
    requests,
    channelChats,
    axiosCancelTokenSource
  ];
};

const getBacklogParams = (state, access, project) => {
  let {
    // eslint-disable-next-line prefer-const
    search,
    authors,
    sprint,
    responsibles,
    // eslint-disable-next-line prefer-const
    sprintIsNull,
    // eslint-disable-next-line prefer-const
    hideSubtasks,
    // eslint-disable-next-line prefer-const
    isFavorite,
    // eslint-disable-next-line prefer-const
    subordinates,
    // eslint-disable-next-line prefer-const
    tag,
    members,
    // eslint-disable-next-line prefer-const
    relations,
    // eslint-disable-next-line prefer-const
    createdDateRange
  } = getBacklogFilterRequests(state);
  authors = authors.map(mapValue);
  responsibles = responsibles.map(mapValue);
  members = members.map(mapValue);
  sprint = (sprint || {}).value;

  let { orderStatus, contact, asset, task, request, channelChat } = relations;
  orderStatus = orderStatus.map(mapValue);
  contact = contact.map(mapValue);
  asset = asset.map(mapValue);
  task = task.map(mapValue);
  request = request.map(mapValue);
  channelChat = channelChat.map(mapValue);

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  const kind = undefined;
  const status = BACKLOG;
  const expired = undefined;
  const controllers = [];
  const createdDateRangeFilter = (createdDateRange || {}).value;
  const startedDateRange = undefined;
  const endedDateRange = undefined;
  const completedDateRange = undefined;
  const ordering = undefined;
  const myTasks = undefined;
  const isAutoCreated = undefined;

  return [
    search,
    kind,
    status,
    authors,
    responsibles,
    controllers,
    subordinates,
    expired,
    createdDateRangeFilter,
    startedDateRange,
    endedDateRange,
    completedDateRange,
    project,
    orderStatus,
    contact,
    sprint,
    sprintIsNull,
    access,
    ordering,
    isFavorite,
    myTasks,
    isAutoCreated,
    hideSubtasks,
    asset,
    tagsCondition,
    tagsIds,
    members,
    task,
    request,
    channelChat
  ];
};

// --REQUEST-- //
export const fetchRequests = createActionThunk(
  'requests/fetch-requests',
  ({
    getState,
    dispatch,
    project,
    access = 'full',
    isBacklog = false,
    limit,
    offset,
    status,
    withoutCancelling,
    axiosCancelTokenSource
  }) => {
    const state = getState();

    const taskViewType = getRequestViewType(state);
    const fetchTags = taskViewType.value !== TASK_VIEW_TYPE_GANTT;

    const fetch = withoutCancelling
      ? api.requests.fetchWithoutCancelling
      : api.requests.fetch;

    const workspaceId = getWorkspaceId(state);
    const resultLimit = limit !== undefined ? limit : getItemsPerPage(state);
    const resultOffset =
      offset !== undefined ? offset : getAllRequestsLength(state);

    const params = isBacklog
      ? getBacklogParams(state, access, project)
      : getTasksParams(state, access, project, status, axiosCancelTokenSource);

    return fetch(workspaceId, resultOffset, resultLimit, ...params).then(
      ({ data }) => {
        const requestIds = data.results.map(asset => asset.id);

        if (requestIds && fetchTags) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_REQUEST,
              entityIds: requestIds
            })
          );
        }

        return {
          results: data.results,
          totalItems: data.count,
          search: params[0] || '',
          isBacklog
        };
      }
    );
  }
);

export const fetchRequestsLocal = createActionThunk(
  'requests/fetch-requests-local',
  ({
    getState,
    search,
    offset,
    status = [],
    access,
    contact,
    limit,
    orderStatus,
    withoutCancelling,
    endedDateRange,
    myTasks,
    expired,
    subordinates,
    project,
    exclude,
    kind
  }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);
    const resultLimit = limit || getItemsPerPage(state);
    const resultStatus = status.map(mapValue);

    const taskKind = kind || null;

    const author = [];
    const responsible = [];
    const controller = [];

    const createdDateRange = undefined;
    const startedDateRange = undefined;
    const completedDateRange = undefined;

    const sprint = undefined;
    const sprintIsNull = undefined;
    const ordering = undefined;
    const isFavorite = undefined;
    const isAutoCreated = undefined;
    const hideSubtasks = undefined;
    const asset = undefined;
    const tagsCondition = undefined;
    const tagsIds = undefined;
    const members = undefined;
    const tasks = undefined;
    const requests = undefined;
    const channelChats = undefined;

    const fetch = withoutCancelling
      ? api.requests.fetchWithoutCancelling
      : api.requests.fetch;

    return fetch(
      workspaceId,
      offset,
      resultLimit,
      search,
      taskKind,
      resultStatus,
      author,
      responsible,
      controller,
      subordinates,
      expired,
      createdDateRange,
      startedDateRange,
      endedDateRange,
      completedDateRange,
      project,
      orderStatus,
      contact,
      sprint,
      sprintIsNull,
      access,
      ordering,
      isFavorite,
      myTasks,
      isAutoCreated,
      hideSubtasks,
      asset,
      tagsCondition,
      tagsIds,
      members,
      tasks,
      requests,
      channelChats,
      exclude
    ).then(({ data }) => ({
      results: data.results,
      totalItems: data.count,
      search
    }));
  }
);

export const fetchScheduledRequestNextDateLocal = createActionThunk(
  'requests/fetch-scheduled-request-next-date-local',
  ({ getState, dateEnd, dateStart, freqType, freqInterval, weekdays }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return api.taskTemplates
      .fetchScheduledTaskNextDate({
        workspaceId,
        dateEnd,
        dateStart,
        freqType,
        freqInterval,
        weekdays
      })
      .then(({ data }) => data);
  }
);

export const fetchRequest = createActionThunk(
  'requests/fetch-request',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.fetchOne(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchRequestLocal = createActionThunk(
  'requests/fetch-request-local',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.fetchOne(workspaceId, id).then(({ data }) => data);
  }
);

export const createRequest = createActionThunk(
  'requests/create-request',
  ({ task, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .create(workspaceId, {
        ...task,
        fileList: getFileIds(task.fileList)
      })
      .then(({ data }) => data);
  }
);

export const createRequestsInBackground = createActionThunk(
  'requests/create-requests-in-background',
  ({ task, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.backgroundTasks(workspaceId, {
      ...task,
      fileList: getFileIds(task.fileList)
    });
  }
);

export const setRequestFavorite = createActionThunk(
  'requests/set-request-favorite',
  ({ getState, taskId, isFavorite }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .setFavorite(workspaceId, taskId, isFavorite)
      .then(({ data }) => data);
  }
);

export const editRequestFields = createActionThunk(
  'requests/edit-request',
  ({ value, getState }) => {
    const workspaceId = getWorkspaceId(getState());
    const { description, dateEnd } = value.data;

    return api.requests
      .update(workspaceId, value.id, {
        ...value.data,
        dateEnd: dateEnd && moment(dateEnd).toISOString(true),
        description
      })
      .then(({ data }) => data);
  }
);

export const partialRequestUpdate = createActionThunk(
  'requests/partial-update',
  ({ id, values, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .partialUpdate({ workspaceId, id, values })
      .then(({ data }) => data);
  }
);

export const editRequestDates = createActionThunk(
  'requests/edit-request-dates',
  ({ dates, id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .updateDates({ workspaceId, id, dates })
      .then(({ data }) => data);
  }
);

// Разделил удаление на два action, потому что при удаленнии задачи она удалялась из стора до закрытия
// дравера, что приводило к повторной загрузке удаленной задачи и падала ошибка
export const deleteRequestLocal = createActionThunk(
  'requests/delete-request-local',
  ({ id, projectId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.delete(workspaceId, id).then(() => ({ id, projectId }));
  }
);

export const deleteRequest = createAction('requests/delete-request');

export const setEstimate = createActionThunk(
  'requests/set-estimate',
  ({ id, estimation, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .estimate(workspaceId, id, estimation)
      .then(() => ({ id, estimation }));
  }
);

export const setPointEstimate = createActionThunk(
  'requests/set-point-estimate',
  ({ id, storyPoint, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const estimation = {
      storyPoint,
      isStoryPointEstimation: true
    };

    return api.requests
      .pointEstimate(workspaceId, id, estimation)
      .then(() => ({ id, storyPoint }));
  }
);

// --ATTACHMENNTS-- //
export const fetchAttachments = createActionThunk(
  'requests/fetch-attachments',
  ({ id, source, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchAttachments({ workspaceId, id, source })
      .then(({ data }) => data.results);
  }
);

export const changeTaskAttachments = createAction(
  'requests/change-attachments'
);

// --CONTACTS-- //
export const fetchContacts = createActionThunk(
  'requests/fetch-contacts',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.fetchContacts(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchContact = createActionThunk(
  'requests/fetch-contact',
  ({ id, contactId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .fetchContact(workspaceId, id, contactId)
      .then(({ data }) => data);
  }
);

export const deleteContact = createActionThunk(
  'requests/delete-contact',
  ({ id, contactId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .deleteContact(workspaceId, id, contactId)
      .then(({ data }) => data);
  }
);

// --RELATIONS-- //
export const fetchRelations = createActionThunk(
  'requests/fetch-relations',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const changeRelations = createActionThunk(
  'requests/change-relations',
  ({ id, relations, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.changeRelations({ workspaceId, id, relations });
  }
);

// --MEMBERS-- //
export const fetchAssignments = createActionThunk(
  'requests/fetch-assignments',
  ({ getState, id, directMembersOnly }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchAssignments(workspaceId, id, directMembersOnly)
      .then(({ data }) => data.results);
  }
);

export const changeAssignments = createActionThunk(
  'requests/change-assignments',
  ({ id, employees, isDelete, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.assignments({ workspaceId, id, employees, isDelete });
  }
);

export const fetchUsersWithAccess = createActionThunk(
  'requests/fetch-user-with-access',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchUsersWithAccess(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const updateUsersAccess = createActionThunk(
  'requests/update-user-access',
  ({ id, employees, isDelete, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests.modifyUsersAccess({
      workspaceId,
      id,
      employees,
      isDelete
    });
  }
);

// --WORKLOG-- //
export const fetchWorklogList = createActionThunk(
  'requests/fetch-worklog-list',
  ({ id, getState, offset, limit }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .fetchWorklogList(workspaceId, id, null, offset, limit)
      .then(({ data }) => ({ ...data, id }));
  }
);

export const fetchWorklogLocal = createActionThunk(
  'requests/fetch-worklog-local',
  ({ id, employeeId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .fetchWorklogList(workspaceId, id, employeeId)
      .then(({ data }) => data.results);
  }
);

export const createWorklog = createActionThunk(
  'requests/create-worklog',
  ({ id, value, description, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .createWorklog(workspaceId, id, { value, description })
      .then(({ data }) => ({ taskId: id, ...data }));
  }
);

export const updateWorklog = createActionThunk(
  'requests/update-worklog',
  ({ id, worklogId, value, parent, description, diff, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .updateWorklog(workspaceId, id, worklogId, { value, description })
      .then(({ data }) => ({ ...data, taskId: id, parent, diff }));
  }
);

export const deleteWorklog = createActionThunk(
  'requests/delete-worklog',
  ({ id, worklogId, value, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .deleteWorklog(workspaceId, id, worklogId)
      .then(() => ({ taskId: id, worklogId, value }));
  }
);

export const checkWorklogIsFull = createActionThunk(
  'requests/worklog-is-full',
  ({ taskId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .checkWorklogIsFull({ workspaceId, taskId })
      .then(({ data }) => data.isFull);
  }
);

export const updateStatus = createActionThunk(
  'requests/update-status',
  ({ id, status, reason, reasonFileList, dateEnd, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .updateStatus(workspaceId, id, status, reason, reasonFileList, dateEnd)
      .then(({ data }) => data);
  }
);

export const changeManager = createActionThunk(
  'requests/change-manager',
  ({ task, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.requests
      .changeManager(workspaceId, task.id, { employee: task.employee })
      .then(({ data }) => data);
  }
);

export const fetchSubtaskList = createActionThunk(
  'requests/fetch-subtask-list',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.fetchSubtaskList(workspaceId, id).then(({ data }) => ({
      ...data,
      parentId: id
    }));
  }
);

export const sendTasksInWork = createActionThunk(
  'requests/send-tasks-in-work',
  ({ getState, sprintId, tasks }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .sendTasksInWork(workspaceId, sprintId, tasks)
      .then(() => tasks);
  }
);

export const addTasksToSprint = createActionThunk(
  'requests/add-tasks-to-sprint',
  ({ getState, id, tasks }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.sprints
      .addTasksToSprint(workspaceId, id, tasks)
      .then(({ data }) => data);
  }
);

export const updateSubtasksDates = createActionThunk(
  'requests/update-subtasks-dates',
  ({ getState, id, dateStart, dateEnd }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .updateSubtasksDates(workspaceId, id)
      .then(() => ({ id, dateStart, dateEnd }));
  }
);

// AGREEMENT
export const agreementAction = createActionThunk(
  'requests/agreement-action',
  ({ id, action, rejectionReason, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .agreementAction({ workspaceId, id, action, rejectionReason })
      .then(({ data }) => data);
  }
);

export const fetchAgreementSteps = createActionThunk(
  'requests/fetch-agreement-steps',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchAgreementSteps(workspaceId, id)
      .then(({ data }) => ({ agreementSteps: data, id }));
  }
);

export const fetchTemplateAgreementStepsLocal = createActionThunk(
  'requests/fetch-template-agreement-steps',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchTemplateAgreementSteps(workspaceId, id)
      .then(({ data }) => data);
  }
);

export const setRequestViewed = createActionThunk(
  'requests/set-request-viewed',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.view(workspaceId, id).then(() => ({ id }));
  }
);

export const setRequestViewedApproving = createActionThunk(
  'requests/set-request-viewed-approving',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.view(workspaceId, id).then(() => ({ id }));
  }
);

export const fetchCoResponsibles = createActionThunk(
  'requests/fetch-co-responsibles',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests
      .fetchCoResponsibles(workspaceId, id)
      .then(({ data }) => ({ taskId: id, coResponsibles: data }));
  }
);

export const changeController = createActionThunk(
  'requests/change-controller',
  ({ id, employeeId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.changeController(workspaceId, id, employeeId);
  }
);

export const changeResponsible = createActionThunk(
  'requests/change-responsible',
  ({ id, employeeId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.changeResponsible(workspaceId, id, employeeId);
  }
);

export const convertToSubtask = createActionThunk(
  'requests/convert-to-subtask',
  ({ taskId, parentId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.requests.convertToSubtask({ workspaceId, taskId, parentId });
  }
);

export const fetchIssue = createActionThunk(
  'requests/fetch-issue',
  ({ issueUuid }) =>
    api.requests.fetchIssue({ issueUuid }).then(({ data }) => data)
);

export const rejectedIssue = createActionThunk(
  'requests/rejected-issue',
  ({ issueUuid, values }) =>
    api.requests
      .rejectedIssue({ issueUuid, data: values })
      .then(({ data }) => data)
);

export const acceptedIssue = createActionThunk(
  'requests/accepted-issue',
  ({ issueUuid }) =>
    api.requests.acceptedIssue({ issueUuid }).then(({ data }) => data)
);

export const selectRequest = createAction('requests/select', ({ id }) => id);
export const deselectRequest = createAction(
  'requests/deselect',
  ({ id }) => id
);
export const deselectAll = createAction('requests/deselect-all');
export const changeRequestViewType = createAction('requests/change-view-type');
export const setRequestData = createAction('requests/set-request-data');

// filters
export const clearFilter = createAction('requests/clear-filter');
export const setFilterSearch = createAction('requests/set-filter-search');
export const setFilterType = createAction('requests/set-filter-type');
export const setFilterStatus = createAction('requests/set-filter-status');
export const setFilterFavorite = createAction('requests/set-filter-favorite');
export const setFilterSprint = createAction('requests/set-filter-sprint');
export const setFilterAuthor = createAction('requests/set-filter-author');
export const setFilterResponsible = createAction(
  'requests/set-filter-responsible'
);
export const setFilterAsset = createAction('requests/set-filter-asset');
export const setFilterController = createAction(
  'requests/set-filter-controller'
);
export const setFilterCompletedDateRange = createAction(
  'requests/set-filter-completed-date-range'
);
export const setFilterCreatedDateRange = createAction(
  'requests/set-filter-created-date-range'
);
export const setFilterStartedDateRange = createAction(
  'requests/set-filter-started-date-range'
);
export const setFilterEndedDateRange = createAction(
  'requests/set-filter-ended-date-range'
);
export const setFilterSubordinates = createAction(
  'requests/set-filter-subordinates'
);
export const setFilterMyTasks = createAction('requests/set-filter-my-tasks');
export const setFilterIsAutoCreated = createAction(
  'requests/set-filter-is-auto-created'
);
export const setFilterExpired = createAction('requests/set-filter-expired');
export const setFilterProject = createAction('requests/set-filter-project');
export const setFilterOrderStatus = createAction(
  'requests/set-filter-order-status'
);
export const setFilterTasks = createAction('requests/set-filter-tasks');
export const setFilterRequests = createAction('requests/set-filter-requests');
export const setFilterContacts = createAction('requests/set-filter-contacts');
export const setFilterMembers = createAction('requests/set-filter-members');
export const setFilterSprintIsNull = createAction(
  'requests/set-filter-sprint-is-null'
);
export const setFilterTag = createAction('requests/set-filter-tag');
export const setFilterOrdering = createAction('requests/set-filter-ordering');
export const setFilterHideSubtasks = createAction(
  'requests/set-filter-hide-subtasks'
);

// backlog filters
export const setBacklogFilterSearch = createAction(
  'requests/set-backlog-filter-search'
);
export const setBacklogFilterSubordinates = createAction(
  'requests/set-backlog-filter-subordinates'
);
export const setBacklogFilterMembers = createAction(
  'requests/set-backlog-filter-members'
);
export const setBacklogFilterAuthors = createAction(
  'requests/set-backlog-filter-authors'
);
export const setBacklogFilterResponsibles = createAction(
  'requests/set-backlog-filter-responsibles'
);
export const setBacklogFilterSprint = createAction(
  'requests/set-backlog-filter-sprint'
);
export const setBacklogFilterHideSubtasks = createAction(
  'requests/set-backlog-filter-hide-subtasks'
);
export const setBacklogFilterSprintIsNull = createAction(
  'requests/set-backlog-filter-sprint-is-null'
);
export const setBacklogFilterProject = createAction(
  'requests/set-backlog-filter-project'
);
export const setBacklogFilterFavorite = createAction(
  'requests/set-backlog-filter-favorite'
);
export const setBacklogFilterTag = createAction(
  'requests/set-backlog-filter-tag'
);
export const setBacklogFilterRelations = createAction(
  'requests/set-backlog-filter-relations'
);
export const setBacklogFilterCreatedDateRange = createAction(
  'requests/set-backlog-filter-created-date-range'
);
export const clearBacklogFilter = createAction('requests/clear-backlog-filter');

export const setTasksIsAllLoading = createAction('requests/set-is-all-loading');

export const clearTasksEntries = createAction('requests/clear-entries');

export const reorderTasksEntries = createAction(
  'requests/reorder-tasks-entries'
);

export const resetReorderingTasksEntries = createAction(
  'requests/reset-reordering-tasks-entries'
);
