import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DATE_FORMAT, DATE_PICKER_FORMAT } from 'constants/index';

import Button from 'components/common/button';
import { FormEmployeeSelect, FormTextarea } from 'components/common/hook-form';
import FormDateRangeSelect from 'components/common/hook-form/select/date-range';

import { replaceEmployeeAction } from 'store/team/employees';
import { setNeedRefetchDepartments } from 'store/team/departments';
import { getUserEmployee } from 'store/workspace';

import { validateMaxLength } from 'utils/validators';

import styles from './replace-employee.module.scss';

const FormReplaceEmployee = ({ data, onClose }) => {
  const { employee } = data;
  const { t } = useTranslation('ReplaceAnEmployee');

  const methods = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const currentEmployee = useSelector(getUserEmployee);
  const allowSetYourself = (employee || {}).id !== currentEmployee.id;

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        replaceEmployeeAction({
          id: (employee || {}).id,
          replacerId: (values.replacer || {}).value,
          fromDate: values.dateRange.value[0],
          toDate: (values.dateRange || {}).value[1],
          ...values
        })
      );

      dispatch(setNeedRefetchDepartments(true));

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <FormDateRangeSelect
          name="dateRange"
          label={t('ReplacementPeriod')}
          isShowTimeSelect={false}
          format={DATE_FORMAT}
          allowSetBeforeCurrentDate={false}
          datePickerProps={{
            dateFormat: DATE_PICKER_FORMAT
          }}
          rules={{
            validate: dates => {
              if (!dates || !dates.value || !dates.value[0]) {
                return t('FieldFromRequired', { ns: 'Errors' });
              }

              return true;
            }
          }}
        />

        <FormEmployeeSelect
          name="replacer"
          label={t('WhoReplaceEmployee')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          params={{
            excludeSelf: true,
            excludeBench: false,
            excludeReplaced: true,
            exclude: (employee || {}).id
          }}
          departmentsParams={{
            excludeType: undefined
          }}
          allowSetYourself={allowSetYourself}
        />

        <FormTextarea
          label={t('ReasonForSubstitution')}
          name="reason"
          placeholder={t('YourComment')}
          autoSize={{
            minRows: 7,
            maxRows: 7
          }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            maxLength: validateMaxLength(512)
          }}
          style={{
            resize: 'none'
          }}
        />

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          width="expanded"
          className={styles.submitBtn}
          loading={isLoading}
        >
          {t('Confirm')}
        </Button>
      </form>
    </FormProvider>
  );
};

FormReplaceEmployee.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

FormReplaceEmployee.defaultProps = {
  data: {}
};

export default FormReplaceEmployee;
