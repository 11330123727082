import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormChanneleSelect,
  FormObjectSlaSelect
} from 'components/common/hook-form';

export const SlaObjectSelect = ({
  isObjectSlaForChannelsEnabled,
  contact,
  value,
  ...props
}) => {
  const { t } = useTranslation(['Common', 'Requests', 'ConnectWidget']);

  const commonProps = {
    contact: contact?.value,
    value: value?.item || !isObjectSlaForChannelsEnabled && value,
    defaultType: value?.type,
    ...props
  };

  if (isObjectSlaForChannelsEnabled) {
    return (
      <FormObjectSlaSelect
        {...commonProps}
        name="slaObject"
        label={`${t('SLAEntity', { ns: 'Requests' })} ${t('Optional', { ns: 'Common' })}`}
      />
    );
  }

  return (
    <FormChanneleSelect
      {...commonProps}
      isClearable
      name="slaObject"
      addEntityButtonData={null}
      params={{
        lite: undefined,
        contact: contact?.value,
        ordering: 'name'
      }}
      reloadAfterOpen
      label={`${t('Channel', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
      valueText={t('ChooseChannel', { ns: 'ConnectWidget' })}
    />
  );
};
