import React from 'react';

export const ShareAccessIcon = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M144.18 58.4444C143.995 58.1586 142.138 55.7444 138.238 50.8158C138.238 50.8158 135.452 47.2872 132.652 44.4158L132.195 43.9444C131.638 43.3872 131.08 42.8301 130.523 42.3729C129.966 41.9158 129.309 41.2586 128.752 40.7872C128.566 40.6872 128.48 40.5015 128.295 40.4158C127.452 39.6729 126.623 38.9872 125.695 38.2729C125.409 38.0015 125.038 37.8158 124.766 37.5301C123.609 36.5968 122.398 35.7333 121.138 34.9444C121.052 34.8586 120.952 34.8586 120.952 34.7586C120.952 34.6586 120.666 34.5729 120.48 34.4872C119.552 33.8301 118.538 33.1872 117.623 32.5301L117.523 32.4444C117.438 32.3444 117.338 32.3444 117.338 32.2586C117.338 32.1729 117.066 32.0729 116.88 31.9729C115.852 31.3301 114.738 30.6729 113.723 30.1158C108.78 27.3488 103.621 24.9887 98.2948 23.0586C91.4202 20.5941 84.2733 18.9681 77.0091 18.2158H76.6376C75.7131 18.1041 74.7832 18.0422 73.8519 18.0301H73.3805C67.9267 17.5916 62.4401 17.8074 57.0376 18.6729C55.7376 18.8586 54.4234 19.1444 53.0376 19.4158C51.8129 19.6625 50.6018 19.9725 49.4091 20.3444C48.2091 20.7158 46.9948 21.1872 45.8805 21.6444C30.7376 28.4301 28.2234 44.3158 25.7234 60.7586C25.2519 63.9158 24.7948 67.1729 24.2948 70.3301C23.3336 77.0151 21.4088 83.5256 18.5805 89.6586C14.5805 102.201 15.7234 113.259 21.6376 120.873C22.6056 121.646 23.4238 122.591 24.0519 123.659C29.7662 129.144 38.2662 132.301 49.3234 132.116C58.1519 132.016 88.0662 133.701 113.338 125.244C113.338 125.244 117.052 124.03 121.909 121.902C139.366 114.087 143.738 106.187 144.566 103.33C144.788 102.509 144.912 101.666 144.938 100.816C144.666 97.8444 141.88 96.0872 141.038 88.5586C139.438 73.8586 148.923 66.6158 144.18 58.4444Z"
      fill="#D7F1F2"
    />
    <path
      d="M122.281 84.3158C122.561 85.8005 122.544 87.326 122.231 88.8041C121.917 90.2822 121.314 91.6835 120.456 92.927C119.598 94.1705 118.502 95.2314 117.231 96.0485C115.96 96.8656 114.54 97.4226 113.052 97.6872L28.3091 113.144C26.8224 113.424 25.2949 113.406 23.8152 113.091C22.3355 112.776 20.9331 112.171 19.6892 111.31C18.4454 110.448 17.3849 109.349 16.5692 108.075C15.7536 106.801 15.199 105.377 14.9377 103.887L4.66625 47.6015C4.39407 46.1147 4.41767 44.5887 4.73572 43.111C5.05377 41.6333 5.66002 40.2327 6.51979 38.9895C7.37957 37.7463 8.476 36.6848 9.74638 35.8656C11.0168 35.0465 12.4362 34.4859 13.9234 34.2158L98.6377 18.7729C100.123 18.4929 101.65 18.5104 103.129 18.8244C104.608 19.1385 106.01 19.7429 107.253 20.6025C108.497 21.4622 109.558 22.5601 110.374 23.8326C111.19 25.1051 111.746 26.527 112.009 28.0158L122.281 84.3158Z"
      fill="#EFEDED"
    />
    <path
      d="M112.423 30.2301L5.06625 49.8015L4.66625 47.6015C4.39407 46.1147 4.41767 44.5887 4.73572 43.111C5.05377 41.6333 5.66002 40.2327 6.51979 38.9895C7.37957 37.7463 8.476 36.6848 9.74638 35.8656C11.0168 35.0465 12.4362 34.4859 13.9234 34.2158L98.6377 18.7729C100.123 18.4929 101.65 18.5104 103.129 18.8244C104.608 19.1385 106.01 19.7429 107.253 20.6025C108.497 21.4622 109.558 22.5601 110.374 23.8326C111.19 25.1051 111.746 26.527 112.009 28.0158L112.423 30.2301Z"
      fill="#16969C"
    />
    <path
      d="M17.9805 43.7729C19.7636 43.7729 21.2091 42.3275 21.2091 40.5444C21.2091 38.7613 19.7636 37.3158 17.9805 37.3158C16.1974 37.3158 14.752 38.7613 14.752 40.5444C14.752 42.3275 16.1974 43.7729 17.9805 43.7729Z"
      fill="white"
    />
    <path
      d="M30.1233 41.5586C31.9064 41.5586 33.3519 40.1132 33.3519 38.3301C33.3519 36.547 31.9064 35.1015 30.1233 35.1015C28.3403 35.1015 26.8948 36.547 26.8948 38.3301C26.8948 40.1132 28.3403 41.5586 30.1233 41.5586Z"
      fill="white"
    />
    <path
      d="M42.2806 39.3443C44.0637 39.3443 45.5091 37.8989 45.5091 36.1158C45.5091 34.3327 44.0637 32.8872 42.2806 32.8872C40.4975 32.8872 39.052 34.3327 39.052 36.1158C39.052 37.8989 40.4975 39.3443 42.2806 39.3443Z"
      fill="white"
    />
    <path
      d="M48.2411 48.212L18.5878 53.6224C16.2282 54.0529 14.6644 56.3146 15.0949 58.6742C15.5254 61.0337 17.7872 62.5975 20.1467 62.167L49.8001 56.7567C52.1596 56.3262 53.7234 54.0644 53.2929 51.7049C52.8624 49.3453 50.6006 47.7815 48.2411 48.212Z"
      fill="#F9716C"
    />
    <path
      d="M61.4833 69.5614L20.306 77.0743C19.3125 77.2555 18.654 78.2078 18.8353 79.2013L19.1712 81.0424C19.3525 82.0359 20.3048 82.6943 21.2983 82.513L62.4757 75.0001C63.4692 74.8189 64.1276 73.8665 63.9463 72.8731L63.6104 71.032C63.4292 70.0385 62.4768 69.3801 61.4833 69.5614Z"
      fill="#D9D9D9"
    />
    <path
      d="M59.9314 61.8626L18.9114 69.3969C17.9217 69.5787 17.2667 70.5284 17.4485 71.5181L17.7854 73.3521C17.9672 74.3418 18.9168 74.9967 19.9065 74.8149L60.9265 67.2806C61.9162 67.0989 62.5712 66.1492 62.3894 65.1595L62.0525 63.3255C61.8707 62.3358 60.9211 61.6809 59.9314 61.8626Z"
      fill="#D9D9D9"
    />
    <path
      d="M116.466 44.1872H80.6663C75.6799 44.1872 71.6377 48.2294 71.6377 53.2158V96.7872C71.6377 101.774 75.6799 105.816 80.6663 105.816H116.466C121.453 105.816 125.495 101.774 125.495 96.7872V53.2158C125.495 48.2294 121.453 44.1872 116.466 44.1872Z"
      fill="white"
    />
    <path
      d="M116.309 74.9444C116.32 78.456 115.289 81.892 113.347 84.8173C111.404 87.7426 108.637 90.0256 105.396 91.3773C102.155 92.729 98.5855 93.0885 95.14 92.4104C91.6944 91.7323 88.5276 90.0469 86.0405 87.5678C83.5534 85.0887 81.8579 81.9274 81.1686 78.4841C80.4794 75.0407 80.8274 71.4703 82.1686 68.2249C83.5098 64.9795 85.7839 62.205 88.7029 60.2529C91.622 58.3007 95.0546 57.2587 98.5663 57.2587C103.262 57.2586 107.766 59.1201 111.092 62.4352C114.418 65.7503 116.294 70.2486 116.309 74.9444Z"
      fill="#FDA402"
    />
    <path
      d="M105.123 70.3444C105.126 71.6419 104.744 72.911 104.025 73.9912C103.306 75.0714 102.283 75.9141 101.085 76.4125C99.8875 76.911 98.5686 77.0429 97.2957 76.7915C96.0228 76.5401 94.8531 75.9168 93.9346 75.0003C93.0162 74.0838 92.3902 72.9155 92.1361 71.6431C91.8819 70.3708 92.0109 69.0516 92.5068 67.8527C93.0027 66.6537 93.8431 65.6287 94.9217 64.9076C96.0004 64.1864 97.2687 63.8015 98.5662 63.8015C100.303 63.8015 101.968 64.4904 103.198 65.717C104.427 66.9436 105.12 68.6078 105.123 70.3444Z"
      fill="white"
    />
    <path
      d="M105.209 81.8586H91.9377C90.9235 81.8624 89.9518 82.267 89.2347 82.9842C88.5175 83.7013 88.1129 84.673 88.1091 85.6872V89.3158C91.1436 91.5429 94.8094 92.7438 98.5734 92.7438C102.337 92.7438 106.003 91.5429 109.038 89.3158V85.6872C109.034 84.673 108.629 83.7013 107.912 82.9842C107.195 82.267 106.223 81.8624 105.209 81.8586Z"
      fill="#FFE181"
    />
    <path
      d="M120.266 110.316C125.829 110.316 130.338 105.807 130.338 100.244C130.338 94.682 125.829 90.1729 120.266 90.1729C114.704 90.1729 110.195 94.682 110.195 100.244C110.195 105.807 114.704 110.316 120.266 110.316Z"
      fill="url(#paint0_linear_105454_326325)"
    />
    <path
      d="M119.209 105.473L113.823 100.087C113.664 99.9281 113.575 99.7123 113.575 99.4872C113.575 99.2622 113.664 99.0464 113.823 98.8872C113.983 98.7281 114.198 98.6387 114.423 98.6387C114.649 98.6387 114.864 98.7281 115.023 98.8872L119.209 103.073L125.495 96.7729C125.574 96.6941 125.667 96.6317 125.77 96.589C125.873 96.5464 125.983 96.5244 126.095 96.5244C126.206 96.5244 126.317 96.5464 126.42 96.589C126.523 96.6317 126.616 96.6941 126.695 96.7729C126.774 96.8517 126.836 96.9453 126.879 97.0482C126.921 97.1512 126.943 97.2615 126.943 97.3729C126.943 97.4844 126.921 97.5947 126.879 97.6977C126.836 97.8006 126.774 97.8941 126.695 97.9729L119.209 105.473Z"
      fill="white"
    />
    <path
      d="M55.7663 121.301C58.3252 120.765 60.6901 119.542 62.6075 117.765C64.5249 115.987 65.9227 113.721 66.6513 111.21C67.3798 108.699 67.4116 106.037 66.7432 103.51C66.0749 100.982 64.7316 98.6834 62.8572 96.8605C60.9828 95.0377 58.6478 93.759 56.1024 93.1613C53.557 92.5637 50.8969 92.6697 48.4071 93.468C45.9173 94.2662 43.6915 95.7267 41.9681 97.6929C40.2447 99.6591 39.0885 102.057 38.6234 104.63L15.652 109.387L8.10913 119.087L19.9806 116.63L20.4948 119.13L25.9091 118.001L25.3948 115.501L28.8234 114.801L30.0663 120.659L35.5091 119.53L34.3091 113.73L39.4948 112.659C40.7671 115.776 43.0922 118.347 46.0654 119.927C49.0385 121.506 52.4714 121.992 55.7663 121.301ZM46.0948 108.587C45.7965 107.202 45.917 105.759 46.441 104.442C46.965 103.126 47.8687 101.995 49.0374 101.193C50.206 100.392 51.5866 99.9557 53.0037 99.941C54.4207 99.9263 55.8101 100.333 56.995 101.111C58.18 101.888 59.107 103 59.6582 104.306C60.2093 105.611 60.3596 107.051 60.09 108.442C59.8205 109.834 59.1431 111.113 58.1443 112.118C57.1454 113.124 55.8701 113.809 54.4806 114.087C52.6405 114.456 50.7294 114.085 49.1602 113.056C47.591 112.027 46.4899 110.422 46.0948 108.587Z"
      fill="#FEC458"
    />
    <defs>
      <linearGradient
        id="paint0_linear_105454_326325"
        x1="110.195"
        y1="100.244"
        x2="130.338"
        y2="100.244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
    </defs>
  </svg>
);
