import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CHATS, CONTACTS, ORDERS, TYPE_CONTACT } from 'constants/index';

import { getWorkspaceId } from 'store/workspace';
import { getRouterUrl } from 'store/router';
import { setChannelChatNewMessagesPush } from 'store/notifications';
import { setEntityChatHasNewMessages } from 'store/operator';

export const useAlertExternalChatNewMessage = ({ dispatch }) => {
  const routerUrl = useSelector(getRouterUrl);
  const workspaceId = useSelector(getWorkspaceId);

  const { t } = useTranslation('NotificationsBell');

  const handleAlertExternalChatNewMessage = data => {
    if (
      (routerUrl.isTabVisible &&
        ((routerUrl.view === CONTACTS &&
          routerUrl.subMenu === `${CHATS}/${data.contactId}`) ||
          (routerUrl.subMenu === ORDERS &&
            routerUrl.query.contractorId &&
            +routerUrl.query.contractorId === data.contactId))) ||
      !data.showPush
    ) {
      return;
    }

    dispatch(
      setChannelChatNewMessagesPush({
        ...data,
        text: t('NewMessageFromContact')
      })
    );
    dispatch(
      setEntityChatHasNewMessages({
        entityType: TYPE_CONTACT,
        value: workspaceId === data.workspaceId
      })
    );
  };

  return handleAlertExternalChatNewMessage;
};
