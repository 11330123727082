import React, { useState } from 'react';
import Draggable from 'react-draggable';

import { AgentSupportIconWrap } from '../agent-support-icon-wrap';

import styles from './AgentSupportCollapseElement.module.scss';

export const AgentSupportCollapseElement = ({ toggleCollapse }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragStartY, setDragStartY] = useState(0);

  const handleStart = (e, ui) => {
    setDragStartY(ui.y);
  };

  const handleDrag = (e, ui) => {
    const { y } = position;

    setPosition({ x: 0, y: y + ui.deltaY });
  };

  const handleStop = (e, ui) => {
    const deltaY = ui.y - dragStartY;

    if (Math.abs(deltaY) < 2) {
      toggleCollapse();
    }
  };

  return (
    <Draggable
      axis="y"
      position={position}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
      bounds="body"
    >
      <div className={styles.root}>
        <AgentSupportIconWrap size="middle" />
      </div>
    </Draggable>
  );
};
