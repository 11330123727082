import { RRule } from 'rrule';

import {
  TaskIcon,
  CallIcon,
  MeetIcon,
  MessageIcon,
  WatchIcon,
  AgreementIcon,
  RequestTypeIcon,
  TaskLimitedAccessIcon
} from 'components/common/icons/task-types';

import themeVariables from 'theme';
import alphabeticallySort from 'utils/alphabetically-sort';

export const TYPE_KIND = 'kind';
export const TYPE_TASK = 'task';
export const TYPE_TASK_TEMPLATE = 'task_template';
// это не задача согласования, а обычная задача, в которй был добавлен согласующий
// которая будет иметь сразу статус "На рассмотрении"
export const TYPE_AGREEMENT_TASK = 'agreement_task';
export const TYPE_IDEA = 'idea';
export const TYPE_CALL = 'call';
export const TYPE_MEETING = 'meeting';
export const TYPE_LETTER = 'letter';
export const TYPE_ACQUAINTANCE = 'acquaintance';
export const TYPE_LETTER_KIND = 'letterKind';
export const TYPE_AGREEMENT = 'agreement';
export const TYPE_REQUEST = 'request';

export const LETTER_KIND_EMAIL = 'email';
export const LETTER_KIND_LETTER = 'letter';
export const LETTER_KIND_SMS = 'sms';

export const LETTER_KINDS = [
  {
    key: LETTER_KIND_EMAIL,
    value: LETTER_KIND_EMAIL,
    label: 'WhatToSendEmail',
    ns: 'AddTask'
  },
  {
    key: LETTER_KIND_SMS,
    value: LETTER_KIND_SMS,
    label: 'WhatToSendSMS',
    ns: 'AddTask'
  },
  {
    key: LETTER_KIND_LETTER,
    value: LETTER_KIND_LETTER,
    label: 'WhatToSendLetter',
    ns: 'AddTask'
  }
];

export const COMPLETE_LINK = 'link';
export const COMPLETE_MEDIA = 'media';
export const COMPLETE_COMMENT = 'comment';
export const COMPLETE_DOCUMENT = 'document';
// export const COMPLETE_CLOSE_ORDER = 'close-order';

export const COMPLETE_KINDS = [
  {
    key: COMPLETE_LINK,
    value: COMPLETE_LINK,
    label: 'TaskResultLink',
    ns: 'Task'
  },
  {
    key: COMPLETE_MEDIA,
    value: COMPLETE_MEDIA,
    label: 'TaskResultMediafile',
    ns: 'Task'
  },
  {
    key: COMPLETE_COMMENT,
    value: COMPLETE_COMMENT,
    label: 'TaskResultComment',
    ns: 'Task'
  },
  {
    key: COMPLETE_DOCUMENT,
    value: COMPLETE_DOCUMENT,
    label: 'TaskResultDocument',
    ns: 'Task'
  }
  // {
  //   key: COMPLETE_CLOSE_ORDER,
  //   value: COMPLETE_CLOSE_ORDER,
  // }
].sort((a, b) => alphabeticallySort(a, b, 'label'));

export const STATUS_PENDING = 'pending';
export const STATUS_FOR_EXECUTION = 'todo';
export const STATUS_IN_WORK = 'progress';
export const STATUS_BACKLOG = 'backlog';
export const STATUS_DONE = 'completed';
export const STATUS_CHECK = 'review';
export const STATUS_REJECTED = 'rejected';
export const STATUS_DELETED = 'deleted';
export const STATUS_CANCELLED = 'cancelled';

export const SCHEDULER = 'isScheduler';
export const SCHEDULER_ON = 'repeating-on';
export const SCHEDULER_OFF = 'repeating-off';
export const SCHEDULER_NONE = 'repeating-none';
export const SCHEDULED_TASK_DAY = 'daily';
export const SCHEDULED_TASK_WEEK = 'weekly';
export const SCHEDULED_TASK_MONTH = 'monthly';
export const SCHEDULED_TASK_YEAR = 'yearly';

const MONDAY_SHORT = 'mo';
const TUESDAY_SHORT = 'tu';
const WEDNESDAY_SHORT = 'we';
const THURSDAY_SHORT = 'th';
const FRIDAY_SHORT = 'fr';
const SATURDAY_SHORT = 'sa';
const SUNDAY_SHORT = 'su';

export const DAYS_OF_WEEK = [
  {
    value: MONDAY_SHORT,
    label: 'MondayBtn',
    ns: 'AddTask'
  },
  {
    value: TUESDAY_SHORT,
    label: 'TuesdayBtn',
    ns: 'AddTask'
  },
  {
    value: WEDNESDAY_SHORT,
    label: 'WednesdayBtn',
    ns: 'AddTask'
  },
  {
    value: THURSDAY_SHORT,
    label: 'ThursdayBtn',
    ns: 'AddTask'
  },
  {
    value: FRIDAY_SHORT,
    label: 'FridayBtn',
    ns: 'AddTask'
  },
  {
    value: SATURDAY_SHORT,
    label: 'SaturdayBtn',
    ns: 'AddTask'
  },
  {
    value: SUNDAY_SHORT,
    label: 'SundayBtn',
    ns: 'AddTask'
  }
];

export const SCHEDULED_TASK_TYPES = {
  [SCHEDULED_TASK_DAY]: {
    value: SCHEDULED_TASK_DAY,
    label: 'Day',
    ns: 'AddTask',
    options: null
  },
  [SCHEDULED_TASK_WEEK]: {
    value: SCHEDULED_TASK_WEEK,
    label: 'Week',
    ns: 'AddTask',
    options: DAYS_OF_WEEK
  },
  [SCHEDULED_TASK_MONTH]: {
    value: SCHEDULED_TASK_MONTH,
    label: 'Month',
    ns: 'AddTask',
    options: null
  },
  [SCHEDULED_TASK_YEAR]: {
    value: SCHEDULED_TASK_YEAR,
    label: 'Year',
    ns: 'AddTask',
    options: null
  }
};

export const RRULE_SCHEDULER_TYPES = {
  [SCHEDULED_TASK_DAY]: RRule.DAILY,
  [SCHEDULED_TASK_WEEK]: RRule.WEEKLY,
  [SCHEDULED_TASK_MONTH]: RRule.MONTHLY,
  [SCHEDULED_TASK_YEAR]: RRule.YEARLY,

  [MONDAY_SHORT]: RRule.MO,
  [TUESDAY_SHORT]: RRule.TU,
  [WEDNESDAY_SHORT]: RRule.WE,
  [THURSDAY_SHORT]: RRule.TH,
  [FRIDAY_SHORT]: RRule.FR,
  [SATURDAY_SHORT]: RRule.SA,
  [SUNDAY_SHORT]: RRule.SU
};

export const TASK_TYPES = [
  {
    id: TYPE_TASK,
    value: TYPE_TASK,
    label: 'TaskHeading',
    ns: 'AddTaskType',
    icon: TaskIcon,
    color: themeVariables.color['brand-90'],
    textColor: themeVariables.color['brand-20'],
    iconColor: themeVariables.color.brand,
    description: 'TaskDesc',
    allow: true,
    accessIcon: TaskLimitedAccessIcon,
    accessLabel: 'TaskRestrictedAccess',
    accessNs: 'Backend'
  },
  {
    id: TYPE_AGREEMENT,
    value: TYPE_AGREEMENT,
    label: 'AgreementHeading',
    ns: 'AddTaskType',
    icon: AgreementIcon,
    color: themeVariables.color['indigo-light'],
    textColor: themeVariables.color['indigo-dark'],
    iconColor: themeVariables.color.indigo,
    description: 'AgreementDesc',
    allow: true
  },
  {
    id: TYPE_MEETING,
    value: TYPE_MEETING,
    label: 'MeetingHeading',
    ns: 'AddTaskType',
    icon: MeetIcon,
    color: themeVariables.color['orange-light'],
    textColor: themeVariables.color['orange-dark'],
    iconColor: themeVariables.color.orange,
    description: 'MeetingDesc',
    allow: true
  },
  {
    id: TYPE_ACQUAINTANCE,
    value: TYPE_ACQUAINTANCE,
    label: 'IntroductionHeading',
    ns: 'AddTaskType',
    icon: WatchIcon,
    color: themeVariables.color['magenta-light'],
    textColor: themeVariables.color['magenta-dark'],
    iconColor: themeVariables.color.magenta,
    description: 'IntroductionDesc',
    allow: true
  },
  {
    id: TYPE_CALL,
    value: TYPE_CALL,
    label: 'CallHeading',
    ns: 'AddTaskType',
    icon: CallIcon,
    color: themeVariables.color['yellow-30'],
    textColor: themeVariables.color['yellow-10'],
    iconColor: themeVariables.color.yellow,
    description: 'CallDesc',
    allow: false
  },
  {
    id: TYPE_LETTER,
    value: TYPE_LETTER,
    label: 'EmailHeading',
    ns: 'AddTaskType',
    icon: MessageIcon,
    color: themeVariables.color['danube-20'],
    textColor: themeVariables.color['danube-10'],
    iconColor: themeVariables.color.danube,
    description: 'EmailDesc',
    allow: false
  },
  {
    id: TYPE_REQUEST,
    value: TYPE_REQUEST,
    label: 'RequestType',
    ns: 'Requests',
    icon: RequestTypeIcon,
    color: themeVariables.color['yellow-30'],
    textColor: themeVariables.color['yellow-10'],
    iconColor: themeVariables.color.yellow,
    description: 'RequestType',
    allow: false
  }
];

export const TASK_STATUS = new Map([
  [
    STATUS_PENDING,
    {
      label: 'UnderConsiderationTaskStatus',
      ns: 'Task',
      color: themeVariables.color['azure-dark'],
      colorLight: themeVariables.color['azure-light'],
      borderColor: themeVariables.color.azure,
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_FOR_EXECUTION,
    {
      label: 'ToDoTaskStatus',
      ns: 'Task',
      color: themeVariables.color['danube-10'],
      colorLight: themeVariables.color['danube-20'],
      borderColor: themeVariables.color.danube,
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_IN_WORK,
    {
      label: 'InProgressTaskStatus',
      ns: 'Task',
      color: themeVariables.color['yellow-10'],
      colorLight: themeVariables.color['yellow-30'],
      borderColor: themeVariables.color.yellow,
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_BACKLOG,
    {
      label: 'BacklogTaskStatus',
      ns: 'Statuses',
      color: themeVariables.color['magenta-dark'],
      colorLight: themeVariables.color['magenta-light'],
      borderColor: themeVariables.color.magenta,
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_CHECK,
    {
      label: 'CheckTaskStatus',
      ns: 'Task',
      color: themeVariables.color['indigo-dark'],
      borderColor: themeVariables.color.indigo,
      colorLight: themeVariables.color['indigo-light'],
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_DONE,
    {
      label: 'DoneTaskStatus',
      ns: 'Task',
      color: themeVariables.color['green-10'],
      borderColor: themeVariables.color.green,
      colorLight: themeVariables.color['green-30'],
      backgroundColor: themeVariables.color.white
    }
  ],
  [
    STATUS_CANCELLED,
    {
      label: 'CancelledTaskStatus',
      ns: 'Task',
      color: themeVariables.color['red-35'],
      borderColor: themeVariables.color.red,
      colorLight: themeVariables.color['red-90'],
      backgroundColor: themeVariables.color.white
    }
  ]
]);

const TO_WORK_ACTION = {
  label: 'BackTaskToWorkBtn',
  ns: 'Task',
  status: STATUS_FOR_EXECUTION,
  mood: 'negative'
};
export const GET_IN_WORK_ACTION = {
  label: 'BringTaskToWorkBtn',
  ns: 'Task',
  status: STATUS_IN_WORK,
  mood: 'positive'
};
export const DONE_ACTION = {
  label: 'DoneTaskBtn',
  ns: 'Task',
  status: STATUS_DONE,
  mood: 'positive'
};

export const TASK_STATUSES = [
  {
    id: STATUS_PENDING,
    label: 'UnderConsiderationTaskStatus',
    ns: 'Task',
    color: themeVariables.color.azure,
    backgroundColor: themeVariables.color.white,
    actions: [
      {
        label: 'ApproveTaskBtn',
        ns: 'Task',
        status: STATUS_FOR_EXECUTION,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_FOR_EXECUTION,
    label: 'ToDoTaskStatus',
    ns: 'Task',
    color: themeVariables.color['brand-40'],
    backgroundColor: themeVariables.color.white,
    actions: [GET_IN_WORK_ACTION],
    actionsMeeting: [
      {
        label: 'AcceptMeetBtn',
        ns: 'Task',
        status: STATUS_IN_WORK,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_IN_WORK,
    label: 'InProgressTaskStatus',
    ns: 'Task',
    color: themeVariables.color.yellow,
    backgroundColor: themeVariables.color.white,
    actions: [DONE_ACTION],
    actionsMeeting: [DONE_ACTION],
    actionsAcquaintence: [
      {
        label: 'AquaintedBtn',
        ns: 'Task',
        status: STATUS_DONE,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_BACKLOG,
    label: 'BacklogTaskStatus',
    ns: 'Statuses',
    color: themeVariables.color.magenta,
    backgroundColor: themeVariables.color.white,
    actions: []
  },
  {
    id: STATUS_CHECK,
    label: 'CheckTaskStatus',
    ns: 'Task',
    color: themeVariables.color['black-55'],
    backgroundColor: themeVariables.color.white,
    actions: [
      {
        label: 'CloseTaskBtn',
        ns: 'Task',
        status: STATUS_DONE,
        mood: 'positive'
      },
      TO_WORK_ACTION
    ]
  },
  {
    id: STATUS_DONE,
    label: 'DoneTaskStatus',
    ns: 'Task',
    color: themeVariables.color.danube,
    backgroundColor: themeVariables.color.white,
    actions: [TO_WORK_ACTION],
    actionsMeeting: []
  },
  {
    id: STATUS_CANCELLED,
    label: 'CancelledTaskStatus',
    ns: 'Statuses',
    color: themeVariables.color['red-70'],
    backgroundColor: themeVariables.color.white,
    actions: [TO_WORK_ACTION]
  }
];

export const ACTIVITY_TYPES = [
  {
    id: TYPE_REQUEST,
    label: 'RequestsTitle',
    ns: 'Requests'
  },
  {
    id: TYPE_TASK,
    label: 'Tasks',
    ns: 'Common'
  }
];

export const STATUSES_EXCLUDE_BACKLOG = [
  STATUS_PENDING,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK,
  STATUS_CHECK,
  STATUS_DONE,
  STATUS_CANCELLED
];

export const STATUSES_ACTIVE = [
  { value: STATUS_FOR_EXECUTION, label: 'ToDoTaskStatus', ns: 'Task' },
  { value: STATUS_IN_WORK, label: 'InProgressTaskStatus', ns: 'Task' },
  {
    value: STATUS_CHECK,
    label: 'CheckTaskStatus',
    ns: 'Task'
  },
  {
    value: STATUS_PENDING,
    label: 'UnderConsiderationTaskStatus',
    ns: 'Task'
  }
];

export const ONLY_THIS = 'only-this';
export const THIS_AND_NEXT = 'this-and-next';

export const CHANGING_SCHEDULER_OPTIONS = [
  {
    value: ONLY_THIS,
    label: 'OnlyThisTaskRadio',
    ns: 'ChangeRepetitiveTask'
  },
  {
    value: THIS_AND_NEXT,
    label: 'ThisAndFollowingRadio',
    ns: 'ChangeRepetitiveTask'
  }
];

export const ACCEPT_SCHEDULER_MEETING_OPTIONS = [
  {
    value: ONLY_THIS,
    label: 'OnlyThisMeetRadio',
    ns: 'ConfirmMeetParticipation'
  },
  {
    value: THIS_AND_NEXT,
    label: 'ThisAndFollowingRadio',
    ns: 'ConfirmMeetParticipation'
  }
];

export const TASK_VIEW_TYPE = 'task-view-type';
export const TASK_VIEW_TYPE_LIST = 'list';
export const TASK_VIEW_TYPE_BOARD = 'board';
export const TASK_VIEW_TYPE_GANTT = 'gantt';

export const TASK_ORDERING_DUE_DATE = 'due_date';
export const TASK_ORDERING_CREATED_AT = 'created_at';
export const TASK_ORDERING_START_AT = 'start_at';
export const TASK_ORDERING_COMPLETED_AT = 'completed_at';
export const TASK_ORDERING_DATE_END = 'date_end';

export const TASK_ORDERING_ACTIONS = [
  {
    title: 'SmartSorting',
    ns: 'Tasks',
    allow: true,
    key: TASK_ORDERING_DUE_DATE
  },
  {
    title: 'CreationDateSorting',
    ns: 'Tasks',
    allow: true,
    key: TASK_ORDERING_CREATED_AT
  },
  {
    title: 'StartDateSorting',
    ns: 'Tasks',
    allow: true,
    key: TASK_ORDERING_START_AT
  },
  {
    title: 'CompletedDateSorting',
    ns: 'Tasks',
    allow: true,
    key: TASK_ORDERING_COMPLETED_AT
  },
  {
    title: 'DueDateSorting',
    ns: 'Tasks',
    allow: true,
    key: TASK_ORDERING_DATE_END
  }
];

export const AGREEMENT_TASK_APPROVED = 'approved';
export const AGREEMENT_TASK_REJECTED = 'rejected';
export const AGREEMENT_TASK_PROGRESS = 'progress';

export const AGREEMENT_TASK_ACTIONS = [
  {
    label: 'ApproveTaskBtn',
    ns: 'Task',
    value: AGREEMENT_TASK_APPROVED,
    mood: 'positive'
  },
  {
    label: 'RejectTaskBtn',
    ns: 'Task',
    value: AGREEMENT_TASK_REJECTED,
    mood: 'negative'
  },
  {
    label: 'GoBackApprovalBtn',
    ns: 'Task',
    value: AGREEMENT_TASK_PROGRESS,
    mood: 'negative'
  }
];

export const ISSUE_STATUSES = {
  [STATUS_PENDING]: 'UnderConsiderationTaskStatus',
  [STATUS_IN_WORK]: 'InProgressTaskStatus',
  [STATUS_DONE]: 'DoneTaskStatus',
  [STATUS_REJECTED]: 'DeclinedTaskStatus'
};

export const SLA_REASON_MODAL_PROPS = {
  title: 'CloseSLATaskHeading',
  description: 'CloseSLATaskDesc',
  submitButtonText: 'CloseTaskBtn',
  ns: 'CloseSLATask'
};

export const SLA_REASON_MODAL_EDITOR_PROPS = {
  labelEditor: 'CommentForClient',
  placeholderEditor: 'Placeholder',
  showItemsEditor: {
    upload: false,
    mention: false,
    emoji: true,
    topToolbar: true
  },
  disallowRulesEditor: true
};

export const TASK_FIELD_TITLE = 'title';
export const TASK_FIELD_CREATED_AT = 'createdAt';
export const TASK_FIELD_DATE_START = 'dateStart';
export const TASK_FIELD_DATE_END = 'dateEnd';
export const TASK_FIELD_COMPLETED_AT = 'completedAt';
export const TASK_FIELD_PROJECT = 'project';
export const TASK_FIELD_SPRINT = 'sprint';
export const TASK_FIELD_FULL_LOCATION = 'fullLocation';
export const TASK_FIELD_LOCATION = 'location';
export const TASK_FIELD_LOCATION_EXACT = 'locationExact';
export const TASK_FIELD_COMPLETE_REQUIRE = 'completeRequire';
export const TASK_FIELD_DESCRIPTION = 'description';
export const TASK_FIELD_FILE_LIST = 'fileList';

export const FILE_GENERATION_PROCESS_ALERTS = {
  [TYPE_AGREEMENT]: 'SheetAttachmentAgreementAlert',
  [TYPE_ACQUAINTANCE]: 'SheetAttachmentProcessAlert'
};

export const ACCESS_TYPE_PUBLIC = 'public';
export const ACCESS_TYPE_PRIVATE = 'private';
export const ACCESS_TYPE_RESTRICTED = 'restricted';
