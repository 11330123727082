import moment from 'moment';
import { useForm } from 'react-hook-form';

import { DATE_TIME_FORMAT_FM } from 'constants';

import { convertMessageToString } from 'components/common/comments/converters';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import getTimeByEstimate from 'utils/get-time-by-estimate';
import getMembers from 'utils/get-members';

const mapValueForNewFields = value => ({
  value: value.id,
  label: value
});

const useFormDefaultValues = defaultValues => {
  const getInitialDateStartValue = () =>
    defaultValues?.dateStart
      ? moment(defaultValues?.dateStart, DATE_TIME_FORMAT_FM).toDate().getTime()
      : new Date(moment().toDate().getTime());

  const description =
    typeof defaultValues?.description === 'string'
      ? defaultValues?.description
      : (defaultValues?.description || []).filter(el => !el.link);

  const apartValues =
    defaultValues && getTimeByEstimate(defaultValues?.estimation);

  const getInitialSlaObject = () => {
    // for chat and regular creation
    if (defaultValues?.slaObjectType) {
      return {
        type: defaultValues.slaObjectType,
        item: defaultValues.slaObject
          ? {
              value: defaultValues.slaObject.id,
              label: defaultValues.slaObject
            }
          : null
      };
    }

    // for copy
    if (defaultValues?.taskInfo?.slaObjectType) {
      return {
        type: defaultValues.taskInfo.slaObjectType,
        item: defaultValues.taskInfo.slaObject
          ? {
              value: defaultValues.taskInfo.slaObject.id,
              label: defaultValues.taskInfo.slaObject
            }
          : null
      };
    }

    return null;
  };

  const getInitialValues = () => ({
    ...defaultValues,
    title: defaultValues?.title || '',
    responsible: defaultValues?.responsible
      ? mapValueForNewFields(defaultValues.responsible[0])
      : null,
    controller: defaultValues?.controller
      ? mapValueForNewFields(defaultValues.controller)
      : null,
    dateStart: getInitialDateStartValue(),
    dateEnd: moment(defaultValues.dateEnd).isValid()
      ? moment(defaultValues.dateEnd).toDate() ||
        (defaultValues.isCopy &&
          defaultValues.taskInfo.slaObject &&
          defaultValues?.taskInfo?.executorSla &&
          moment(defaultValues.dateEnd, DATE_TIME_FORMAT_FM).toDate())
      : defaultValues.maxDateEnd && moment(defaultValues.maxDateEnd).toDate(),

    tags: defaultValues?.tags
      ? defaultValues.tags.map(mapValueForNewFields)
      : [],
    relations: defaultValues?.relations || [],
    description: {
      description: convertMessageToString(description),
      fileList: (defaultValues?.fileList || []).map(addValidityDateState)
    },
    project: defaultValues?.project
      ? {
          value: defaultValues.project.id,
          label: defaultValues.project
        }
      : undefined,
    sprint: defaultValues?.sprint
      ? {
          value: defaultValues.sprint.id,
          label: defaultValues.sprint
        }
      : undefined,
    members: defaultValues ? getMembers(defaultValues) : [],
    hours: apartValues?.hours ? apartValues.hours.toString() : null,
    minutes: apartValues?.minutes ? apartValues.minutes.toString() : null,

    slaObject: getInitialSlaObject(),
    slaObjectType:
      defaultValues?.slaObjectType ||
      defaultValues?.taskInfo?.slaObjectType ||
      null,
    contact: defaultValues?.taskInfo?.contact
      ? mapValueForNewFields(defaultValues.taskInfo.contact)
      : defaultValues?.contact
  });

  const methods = useForm({
    defaultValues: getInitialValues()
  });

  return methods;
};

export default useFormDefaultValues;
