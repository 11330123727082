import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInputNumber, FormSwitch } from 'components/common/hook-form';
import Typography from 'components/common/typography';

import styles from './FeedbackSettings.module.scss';

export const FeedbackSettings = ({ isOnlyView = false }) => {
  const { t } = useTranslation(['ConnectWidget', 'Errors']);

  const { control } = useFormContext();

  const isActiveWatch = useWatch({
    name: 'feedbackConfig.isActive',
    control
  });

  const hoursWatch = useWatch({
    name: 'feedbackConfig.hours',
    control
  });

  const minutesWatch = useWatch({
    name: 'feedbackConfig.minutes',
    control
  });

  const hoursAndMinutesRules = {
    required:
      !hoursWatch && !minutesWatch && t('RequiredField', { ns: 'Errors' })
  };

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 16 }}>
        {t('FeedbackHeading')}
      </Typography.Title>

      <FormSwitch
        name="feedbackConfig.isActive"
        label={t('SetFeedbackToggle')}
        disabled={isOnlyView}
      />

      {isActiveWatch && (
        <>
          <Typography.Title level={4} style={{ marginBottom: 4 }}>
            {t('FeedbackTimerTitle')}
          </Typography.Title>

          <Typography.Text
            size="small"
            style={{ display: 'block', marginBottom: 16 }}
          >
            {t('FeedbackTimerDescription')}
          </Typography.Text>

          <div className={styles.inputWrapper}>
            <FormInputNumber
              name="feedbackConfig.hours"
              label={t('Hours')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={3}
              rules={hoursAndMinutesRules}
              disabled={isOnlyView}
            />

            <FormInputNumber
              name="feedbackConfig.minutes"
              label={t('Minutes')}
              placeholder="0"
              itemProps={{
                style: { width: '100%', marginBottom: 0 }
              }}
              maxLength={3}
              rules={hoursAndMinutesRules}
              disabled={isOnlyView}
            />
          </div>
        </>
      )}
    </>
  );
};
