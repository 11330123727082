import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CREATE_COMPANY, MODAL } from 'constants/index';

import {
  getUserEmployee,
  getIsIndividual,
  getWorkspaceId
} from 'store/workspace';

import useQueryParam from 'hooks/common/use-query-param';

export const useVisibleWriteProfile = () => {
  const workspaceId = useSelector(getWorkspaceId);
  const isIndividualWorkspace = useSelector(getIsIndividual);
  const userEmployee = useSelector(getUserEmployee, shallowEqual);

  const modal = useQueryParam(MODAL);
  const isCreateCompanyModal = modal === CREATE_COMPANY;
  const [visible, setVisible] = useState(false);

  const hasPhone = (userEmployee.phone || '').length > 0;
  const hasEmail = (userEmployee.email || '').length > 0;

  useEffect(() => {
    const isVisibleProfile =
      !isIndividualWorkspace &&
      (!hasPhone || !hasEmail) &&
      !isCreateCompanyModal;

    setVisible(isVisibleProfile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, userEmployee, isCreateCompanyModal]);

  return visible;
};

export default useVisibleWriteProfile;
