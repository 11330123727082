import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

export const CopyLink = ({
  children,
  link,
  onCopy,
  label,
  className,
  style,
  disabled,
  tooltipClassName,
  iconSize,
  iconColor
}) => {
  const [isCkicked, setIsClicked] = useState(false);

  const { t } = useTranslation('Common');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsClicked(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isCkicked]);

  const onClickCopy = () => {
    if (!isCkicked) {
      setIsClicked(true);
      navigator.clipboard.writeText(link);
      onCopy();
    }
  };

  const renderContent = () =>
    isCkicked ? (
      <Icon type="check" color="green" size={iconSize || 16} />
    ) : (
      <Icon type="copy" color={iconColor} size={iconSize || 16} />
    );

  return (
    <Tooltip
      title={!children && (isCkicked ? t('Copied') : label)}
      overlayClassName={tooltipClassName}
    >
      <Button
        onClick={onClickCopy}
        size="small"
        type="link"
        shape="circle"
        disabled={disabled}
        className={className}
        style={style}
      >
        {children || renderContent()}
      </Button>
    </Tooltip>
  );
};

CopyLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  onCopy: PropTypes.func,
  tooltipClassName: PropTypes.string
};

CopyLink.defaultProps = {
  link: '',
  label: <Translation ns="Common">{t => t('Copy')}</Translation>,
  className: undefined,
  style: {},
  disabled: false,
  onCopy: () => {},
  tooltipClassName: undefined
};

export default CopyLink;
