import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Tooltip from 'components/common/tooltip';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import { useExpandHistoryActionsContext } from 'providers/expand-history-actions-provider';

import styles from './HistoryActionExpander.module.scss';

export const HistoryActionExpander = ({ iconSize }) => {
  const { t } = useTranslation('CommonChat');

  const { isAllHistoryActionCollapsed, toggleAllHistoryActions } =
    useExpandHistoryActionsContext();

  return (
    <Tooltip
      placement="leftTop"
      title={t(
        isAllHistoryActionCollapsed ? 'HideFullHistory' : 'SeeFullHistory'
      )}
      mouseEnterDelay={0.5}
      overlayClassName={styles.tooltip}
    >
      <Button
        type="text"
        onClick={() => toggleAllHistoryActions()}
        className={classnames(styles.button, {
          [styles.buttonSmall]: iconSize === 'small'
        })}
      >
        <Icon
          type="story"
          size={20}
          className={styles.icon}
          color={isAllHistoryActionCollapsed ? 'brand' : 'black-55'}
        />
      </Button>
    </Tooltip>
  );
};
