import convertToMinutes from 'utils/convert-to-minutes';
import { convertToHoursAndMinutes } from 'utils/convertToHoursAndMinutes';

export const transformEditorFeedbackConfigValues = values => {
  const { hours, minutes } = convertToHoursAndMinutes(values?.timerFeedback);

  return {
    hours: hours || null,
    minutes: minutes || null,
    isActive: !!values?.isActive
  };
};

export const transformSubmitFeedbackConfigValues = ({
  hours,
  minutes,
  isActive
}) => ({
  isActive,
  timerFeedback: convertToMinutes({ hours, minutes })
});
