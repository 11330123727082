import React, { Fragment, Children, cloneElement } from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_FORMAT_FM } from 'constants/calendar';

import MessageDate from 'components/common/date-list/message-date';

import { shouldDisplayForChat } from './helpers/shouldDisplayForChat';
import { shouldDisplayForHistory } from './helpers/shouldDisplayForHistory';

import styles from './GroupedDateList.module.scss';

export const CHAT_MESSAGE = 'chat_message';
export const HISTORY_ACTION = 'history_action';

export const GroupedDateList = ({
  className,
  dateFormat = DATE_FORMAT_FM,
  children = [],
  source,
  sortedMessages = [],
  groupedMessagesByDate
}) => {
  const calculateDateVisibility = ({
    currentItem,
    previousItem,
    currentDate
  }) => {
    if (source === CHAT_MESSAGE) {
      return shouldDisplayForChat({
        currentItem,
        previousItem,
        currentDate,
        format: dateFormat,
        sortedMessages,
        groupedItems: groupedMessagesByDate
      });
    }

    if (source === HISTORY_ACTION) {
      return shouldDisplayForHistory({
        currentItem,
        previousItem,
        currentDate,
        format: dateFormat,
        sortedMessages
      });
    }

    return false;
  };

  if (!children || !children.length) {
    return null;
  }

  return (
    <>
      {Children.map(children, (child, index) => {
        const currItem = child.props;
        const prevItem = children[index - 1]?.props;
        const currCreatedAt = currItem.createdAt;
        const currDate =
          currItem && currCreatedAt
            ? moment(currCreatedAt).format(dateFormat)
            : undefined;

        const shouldDisplayDate = calculateDateVisibility({
          currentItem: currItem,
          previousItem: prevItem,
          currentDate: currDate
        });

        return (
          <Fragment key={child.key}>
            {shouldDisplayDate && (
              <div className={classnames(styles.date, className)}>
                <MessageDate
                  date={currDate}
                  dateFormat={dateFormat}
                  className={styles.chatDate}
                />
              </div>
            )}
            {cloneElement(child)}
          </Fragment>
        );
      })}
    </>
  );
};
