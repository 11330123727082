import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Spin } from 'antd';
import classnames from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { SELECTED_REQUEST, STATUS_DONE } from 'constants/index';

import Avatar from 'components/common/avatar';
import Icon from 'components/common/icon';
import { IconId } from 'components/requests-view/components';
import UserCard from 'components/common/user-card';
import Tooltip from 'components/common/tooltip';
import Typography from 'components/common/typography';

import {
  getRequestEntries,
  getRequestsHasMore,
  getIsRequestsLoading,
  fetchRequests
} from 'store/requests';

import { useQueryParam } from 'hooks';
import useModalsService from 'services/modals';
import { getFIO } from 'utils/get-fio';
import { getIsTaskOutdated } from 'utils/get-is-outdated';

import styles from './list.module.scss';

const Card = ({
  id,
  parent,
  title,
  kind,
  author,
  responsible,
  status,
  dateEnd,
  completedAt,
  isActive,
  template
}) => {
  const modals = useModalsService({ returnUrl: true });

  const { t } = useTranslation('Task');

  const isStatusDone = status === STATUS_DONE;
  const isOutdated = getIsTaskOutdated({ dateEnd, status, completedAt });
  const users = [{ ...author, role: t('TaskAuthor') }];

  if (responsible) {
    users.push({ ...responsible, role: t('TaskResponsible') });
  }

  const userTooltip = user => (
    <UserCard
      {...user}
      position={user.role}
      avatar={(user.avatarFile || {}).url}
      showEmail
    />
  );

  const renderUsers = () =>
    users.length && (
      <div className={styles.users} data-qa="qa-i65zqy2bvlb9lpq">
        {users.map(user => (
          <Tooltip title={userTooltip(user)} key={`${user.id}-${user.role}`}>
            <Avatar
              src={(user.avatarFile || {}).url}
              size={20}
              isActive={user.isActive}
              className={styles.user}
            >
              {getFIO(user)}
            </Avatar>
          </Tooltip>
        ))}
      </div>
    );

  return (
    <Link
      className={classnames(styles.card, {
        [styles.active]: isActive
      })}
      data-qa="qa-k3gw0qhb4m43gvo"
      to={modals.requests.showDetails({ id })}
    >
      <div className={styles.head} data-qa="qa-bgixrmubizd3joi">
        <IconId object={{ id, kind, parent }} />

        {get(template, 'scheduler.isActive', false) && (
          <Icon type="retweet" size={20} color="green" />
        )}

        {renderUsers()}
      </div>

      <Typography.Title
        level={4}
        ellipsis={{ rows: 2 }}
        title={title}
        delete={isStatusDone}
        className={classnames({
          [styles.outdated]: isOutdated
        })}
        data-qa="qa-58yhy5balm876x7"
        style={{ fontSize: 14 }}
      >
        {title}
      </Typography.Title>
    </Link>
  );
};

export const List = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getIsRequestsLoading);
  const hasMore = useSelector(getRequestsHasMore);
  const list = useSelector(getRequestEntries);

  const [hasError, setHasError] = useState(false);

  const selectedTaskId = +useQueryParam(SELECTED_REQUEST);

  const { t } = useTranslation('Common');

  const loadMore = async () => {
    setHasError(false);

    try {
      await dispatch(fetchRequests());
    } catch (error) {
      setHasError(true);
    }
  };

  const memoList = useMemo(
    () =>
      list.map(item => (
        <Card key={item.id} isActive={item.id === selectedTaskId} {...item} />
      )),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, selectedTaskId, hasMore]
  );

  return (
    <InfiniteScroll
      loadMore={loadMore}
      initialLoad={false}
      hasMore={!isLoading && hasMore && !hasError}
      useWindow={false}
    >
      {memoList}

      {!list.length && !isLoading && !hasError && <span>{t('EmptyList')}</span>}

      {isLoading && <Spin key="spin-list-loading" size="small" />}
    </InfiniteScroll>
  );
};

export default List;
