import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import 'moment/locale/ru';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGE } from 'i18n-config';

import Layout from 'components/common/layout';
import AuthRequiredProvider from 'components/auth-view/common/auth-required-provider';
import MediaViewerProvider from 'components/common/media-viewer-provider';
import ThemeProvider from 'components/common/theme';
import { initialLoadingAnimation } from 'components/common/icons';
import { FeedbackView } from 'components/feedback-view';

import { getRouterUrlActivePath } from 'store/router';
import { setUILanguage } from 'store/ui';

import GetWorkspacesProvider from 'providers/get-workspaces-provider';
import useCacheBuster from 'hooks/common/use-cache-buster';
import { UpserviceWidgetProvider } from 'providers';
import themeVariables from 'theme';
import lazyWithRetry from 'utils/lazy-with-retry';

import UserDataProvider from './user-data-provider';
import BrowserTabsProvider from './browser-tabs-provider';
import Routes from './routes';
import ErrorBoundary from './error-boundary';
import WebStub from '../auth-view/common/web-stub';
import ProductFruitsProvider from './product-fruits-provider';
import WebsiteFormView from '../website-form-view';
import IssuesView from '../issues-view';
import { ModalsProvider } from './modals-provider';

import './index.scss';

const AuthorizationView = lazyWithRetry(() => import('components/auth-view'));
const EngineeringWorksView = lazyWithRetry(
  () => import('components/engineering-works-view')
);
const InviteCanceledView = lazyWithRetry(
  () => import('components/invite-canceled-view')
);

export const App = () => {
  const dispatch = useDispatch();

  const isLoading = useCacheBuster();

  const activePath = useSelector(getRouterUrlActivePath);

  useEffect(() => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      dispatch(setUILanguage(language));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Lottie
        animationData={initialLoadingAnimation}
        loop
        style={{
          height: 150,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    );
  }

  if (
    activePath === '/forms' ||
    activePath === '/issues' ||
    activePath === '/feedback'
  ) {
    return (
      <div className="app">
        <ThemeProvider variables={themeVariables}>
          <Suspense fallback={<Spin />}>
            <ModalsProvider>
              <Switch>
                <Route path="/forms/:channelUuid" component={WebsiteFormView} />

                <Route path="/issues/:issueUuid" component={IssuesView} />

                <Route
                  path="/feedback/:feedbackUuid"
                  component={FeedbackView}
                />
              </Switch>
            </ModalsProvider>
          </Suspense>
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className="app">
      <ErrorBoundary>
        <UpserviceWidgetProvider>
          <BrowserTabsProvider>
            <UserDataProvider>
              <ProductFruitsProvider>
                <ThemeProvider variables={themeVariables}>
                  <MediaViewerProvider>
                    <Suspense fallback={<Spin />}>
                      <Switch>
                        <Redirect exact from="/" to="/auth" />
                        <Redirect from="/login" to="/auth" />
                        <Redirect from="/registration" to="/auth" />

                        <Route path="/auth" component={AuthorizationView} />

                        <Route
                          path="/engineering-works"
                          component={EngineeringWorksView}
                        />

                        <Route
                          path="/invite-canceled"
                          component={InviteCanceledView}
                        />

                        <Route
                          path="/orders/invitation/:token"
                          component={WebStub}
                        />

                        <AuthRequiredProvider>
                          <GetWorkspacesProvider>
                            <ModalsProvider>
                              <Layout>
                                <Switch>
                                  <Route
                                    path="/:workspace"
                                    component={Routes}
                                  />
                                </Switch>
                              </Layout>
                            </ModalsProvider>
                          </GetWorkspacesProvider>
                        </AuthRequiredProvider>
                      </Switch>
                    </Suspense>
                  </MediaViewerProvider>
                </ThemeProvider>
              </ProductFruitsProvider>
            </UserDataProvider>
          </BrowserTabsProvider>
        </UpserviceWidgetProvider>
      </ErrorBoundary>
    </div>
  );
};

export default App;
