import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Icon from 'components/common/icon';
import { ShareAccessIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import styles from './ChangeAccessTypeModal.module.scss';

export const ChangeAccessTypeModal = ({
  open,
  onClose,
  isLoading,
  onConfirm
}) => {
  const { t } = useTranslation(['AddTask', 'ScreenErrors', 'Common']);

  return (
    <Modal
      width={440}
      open={open}
      onClose={onClose}
      destroyOnClose
      centered
      maskClosable={false}
      contentClassName={styles.content}
    >
      <div className={styles.iconWrap}>
        <Icon component={ShareAccessIcon} />
      </div>

      <h4 className={styles.title}>{t('ChangeTaskAccess')}</h4>

      <Typography.Paragraph color="black-45">
        {t('ChangeTaskAccessDescription')}
      </Typography.Paragraph>

      <div className={styles.btnWrap}>
        <Button
          mood="negative"
          type="secondary"
          width="expanded"
          size="large"
          onClick={onClose}
        >
          {t('Cancel', { ns: 'Common' })}
        </Button>

        <Button
          type="primary"
          size="large"
          width="expanded"
          loading={isLoading}
          onClick={onConfirm}
          style={{ marginLeft: 20 }}
        >
          {t('ContinueBtn', { ns: 'ScreenErrors' })}
        </Button>
      </div>
    </Modal>
  );
};
