import React, { useState, useEffect, useRef } from 'react';
import { Input, Divider, Spin, Typography, Alert, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, isEqual, union, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TITLE,
  DATE_TIME_FORMAT_FM,
  TYPE_TASK,
  STATUS_BACKLOG,
  DESCRIPTION,
  DATE_PICKER_TIME_FORMAT,
  TYPE_ADDRESS,
  TYPE_MEETING,
  TYPE_CALL,
  TYPE_LETTER,
  TYPE_KIND,
  TYPE_CONTACT_TO,
  TYPE_CONTACT,
  TYPE_PROJECT,
  TYPE_AGREEMENT,
  TYPE_LETTER_KIND,
  SCHEDULER,
  TYPE_ACQUAINTANCE,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_AGREEMENT_TASK,
  TYPE_COMPLETE_REQUIRE,
  // COMPLETE_CLOSE_ORDER,
  // STATUS_NEGOTIATION,
  // STATUS_ACTIVE,
  TYPE_RELATIONS,
  TYPE_ASSET,
  TYPE_MEETING_CONTACTS,
  STATUS_DONE,
  STATUS_CANCELLED
} from 'constants/index';

import LocationInput from 'components/common/controls/location-input';
import MarkdownFormItem from 'components/common/new-editor/form/markdown-form-item';
import ActionsDropdown from 'components/common/actions';
import {
  ContactSelect,
  EmployeeSelect,
  // OrderSelect,
  ProjectSelect,
  RelationsSelect,
  SprintSelect
} from 'components/common/controls/custom-select';
import DatePicker from 'components/common/date-picker';
import Button from 'components/common/button';
import Switch from 'components/common/controls/switch';
import ProjectParticipantsSelect from 'components/common/controls/custom-select/project-participants-select';
import Icon from 'components/common/icon';
import { LabelWithTooltip } from 'components/common/hook-form';
import useMeetingCalendarContext from 'components/tasks-view/components/meeting-calendar/use-meeting-calendar-context';
import { SkeletonEntityCreation } from 'components/common/skeletons';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import { getTaskDescriptionText } from 'components/tasks-view/utils';
import { CloseIcon } from 'components/common/icon/icons';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import {
  validateIsRequire,
  validateMinLength,
  validateMaxLength,
  validateIsNumber,
  makeValidateDatesStartAfterEnd
} from 'utils/validators';
import mapUserToValueSelect, {
  mapUserToSelectValue
} from 'utils/map-user-to-select';
import useMinMaxTime from 'hooks/common/use-min-max-time';
import { usePrevious } from 'hooks/common';
import getTimeByEstimate from 'utils/get-time-by-estimate';
import mapValue from 'utils/map-value';
import alphabeticallySort from 'utils/alphabetically-sort';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { getFullName } from 'utils/get-fio';

import {
  getFieldsByTypes,
  getFullFilledFields,
  isAllowedToDelete
} from '../creator/utils';
import LetterTypeSelect from '../creator/main-form/letter-type-select';
import TaskTypeSelect from '../../../components/task-type-select';
import ActionTypeController from '../components/actions/type-controller';
import ActionTypeResponsible from '../components/actions/type-responsible';
import ActionTypeCoResponsibles from '../components/actions/type-co-responsibles';
import AgreementSteps from '../creator/main-form/agreement-steps';
import IntervalSelect from '../creator/main-form/interval-select';
import CompleteRequireSelect from '../creator/main-form/complete-require-select';

import styles from './editor.module.scss';

const { Text } = Typography;

const BaseForm = Form.create()(({
  form,
  onSubmit,
  values = {},
  isLoading,
  isLoadingAll,
  isTemplate,
  meetingCalendar
}) => {
  const amplitude = useAmplitude();

  const [activeFields, setActiveFields] = useState([]);
  const [contactToVisibility, setContactToVisibility] = useState(false);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [visibleEstimate, setVisibleEstimate] = useState(false);
  const [currentTaskType, setCurrentTaskType] = useState((values || {}).kind);

  const prevValues = usePrevious(values);
  const prevTaskType = usePrevious(currentTaskType);

  const { goToDate, selectedDates, selectDatesToCalendar, setResources } =
    useMeetingCalendarContext();

  const { t } = useTranslation([
    'AddTask',
    'Errors',
    'TaskLinks',
    'EditTaskTemplate'
  ]);

  const isTypeTask = (currentTaskType || '').includes(TYPE_TASK);
  const isTypeMeeting = currentTaskType === TYPE_MEETING;
  const isTypeCall = currentTaskType === TYPE_CALL;
  const isTypeLetter = currentTaskType === TYPE_LETTER;
  const isTypeAgreement = currentTaskType === TYPE_AGREEMENT;
  const isTypeAcquaintence = currentTaskType === TYPE_ACQUAINTANCE;

  const isTopTask = !(values || {}).parent;
  const isSlaTask = !!Object.values(values.taskInfo || {}).length;

  const isBacklog = values && values.status === STATUS_BACKLOG;
  const isStatusCancelled = values && values.status === STATUS_CANCELLED;
  const isStatusDone = values && values.status === STATUS_DONE;

  const allowEditProject = isTopTask;
  const allowEditSprint =
    allowEditProject && isTypeTask && !isStatusCancelled && !isStatusDone;

  const hideResponsibleField =
    isTypeAgreement || isTypeAcquaintence || isTypeMeeting;

  useEffect(() => {
    if (!values || !Object.keys(values).length) return;

    if (currentTaskType === values.kind) return;

    setCurrentTaskType(values.kind);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const hasInputEstimate = values && values.estimation > 0;
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } =
    form;
  const selectRef = useRef(null);

  const isFilesLoading = useUploadingFiles(
    (getFieldValue(DESCRIPTION) || {}).fileList
  );

  const isActiveScheduler = getFieldValue('isActiveScheduler');
  const coResponsibles = getFieldValue(TYPE_CO_RESPONSIBLES);

  const isStoryPointEstimation =
    getFieldValue('isStoryPointEstimation') === undefined
      ? values.isStoryPointEstimation
      : getFieldValue('isStoryPointEstimation');

  const { validateValidityDateStateValues, changeValidityDateStateValue } =
    useValidityDateFileList({
      fileList: (getFieldValue(DESCRIPTION) || {}).fileList,
      onChange: updatedFileList =>
        setFieldsValue({
          [DESCRIPTION]: {
            ...getFieldValue(DESCRIPTION),
            fileList: updatedFileList
          }
        })
    });

  useEffect(() => {
    if (!getFieldValue('isActiveScheduler') && isTemplate) {
      setFieldsValue({
        [TYPE_DATE_START]: null,
        [TYPE_DATE_END]: null
      });
    }
    //  eslint-disable-next-line
  }, [isActiveScheduler, isTemplate]);

  useEffect(() => {
    if (selectedDates.jsEvent) {
      setFieldsValue({
        [TYPE_DATE_START]: selectedDates.start,
        [TYPE_DATE_END]: selectedDates.end
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  useEffect(() => {
    if (values.dateStart && values.dateEnd) {
      selectDatesToCalendar({
        dateStart: moment(values.dateStart, DATE_TIME_FORMAT_FM).toDate(),
        dateEnd: moment(values.dateEnd, DATE_TIME_FORMAT_FM).toDate()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dateEnd, values.dateStart]);

  useEffect(() => {
    if (coResponsibles) {
      setResources(
        coResponsibles.map(r => ({
          id: r.value,
          title: getFullName(r.label)
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(coResponsibles || []).length]);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CO_RESPONSIBLES || a === TYPE_CONTROLLER) {
      return -1;
    }
    if (
      b === TYPE_CO_RESPONSIBLES ||
      b === TYPE_CONTROLLER ||
      a === TYPE_MEETING_CONTACTS
    ) {
      return 1;
    }
    return 0;
  };

  const addFields = (...types) =>
    setActiveFields(
      uniq([...activeFields, ...types]).sort(sortByCoResponsiblesAndController)
    );
  const removeField = type =>
    setActiveFields(activeFields.filter(field => field !== type));

  useEffect(() => {
    if (isEqual(prevTaskType, currentTaskType) && isEqual(prevValues, values))
      return;

    setVisibleDateStart(false);
    setContactToVisibility(false);

    switch (currentTaskType) {
      case TYPE_MEETING:
        setVisibleDateStart(true);
        break;
      case TYPE_CALL:
      case TYPE_LETTER:
        setContactToVisibility(true);
        break;
      default:
        break;
    }

    const fullFilledFields = getFullFilledFields({ values, activeFields });
    const fieldsBYTypes = getFieldsByTypes(currentTaskType, activeFields);

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);
    if (fullFilledFields.includes(TYPE_TIME)) setVisibleEstimate(true);

    addFields(...union(fullFilledFields, fieldsBYTypes));

    if (values.isScheduler) {
      addFields(TYPE_DATE_START);
      setVisibleDateStart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, currentTaskType]);

  const handleSubmit = event => {
    event.preventDefault();

    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    validateFields((err, value) => {
      if (!err) {
        const getProjectValues = () => {
          if (activeFields.includes(TYPE_PROJECT) && allowEditProject) {
            return {
              project: (value.project || {}).value || null,
              sprint: (value.sprint || {}).value || null
            };
          }

          return {
            project: allowEditProject ? null : undefined,
            sprint: allowEditSprint ? null : undefined
          };
        };

        const getRelations = () => {
          let results = [];

          if (activeFields.includes(TYPE_RELATIONS)) {
            results = [...(value.relations || [])];
          }

          if (activeFields.includes(TYPE_MEETING_CONTACTS)) {
            results = [
              ...results,
              ...transformValueToRelation(
                value.meetingContacts || [],
                TYPE_CONTACT
              )
            ];
          }

          return results;
        };

        onSubmit({
          values: {
            ...value,
            ...value.description,
            ...getProjectValues(),
            storyPoint: +value.storyPoint || 0,
            relations: getRelations(),
            location: value.location || '',
            locationExact: value.locationExact || '',
            description: [
              { text: value.description.description },
              ...(value.description.links || [])
            ],
            kind: currentTaskType,
            schedulerConfig: {
              isActive: value.isActiveScheduler,
              freqInterval: value.freqInterval,
              weekdays: value.weekdays,
              freqType: value.freqType
            },
            coResponsibles: (value.coResponsibles || []).map(mapValue),
            reconsciling: (value.reconsciling || {}).value,
            approvingManager: (value.approvingManager || {}).value,
            completeRequire: activeFields.includes(TYPE_COMPLETE_REQUIRE)
              ? value.completeRequire
              : null
          }
        });

        amplitude.updateTaskEvent({
          task: prevValues,
          value: {
            ...value,
            responsible: (value.responsible || {}).value
          },
          isTemplate
        });
      }
    });
  };

  const getDateFieldValue = key => {
    const value = getFieldValue(key);

    return moment(value).isValid() ? value : undefined;
  };

  const startDate = getDateFieldValue(TYPE_DATE_START);
  const endDate = getDateFieldValue(TYPE_DATE_END);
  const isOutdated = moment(new Date()).isAfter(endDate);

  const permissions = values && values.permissions;
  const allowChange = permissions && permissions.updateTask;

  const allowChangeDateStart =
    allowChange && (!isTemplate || values[SCHEDULER]);
  const allowChangeEstimate = !!permissions && permissions.updateTaskEstimate;
  const allowChangeResponsible =
    permissions && permissions.changeTaskResponsible;
  const allowChangeController = permissions && permissions.changeTaskController;

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const project = getFieldValue('project');
  const projectId = (project || {}).value;

  const Select = projectId ? ProjectParticipantsSelect : EmployeeSelect;

  // const relatedOrder = relations.find(({ type }) => type === TYPE_ORDER);

  const isDateEndRequired = () =>
    (!(projectId || isBacklog) || isTypeAgreement) &&
    (!isTemplate || isActiveScheduler);

  const changeDateEnd = date => {
    const result =
      !endDate && !moment(date).toDate().getHours()
        ? new Date(moment(date).toDate().setHours(23, 45))
        : date;

    if (!startDate) {
      goToDate(result);
    }

    selectDatesToCalendar({ dateStart: startDate, dateEnd: result });

    return result;
  };

  const apartValues = values && getTimeByEstimate(values.estimation);

  const isPeriodic = getFieldValue(SCHEDULER);
  // const isCompleteCloseOrder =
  //   getFieldValue(TYPE_COMPLETE_REQUIRE) === COMPLETE_CLOSE_ORDER &&
  //   activeFields.includes(TYPE_COMPLETE_REQUIRE);

  const actions = [
    {
      type: TYPE_DATE_START,
      title: t(isTypeMeeting ? 'StartDateMeet' : 'StartDate'),
      onClick: () => {
        addFields(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      allow: allowChangeDateStart,
      visibility: isTypeTask || isTypeAgreement || isTypeCall
    },
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addFields(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: isTypeTask || isTypeMeeting,
      component: (
        <div className={styles.flexWrap} data-qa="qa-pmdbroh78r37ims">
          <Form.Item
            label={t('MainAddress')}
            className={styles.location}
            data-qa="qa-vovk3v36jhc2e7h"
          >
            {getFieldDecorator('location', {
              initialValue: (values && values.location) || ''
            })(<LocationInput />)}
          </Form.Item>

          <Form.Item
            label={t('AdditionalAddress')}
            className={styles.locationExact}
            data-qa="qa-3fdwb9wnzq3c5wb"
          >
            {getFieldDecorator('locationExact', {
              initialValue: (values && values.locationExact) || ''
            })(
              <Input
                allowClear={false}
                autoComplete="off"
                data-qa="qa-f6ngi1txe095a5v"
                maxLength={25}
              />
            )}
          </Form.Item>
        </div>
      )
    },
    {
      type: TYPE_CONTACT_TO,
      title: t('WhoToCall'),
      onClick: () => {
        addFields(TYPE_CONTACT_TO);
        setContactToVisibility(true);
      },
      icon: 'contacts',
      allow: true,
      visibility: isTypeCall || isTypeLetter
    },
    {
      type: TYPE_CO_RESPONSIBLES,
      allow: true,
      visibility: isTypeAcquaintence || isTypeMeeting,
      component: (
        <ActionTypeCoResponsibles
          initialValue={(values.coResponsibles || []).map(mapUserToSelectValue)}
          relatedProjectId={projectId}
          getFieldDecorator={getFieldDecorator}
          label={t(isTypeAcquaintence ? 'Acquainted' : 'Employees')}
        />
      )
    },
    {
      type: TYPE_MEETING_CONTACTS,
      allow: true,
      visibility: isTypeMeeting,
      component: (
        <Form.Item label={t('ContactsOptional')}>
          {getFieldDecorator(TYPE_MEETING_CONTACTS, {
            initialValue: (values.relations || [])
              .filter(item => item.type === TYPE_CONTACT)
              .map(item => ({
                label: {
                  ...item.relation,
                  firstName: item.relation.name
                },
                value: item.objectId
              }))
          })(<ContactSelect label={null} isMulti closeMenuOnSelect={false} />)}
        </Form.Item>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      onClick: () => addFields(TYPE_CONTROLLER),
      icon: 'user-add',
      allow: allowChangeController,
      visibility: !isTypeAgreement && !isTypeAcquaintence && !isTypeMeeting,
      component: (
        <ActionTypeController
          getFieldDecorator={getFieldDecorator}
          relatedProjectId={projectId}
          initialValue={values && mapUserToValueSelect(values.controller)}
        />
      )
    },
    {
      type: TYPE_APPROVING_MANAGER,
      title: t('Approving'),
      onClick: () => addFields(TYPE_APPROVING_MANAGER),
      icon: 'audit',
      allow: true,
      visibility: isTypeTask && (values || {}).approvingManager,
      component: (
        <Form.Item label={t('Approving')}>
          {getFieldDecorator(TYPE_APPROVING_MANAGER, {
            initialValue: (values || {}).approvingManager && {
              value: values.approvingManager.id,
              label: values.approvingManager
            },
            rules: [
              {
                required: activeFields.includes(TYPE_APPROVING_MANAGER),
                message: t('RequiredField', { ns: 'Errors' })
              }
            ]
          })(<Select projectId={projectId} data-qa="qa-17nxn3r56d0s3cd" />)}
        </Form.Item>
      )
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => {
        addFields(TYPE_TIME);
        setVisibleEstimate(true);
      },
      icon: 'timer',
      allow: allowChangeEstimate,
      visibility: true
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addFields(TYPE_PROJECT),
      icon: 'project',
      hideDeleteBtn: isBacklog || !allowEditProject,
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: 2
      },
      component: (
        <>
          <Text className={styles.subTitle}>{t('Project')}</Text>

          <div className={styles.projectFields}>
            <Form.Item>
              {getFieldDecorator('project', {
                initialValue: values.project && {
                  value: values.project.id,
                  label: values.project
                }
              })(
                <ProjectSelect
                  label={t('Project')}
                  params={{
                    isActive: true
                  }}
                  isClearable={!isBacklog}
                  isDisabled={!allowEditProject}
                  callback={() => setFieldsValue({ sprint: null })}
                />
              )}
            </Form.Item>

            {project?.label?.sprintsCount > 0 && allowEditSprint && (
              <Form.Item>
                {getFieldDecorator('sprint', {
                  initialValue: values.sprint && {
                    value: values.sprint.id,
                    label: values.sprint
                  }
                })(
                  <SprintSelect
                    label={t('Sprint')}
                    params={{
                      project: projectId,
                      status: ['active', 'planned'],
                      isLag: false
                    }}
                  />
                )}
              </Form.Item>
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addFields(TYPE_RELATIONS),
      icon: 'link',
      allow: isTemplate,
      visibility: isTemplate,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle)}
            style={{ marginBottom: 16, display: 'block' }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Text>

          <Form.Item
            className={styles.relationsSelect}
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('TaskLinksDesc', { ns: 'TaskLinks' })}
              />
            }
          >
            {getFieldDecorator('relations', {
              initialValue: (values.relations || []).filter(r =>
                isTypeMeeting ? r.type !== TYPE_CONTACT : true
              )
            })(
              <RelationsSelect
                hiddenTypes={
                  ((isTypeCall || isTypeLetter || isTypeMeeting) && [
                    TYPE_CONTACT
                  ]) ||
                  // (isCompleteCloseOrder && [TYPE_ORDER]) ||
                  (!isTypeTask && [TYPE_ASSET]) ||
                  []
                }
              />
            )}
          </Form.Item>
        </>
      )
    },
    {
      type: TYPE_COMPLETE_REQUIRE,
      title: t('TaskResult'),
      onClick: () => addFields(TYPE_COMPLETE_REQUIRE),
      icon: 'diff',
      allow: true,
      visibility: isTypeTask || isTypeCall || isTypeLetter,
      component: (
        <>
          <Form.Item
            label={t('TaskResultHeading')}
            data-qa="qa-rrblkltzedkiwwn"
          >
            {getFieldDecorator(TYPE_COMPLETE_REQUIRE, {
              initialValue: values.completeRequire
            })(<CompleteRequireSelect />)}
          </Form.Item>

          {/* {isCompleteCloseOrder && (
              <Form.Item data-qa="qa-s7zg6auktn6sdog">
                {getFieldDecorator('closeOrder', {
                  initialValue: relatedOrder && {
                    value: relatedOrder.id,
                    label: {
                      statusId: relatedOrder.id,
                      order: {
                        ...relatedOrder.label,
                        title:
                          relatedOrder.label.name || relatedOrder.label.title
                      }
                    }
                  },
                  rules: [
                    {
                      required: isCompleteCloseOrder,
                      message: 'Required'
                    }
                  ]
                })(
                  <OrderSelect
                    fetchParams={{
                      isCustomer: false,
                      access: 'full',
                      status: [STATUS_NEGOTIATION, STATUS_ACTIVE]
                    }}
                    isDisabled={
                      !!transformRelations(values).find(
                        ({ type }) => type === TYPE_ORDER
                      )
                    }
                    data-qa="qa-rr6gdcjzmb52dm2"
                    onChange={order => {
                      setRelations([
                        ...relations.filter(rel => rel.type !== TYPE_ORDER),
                        {
                          id: order.value,
                          type: TYPE_ORDER,
                          ...order
                        }
                      ]);
                    }}
                  />
                )}
              </Form.Item>
            )} */}
        </>
      )
    }
  ].sort((a, b) => alphabeticallySort(a, b, 'title'));

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-d8fw0lok518ow1u">
      <Form.Item
        label={t('TaskName')}
        colon={false}
        data-qa="qa-gibw73arzr42id1"
      >
        {getFieldDecorator(TITLE, {
          initialValue: values && values.title,
          rules: [validateIsRequire(), validateMinLength(2)]
        })(
          <Input
            className={styles.title}
            autoComplete="off"
            data-qa="qa-cu8vtko6zl1iets"
            disabled={(isTypeAgreement || isTypeAcquaintence) && !isTemplate}
          />
        )}
      </Form.Item>
    </div>
  );

  const renderDescription = () => (
    <div className={styles.field} data-qa="qa-yvmbmxrfe6s9ats">
      <MarkdownFormItem
        itemProps={{ label: t('TaskDescriptionMobile') }}
        markdownProps={{
          placeholder: t('TaskDescriptionPlchldr'),
          isDisabled: (isTypeAgreement || isTypeAcquaintence) && !isTemplate,
          readOnly: isTypeAgreement || isTypeAcquaintence,
          actionsDeps: {
            taskId: (values || {}).id,
            sendCopyToComment: false
          },
          resizeInput: true,
          destination: {
            entityType: TYPE_TASK,
            entityId: (values || {}).id
          },
          allowManageSubscribers: true,
          hideSubscribersAction: true,
          hideValidityDateAction: true,
          validityDateDeps: {
            changeValidityDateStateValue
          },
          highlightAttachmentsBackground: true,
          mentionClassName: styles.mentionWrap
        }}
        getFieldDecorator={getFieldDecorator}
        initialValue={{
          description: getTaskDescriptionText(values.description),
          fileList: values.fileList || []
        }}
        name={DESCRIPTION}
      />
    </div>
  );

  const renderResponsible = () => {
    const isMultiplyResponsibles = Array.isArray(values.responsible);
    const initialValue = isMultiplyResponsibles
      ? values.responsible.map(mapUserToSelectValue)
      : mapUserToSelectValue(values.responsible || []);

    return (
      <ActionTypeResponsible
        initialValue={values.responsible && initialValue}
        relatedProjectId={projectId}
        getFieldDecorator={getFieldDecorator}
        isDisabled={!allowChangeResponsible}
        isMulti={isMultiplyResponsibles}
      />
    );
  };

  const renderAgreementSteps = () => {
    const transformSteps = () =>
      (values.agreementSteps || []).map(step => ({
        ...step,
        employees: step.employees.map(e => ({ value: e.id, label: e }))
      }));

    if (isTypeAgreement) {
      return (
        <AgreementSteps
          initialValue={isTemplate ? values.agreementSteps : transformSteps()}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
        />
      );
    }

    return null;
  };

  const getDateEndRules = () => {
    if (!isDateEndRequired()) {
      return [];
    }

    return [
      validateIsRequire(),
      {
        validator: makeValidateDatesStartAfterEnd({
          form,
          checkSameDates: true
        })
      }
    ];
  };

  const renderDates = () => (
    <div className={styles.dates} data-qa="qa-v5k4pfxja3nbndv">
      {visibleDateStart && (
        <div className={styles.dateStart} data-qa="qa-pihxbjrb94sdm36">
          <Form.Item label={t(isTypeMeeting ? 'StartDateMeet' : 'StartDate')}>
            {getFieldDecorator(TYPE_DATE_START, {
              valuePropName: 'selected',
              initialValue:
                values &&
                values.dateStart &&
                moment(values.dateStart, DATE_TIME_FORMAT_FM).toDate(),
              rules: [
                {
                  required:
                    (isPeriodic && isActiveScheduler) ||
                    (!isTemplate && isTypeMeeting),
                  message: t('RequiredField', { ns: 'Errors' })
                }
              ]
            })(
              <DatePicker
                showTimeSelect
                timeIntervals={15}
                wrapperClassname={styles.datePicker}
                data-qa="qa-wg5hts5kj1a8lvs"
                dateFormat={DATE_PICKER_TIME_FORMAT}
                minDate={new Date()}
                maxDate={isOutdated ? undefined : endDate}
                minTime={minTimeStart}
                maxTime={maxTimeStart}
                openToDate={
                  moment(startDate).isAfter(new Date()) ? startDate : new Date()
                }
                callback={date => {
                  selectDatesToCalendar({
                    dateStart: date,
                    dateEnd: endDate
                  });
                  goToDate(date);
                }}
              />
            )}
          </Form.Item>

          {isAllowedToDelete(TYPE_DATE_START, currentTaskType) &&
            !isPeriodic && (
              <Button
                type="text"
                icon={<CloseIcon />}
                className={styles.btnDelete}
                data-qa="qa-1auv4jgdhfcz0tn"
                onClick={() => {
                  removeField(TYPE_DATE_START);
                  setVisibleDateStart(false);
                }}
              />
            )}
        </div>
      )}

      <Form.Item
        label={t(isTypeMeeting ? 'DueDateMeet' : 'TaskDueDate')}
        className={styles.dateEnd}
        data-qa="qa-6kbmbrztkqz0a9h"
        colon={false}
      >
        {getFieldDecorator(TYPE_DATE_END, {
          valuePropName: 'selected',
          initialValue:
            values &&
            values.dateEnd &&
            moment(values.dateEnd, DATE_TIME_FORMAT_FM).toDate(),
          getValueFromEvent: changeDateEnd,
          rules: getDateEndRules()
        })(
          <DatePicker
            showTimeSelect
            timeIntervals={15}
            wrapperClassname={styles.datePicker}
            dateFormat={DATE_PICKER_TIME_FORMAT}
            minDate={startDate || new Date()}
            minTime={minTimeEnd}
            maxTime={maxTimeEnd}
            disabled={(isTemplate && !values[SCHEDULER]) || isSlaTask}
            openToDate={isOutdated ? new Date() : undefined}
            onChange={changeDateEnd}
          />
        )}
      </Form.Item>

      {isTemplate &&
        getFieldDecorator(SCHEDULER, {
          initialValue: values.isScheduler ? true : values[SCHEDULER] || false,
          valuePropName: 'checked'
        })(
          <Checkbox
            disabled={isTemplate || isSlaTask}
            style={{ marginLeft: 'auto', marginTop: 28 }}
          >
            {t('RepeatTaskChckbx')}
          </Checkbox>
        )}
    </div>
  );

  const renderEstimate = () => (
    <div
      className={classnames(styles.field, styles.timesetField)}
      data-qa="qa-ed7qfsmt0j50gbn"
    >
      <Text className={styles.subTitle} data-qa="qa-on5j6eyngjeerw6">
        {t('EstimatedTime')}
      </Text>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        {isStoryPointEstimation ? (
          <Form.Item label={t('Storypoints')} style={{ maxWidth: 184 }}>
            {getFieldDecorator('storyPoint', {
              initialValue: values.storyPoint,
              rules: [validateIsNumber()]
            })(<Input size="default" placeholder="000" />)}
          </Form.Item>
        ) : (
          <div className={styles.timeset} data-qa="qa-fdatgk2o8m6vhmf">
            <Form.Item colon={false} label={t('Hours')}>
              {getFieldDecorator('hours', {
                initialValue: hasInputEstimate
                  ? apartValues.hours.toString()
                  : undefined,
                rules: [validateIsNumber(), validateMaxLength(3)]
              })(
                <Input
                  size="default"
                  placeholder="000"
                  data-qa="qa-v0wsrdnd46troq4"
                />
              )}
            </Form.Item>
            <Form.Item colon={false} label={t('Minutes')}>
              {getFieldDecorator('minutes', {
                initialValue: hasInputEstimate
                  ? apartValues.minutes.toString()
                  : undefined,
                rules: [validateIsNumber(), validateMaxLength(3)]
              })(
                <Input
                  size="default"
                  placeholder="000"
                  data-qa="qa-awyuh1k1tpkafu2"
                />
              )}
            </Form.Item>
          </div>
        )}

        {!values.parent && (
          <Form.Item style={{ margin: '27px 0 0 0', width: 'auto' }}>
            {getFieldDecorator('isStoryPointEstimation', {
              initialValue: values.isStoryPointEstimation
            })(<Switch label={t('ChangeToStorypointsToggle')} />)}
          </Form.Item>
        )}
      </div>

      <Button
        type="text"
        icon={<CloseIcon />}
        className={styles.btnDelete}
        style={{
          top: 2
        }}
        data-qa="qa-suyy8dz4f7mlr3k"
        onClick={() => {
          removeField('time');
          setVisibleEstimate(false);
        }}
      />
    </div>
  );

  const filteredActions =
    values &&
    actions
      .filter(action => !activeFields.includes(action.type))
      .filter(action => action.visibility)
      .filter(action => action.title);

  if (isEmpty(values) && isLoadingAll) {
    return <SkeletonEntityCreation />;
  }

  if (!isEmpty(values)) {
    return (
      <Spin spinning={isLoadingAll}>
        <Form
          className={styles.content}
          data-qa="qa-ojf06bmx5f4juyu"
          onSubmit={handleSubmit}
          hideRequiredMark
          colon={false}
        >
          <div className={styles.typeTaskSelect} data-qa="qa-c3hr6zfxbr74kjs">
            <Text className={styles.subTitle} data-qa="qa-hx1nkwoq859vuwa">
              {t('TaskTypeHeading')}
            </Text>
            <Form.Item className={styles.select} data-qa="qa-4kbd5qm6kutpb7e">
              {getFieldDecorator(TYPE_KIND, {
                initialValue:
                  values.kind === TYPE_AGREEMENT_TASK ? TYPE_TASK : values.kind
              })(
                <TaskTypeSelect
                  onChange={setCurrentTaskType}
                  data-qa="qa-kwkfml5qexo6zxn"
                  ref={selectRef}
                  disabled
                />
              )}
            </Form.Item>
          </div>
          <div className={styles.fieldsWrap} data-qa="qa-nptmk7n1yn8ufnu">
            {values.isScheduler && (
              <Form.Item>
                {getFieldDecorator('isActiveScheduler', {
                  initialValue: (values.schedulerConfig || {}).isActive
                })(
                  <Switch
                    label={t('TemplateRepeatToggle', {
                      ns: 'EditTaskTemplate'
                    })}
                  />
                )}
              </Form.Item>
            )}

            {renderTitle()}

            {renderDescription()}

            {renderDates()}

            {isSlaTask && (
              <Alert
                type="warning"
                message={t('SLADateWarning')}
                className={styles.slaAlert}
              />
            )}

            {isTypeMeeting && meetingCalendar && (
              <Button
                type="secondary"
                size="large"
                className={styles.visibleCalendarBtn}
                onClick={() =>
                  meetingCalendar.setVisible(!meetingCalendar.visible)
                }
              >
                {t(
                  meetingCalendar.visible
                    ? 'HideCalendarBtn'
                    : 'OpenCalendarBtn'
                )}
              </Button>
            )}

            {isPeriodic && isTemplate && (
              <IntervalSelect form={form} values={values} />
            )}

            {visibleEstimate && renderEstimate()}

            {!hideResponsibleField && renderResponsible()}

            {renderAgreementSteps()}

            {(activeFields || []).map(field => {
              const action = actions
                .filter(item => item.visibility)
                .find(a => a.type === field);

              if (action && field !== 'dateStart' && field !== 'time') {
                return (
                  <div
                    key={field}
                    className={styles.field}
                    data-qa="qa-ub8mqlbdzsakvye"
                  >
                    {action.component}

                    {isAllowedToDelete(field, currentTaskType) &&
                      !action.hideDeleteBtn && (
                        <Button
                          type="text"
                          icon={<CloseIcon />}
                          className={styles.btnDelete}
                          style={action.deleteBtnStyle}
                          data-qa="qa-wzdz0dd8bd9c6bk"
                          onClick={() => removeField(field)}
                        />
                      )}
                  </div>
                );
              }
              return undefined;
            })}

            {contactToVisibility && (
              <div className={styles.withHint} data-qa="qa-j7jl86e0dzje7n2">
                <Form.Item label={t(isTypeCall ? 'WhoToCall' : 'WhoToSend')}>
                  {getFieldDecorator(TYPE_CONTACT_TO, {
                    initialValue: (values.relations || [])
                      .filter(item => item.type === TYPE_CONTACT)
                      .map(item => ({
                        label: {
                          ...item.relation,
                          firstName: item.relation.name
                        },
                        value: item.objectId
                      }))
                  })(
                    <ContactSelect
                      label={null}
                      isMulti
                      closeMenuOnSelect={false}
                      // onChange={contacts => {
                      //   setRelations([
                      //     ...relations,
                      //     ...filterSameRelations(
                      //       contacts.map(item => ({
                      //         ...item,
                      //         id: item.value,
                      //         objectId: item.value,
                      //         type: TYPE_CONTACT
                      //       })),
                      //       relations
                      //     )
                      //   ]);
                      // }}
                    />
                  )}
                </Form.Item>

                <Alert
                  type="warning"
                  message={t('WhoToSendWarning')}
                  style={{ fontSize: 12 }}
                />

                {isTypeLetter && (
                  <Form.Item
                    label={t('WhatToSend')}
                    className={styles.letterKind}
                    data-qa="qa-07u1g73g1wp9xbd"
                  >
                    {getFieldDecorator(TYPE_LETTER_KIND, {
                      initialValue: values.letterKind,
                      rules: [
                        {
                          required: isTypeLetter,
                          message: t('RequiredField', { ns: 'Errors' })
                        }
                      ]
                    })(<LetterTypeSelect isMulti />)}
                  </Form.Item>
                )}
              </div>
            )}
          </div>

          <Divider className={styles.divider} data-qa="qa-a7wjybuw5tx7cpb" />

          <ActionsDropdown
            actions={filteredActions}
            placement="bottomLeft"
            btnClassName={styles.btnDropdown}
            overlayClassName={styles.dropdownOverlay}
            data-qa="qa-bthkgksizx45as2"
          >
            <Button
              type="link"
              style={{
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                padding: 0
              }}
              data-qa="qa-n1dxcbul5efsb0x"
            >
              <Icon type="plus" /> {t('ShowAllFieldsBtn')}
            </Button>
          </ActionsDropdown>

          <Button
            className={styles.btnSteps}
            data-qa="qa-nlsx1e9ljbre2yd"
            disabled={isFilesLoading}
            loading={isLoading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            {t('SaveBtn')}
          </Button>
        </Form>
      </Spin>
    );
  }

  return undefined;
});

BaseForm.propTypes = {
  value: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoadingAll: PropTypes.bool
};

BaseForm.defaultProps = {
  value: {},
  isLoading: false,
  isLoadingAll: false
};

export default BaseForm;
