import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';

import { useAgentSupport } from 'providers/agent-support-provider';

import styles from './AgentSupportPrompts.module.scss';

const { Text } = Typography;

export const AgentSupportPrompts = () => {
  const { t } = useTranslation('AIAssistant');

  const { sendPrompt, prompts } = useAgentSupport();

  return (
    <div className={styles.root}>
      <Text weight="semibold">{t('HowCanIHelpHeading')}</Text>

      {prompts.map(prompt => (
        <div
          key={prompt.id}
          className={styles.prompt}
          onClick={() => sendPrompt({ promptData: { prompt } })}
        >
          {prompt.text}
        </div>
      ))}
    </div>
  );
};
