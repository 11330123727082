import {
  DESCRIPTION,
  SCHEDULER,
  TYPE_DATE_START,
  TYPE_RELATIONS,
  TYPE_REQUEST,
  TYPE_TIME
} from 'constants/index';

const SLA_OBJECT_DATA = {
  COPY_OR_CHAT: slaObject => ({
    type: 'channel',
    id: slaObject?.item?.value,
    name: slaObject?.item?.label?.name
  }),

  DEFAULT_CHANNEL: slaObject => ({
    type: 'channel',
    id: slaObject?.value,
    name: slaObject?.label?.name
  }),

  EXTENDED: slaObject => ({
    type: slaObject?.type || 'channel',
    id: slaObject?.item?.value,
    name: slaObject?.item?.label?.name
  })
};

export const adaptSlaData = ({
  slaObject,
  isObjectSlaForChannelsEnabled,
  isCopy,
  isFromChat
}) => {
  if (!slaObject) return null;

  if (isObjectSlaForChannelsEnabled) {
    return SLA_OBJECT_DATA.EXTENDED(slaObject);
  }

  return isCopy || isFromChat
    ? SLA_OBJECT_DATA.COPY_OR_CHAT(slaObject)
    : SLA_OBJECT_DATA.DEFAULT_CHANNEL(slaObject);
};

const getSlaObjectParams = (isEnabled, values) => {
  if (!isEnabled) {
    return {
      slaObjectType: 'channel',
      slaObjectId: values.slaObject?.label?.id || null
    };
  }

  const hasSlaObject = values.slaObject?.type && values.slaObject?.item;

  return {
    slaObjectType: hasSlaObject ? values.slaObject.type : null,
    slaObjectId: hasSlaObject ? values.slaObject.item?.label?.id : null
  };
};

export const transformSubmitedValues = async ({
  values,
  activeFields,
  projectId,
  selectedObjectSla,
  validateValidityDateStateValues,
  getTaskInfo,
  isObjectSlaForChannelsEnabled
}) => {
  const isValidValidityDates = validateValidityDateStateValues();
  if (!isValidValidityDates) {
    return null;
  }

  const getRelations = () => {
    let results = [];

    if (activeFields.includes(TYPE_RELATIONS)) {
      results = [...(values.relations || [])];
    }

    return results;
  };

  const taskInfoData = await getTaskInfo({
    channelId: values.slaObject?.label?.id || null,
    contactId: values.contact?.value || null,
    ...getSlaObjectParams(isObjectSlaForChannelsEnabled, values)
  });

  const baseFields = {
    title: values.title,
    dateEnd: values.dateEnd,
    responsible: values.responsible,
    kind: TYPE_REQUEST,
    hours: activeFields.includes(TYPE_TIME) ? values.hours : null,
    minutes: activeFields.includes(TYPE_TIME) ? values.minutes : null,
    approvingManager: null,
    relations: getRelations(),
    dateStart: activeFields.includes(TYPE_DATE_START) ? values.dateStart : null,
    storyPoint: +values.storyPoint || undefined,
    ...values[DESCRIPTION],
    [DESCRIPTION]: (values[DESCRIPTION] || {})[DESCRIPTION] && [
      {
        text: values[DESCRIPTION].description
      },
      ...(values[DESCRIPTION].links || [])
    ],
    ...(values[SCHEDULER]
      ? {
          schedulerConfig: {
            freqInterval: values.freqInterval,
            weekdays: values.weekdays,
            freqType: values.freqType
          }
        }
      : {}),
    taskInfo: {
      ...taskInfoData.taskInfo
    }
  };

  const additionalFields = {
    location: values.location,
    locationExact: values.locationExact,
    tags: values.tags,
    initialTags: values.initialTags,
    isFromTemplate: values.isFromTemplate,
    templateId: values.templateId
  };

  return { ...baseFields, ...additionalFields };
};

export const SLA_SOURCES = {
  CHAT: 'CHAT',
  COPY: 'COPY',
  DEFAULT: 'DEFAULT'
};

export const getSlaSource = (isFromChat, isCopy) => {
  if (isFromChat) return SLA_SOURCES.CHAT;
  if (isCopy) return SLA_SOURCES.COPY;
  return SLA_SOURCES.DEFAULT;
};

const calculateDateEnd = executorSla => {
  if (executorSla === null) return null;
  return new Date(Date.now() + executorSla * 60 * 1000);
};

const handleChatSla = ({
  defaultValues,
  executorSla,
  isSlaRequestFormChat
}) => {
  const isDisabled = !!executorSla || isSlaRequestFormChat;

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const handleCopySla = ({ defaultValues, executorSla }) => {
  const isDisabled =
    !!executorSla ||
    (defaultValues.taskInfo?.slaObject && defaultValues.taskInfo?.executorSla);

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const handleDefaultSla = ({ executorSla, isSlaRequestFormChat }) => {
  const isDisabled = !!executorSla || isSlaRequestFormChat;

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const slaStrategies = {
  [SLA_SOURCES.CHAT]: handleChatSla,
  [SLA_SOURCES.COPY]: handleCopySla,
  [SLA_SOURCES.DEFAULT]: handleDefaultSla
};

export const getDateEndState = ({ source, executorSla, defaultValues }) => {
  const handler = slaStrategies[source];
  if (!handler) {
    return { dateEnd: null, isDisabled: false };
  }

  return handler({ executorSla, defaultValues });
};
