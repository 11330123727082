import React from 'react';

export const TaskLimitedAccessIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="3" fill="#FBA09D" />
    <path
      d="M11.75 7.4375H10.9531V4.8125C10.9531 3.98398 10.2816 3.3125 9.45312 3.3125H6.54688C5.71836 3.3125 5.04688 3.98398 5.04688 4.8125V7.4375H4.25C4.04258 7.4375 3.875 7.60508 3.875 7.8125V12.3125C3.875 12.5199 4.04258 12.6875 4.25 12.6875H11.75C11.9574 12.6875 12.125 12.5199 12.125 12.3125V7.8125C12.125 7.60508 11.9574 7.4375 11.75 7.4375ZM8.32812 10.2148V10.8359C8.32812 10.8875 8.28594 10.9297 8.23438 10.9297H7.76562C7.71406 10.9297 7.67188 10.8875 7.67188 10.8359V10.2148C7.57514 10.1454 7.50294 10.047 7.46566 9.93394C7.42838 9.82084 7.42795 9.69883 7.46443 9.58547C7.50091 9.47211 7.57242 9.37325 7.66866 9.30312C7.7649 9.23299 7.88092 9.1952 8 9.1952C8.11908 9.1952 8.2351 9.23299 8.33134 9.30312C8.42758 9.37325 8.49909 9.47211 8.53557 9.58547C8.57205 9.69883 8.57162 9.82084 8.53434 9.93394C8.49706 10.047 8.42486 10.1454 8.32812 10.2148ZM10.1094 7.4375H5.89062V4.8125C5.89062 4.45039 6.18477 4.15625 6.54688 4.15625H9.45312C9.81523 4.15625 10.1094 4.45039 10.1094 4.8125V7.4375Z"
      fill="white"
    />
  </svg>
);

export default TaskLimitedAccessIcon;
