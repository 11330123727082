import React from 'react';

export const RevokeApiKeyIcon = ({ style = { width: 150, height: 151 } }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width={150}
    height={151}
    fill="none"
  >
    <path
      fill="#EBF8F8"
      d="M70.64 22.265c1.693.319 3.36.717 5.026 1.115 8.107 2.163 16.613 3.928 24.706 5.785l1.293.319c5.494 1.234 10.773 2.508 15.627 3.98 1.733.518 3.386 1.022 5.013 1.606 16.306 5.613 25.892 13.8 17.386 30.108-.734 1.394-1.187 2.84-1.48 4.432-1.133 6.661.906 15.167 2.066 23.66.294 2.03.494 4.033.574 6.103.106 2.376.12 4.724-.12 6.914-.347 3.821-1.44 7.47-3.453 10.894 0 0-2.107 3.397-5.654 6.25-4.266 3.37-52.891 34.394-94.716 7.842-1.694-1.102-42.665-27.919-34.372-65.007 8.053-36.159 54.851-46.429 68.104-44Z"
    />
    <path
      fill="#C4C4C4"
      d="m107.887 65.195-5.927 58.914-.056.514c-.444 3.095-3.484 5.886-8.218 8.009a33.087 33.087 0 0 1-3.456 1.333c-2.235.75-4.706 1.36-7.385 1.805-3.582.624-7.51.958-11.62.958-16.282 0-29.595-5.303-30.678-12.022l-.07-.625-5.927-58.886h73.351-.014Z"
    />
    <path
      fill="#E2E2E2"
      d="m61.605 23.439-21.919 6.01a5.294 5.294 0 0 1-6.497-3.637l-1.124-4.095a5.327 5.327 0 0 1 .542-3.984 5.312 5.312 0 0 1 3.192-2.457l21.92-6.024a5.223 5.223 0 0 1 3.97.5 5.218 5.218 0 0 1 2.457 3.164l1.069 3.873c.222.667.291 1.375.222 2.083a5.386 5.386 0 0 1-.611 2.012 5.187 5.187 0 0 1-3.22 2.555Zm-25.042-5.386a2.56 2.56 0 0 0-1.555 1.207 2.601 2.601 0 0 0-.25 1.958l1.07 3.873a2.56 2.56 0 0 0 1.207 1.554 2.6 2.6 0 0 0 1.957.25l21.92-6.01a2.56 2.56 0 0 0 1.554-1.208c.333-.597.43-1.291.25-1.958l-1.124-4.095a2.56 2.56 0 0 0-1.208-1.554 2.601 2.601 0 0 0-1.958-.25l-21.863 6.233ZM71.226 82.158c21.612 0 39.132-8.216 39.132-18.351 0-10.136-17.52-18.352-39.132-18.352-21.613 0-39.133 8.216-39.133 18.352 0 10.135 17.52 18.351 39.133 18.351Z"
    />
    <path
      fill="#C4C4C4"
      d="M105.041 59.656c0 3.762-3.456 7.163-9.092 9.69a42.69 42.69 0 0 1-3.804 1.47 56.559 56.559 0 0 1-8.12 1.986c-3.943.68-8.26 1.055-12.772 1.055-18.67 0-33.816-6.358-33.816-14.201 0-7.843 15.145-14.2 33.816-14.2 18.671 0 33.816 6.357 33.816 14.2h-.028Z"
    />
    <path
      fill="#E2E2E2"
      d="M71.337 85.74h-.07a4.994 4.994 0 0 0-4.997 4.998v30.914a4.994 4.994 0 0 0 4.997 4.998h.07a4.994 4.994 0 0 0 4.997-4.998V90.738a4.994 4.994 0 0 0-4.997-4.998Z"
    />
    <path
      fill="#FEC458"
      d="M114.761 46.198a15.604 15.604 0 0 0 4.828-15.332 15.612 15.612 0 0 0-26.682-6.906 15.61 15.61 0 0 0-3.217 15.75L70.63 56.508 68.739 69.68l9.85-8.68 1.825 2.076 4.489-3.966-1.825-2.076 2.85-2.498 4.31 4.844 4.516-3.982-4.24-4.813 4.304-3.79a15.58 15.58 0 0 0 19.944-.597Zm-15.919-6.773a7.735 7.735 0 1 1 11.525-10.32 7.735 7.735 0 0 1-11.525 10.32Z"
    />
    <path
      fill="#C4C4C4"
      d="m87.8 13.499 2.346 8.55c.736 2.708-15.686 9.565-36.578 15.298-20.892 5.733-38.521 8.218-39.271 5.511l-2.346-8.55c-.764-2.777 15.59-9.62 36.564-15.368C69.49 13.193 87.037 10.722 87.8 13.5Z"
    />
    <path
      fill="#E2E2E2"
      d="M51.041 28.144c19.532-5.358 34.871-11.508 34.26-13.715-.61-2.207-16.935.319-36.467 5.677-19.531 5.359-34.87 11.508-34.26 13.715.611 2.208 16.936-.319 36.467-5.677ZM92.132 85.81h-.07a5.005 5.005 0 0 0-5.386 4.58l-2.43 30.818a5.006 5.006 0 0 0 4.596 5.386h.07a5.005 5.005 0 0 0 5.385-4.581l2.443-30.818a5.006 5.006 0 0 0-4.594-5.386h-.014ZM53.734 126.705h.07c2.748-.222 4.803-2.637 4.595-5.386l-2.444-30.817a5.017 5.017 0 0 0-5.386-4.595H50.5c-2.749.222-4.803 2.638-4.595 5.386l2.443 30.818a5.016 5.016 0 0 0 5.386 4.594Z"
    />
    <path
      fill="#F9716C"
      d="M25.305 83.589a1.441 1.441 0 0 1-2.027 0L12.354 72.664a1.441 1.441 0 0 1 0-2.027 1.441 1.441 0 0 1 2.026 0l10.925 10.925a1.441 1.441 0 0 1 0 2.027Z"
    />
    <path
      fill="#F9716C"
      d="M25.305 72.664 14.38 83.589a1.441 1.441 0 0 1-2.027 0 1.441 1.441 0 0 1 0-2.027l10.925-10.925a1.441 1.441 0 0 1 2.027 0 1.441 1.441 0 0 1 0 2.027ZM128.709 110.637c-.43.43-1.111.43-1.541 0l-8.273-8.273a1.08 1.08 0 0 1 0-1.541 1.079 1.079 0 0 1 1.54 0l8.274 8.273c.43.431.43 1.111 0 1.541Z"
    />
    <path
      fill="#F9716C"
      d="m128.709 102.364-8.274 8.273c-.43.43-1.11.43-1.54 0a1.08 1.08 0 0 1 0-1.541l8.273-8.273a1.08 1.08 0 0 1 1.541 0c.43.43.43 1.11 0 1.541ZM130.681 74.328a.62.62 0 0 1-.888 0l-4.79-4.79a.62.62 0 0 1 0-.888.62.62 0 0 1 .889 0l4.789 4.79a.62.62 0 0 1 0 .888Z"
    />
    <path
      fill="#F9716C"
      d="m130.681 69.552-4.789 4.79a.621.621 0 0 1-.889 0 .62.62 0 0 1 0-.889l4.79-4.789a.62.62 0 0 1 .888 0 .62.62 0 0 1 0 .888Z"
    />
  </svg>
);
