import React from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/common/tooltip';
import Button from 'components/common/button';

import { AgentSupportIconWrap } from '../agent-support-icon-wrap';

import styles from './AgentSupportAction.module.scss';

export const AgentSupportAction = ({ onClick }) => {
  const { t } = useTranslation('AgentSupport');

  return (
    <Tooltip title={t('AgentSupportTitle')} mouseEnterDelay={0.5}>
      <Button
        type="text"
        mood="none"
        className={styles.actionButton}
        data-qa="qa-je3dfom1ewdx3y4"
        onClick={onClick}
      >
        <AgentSupportIconWrap />
      </Button>
    </Tooltip>
  );
};
