import { useEffect } from 'react';

import { useMountedState } from 'hooks';

import useTaskInfo from './useRequestsTaskInfo';
import { adaptSlaData } from '../helpers';

const useSlaAndChannelLogic = ({
  slaObject,
  isObjectSlaForChannelsEnabled,
  isCopy,
  isFromChat
}) => {
  const [executorSla, setExecutorSla] = useMountedState(null);
  const { getTaskInfo } = useTaskInfo();

  useEffect(() => {
    const fetchSla = async () => {
      const adaptedSla = adaptSlaData({
        slaObject,
        isObjectSlaForChannelsEnabled,
        isCopy,
        isFromChat
      });

      if (adaptedSla?.id) {
        const taskInfo = await getTaskInfo({
          channelId: adaptedSla?.id,
          slaObjectType: adaptedSla.type,
          contactId: null
        });

        setExecutorSla(taskInfo.taskInfo.executorSla || null);
      } else {
        setExecutorSla(null);
      }
    };

    fetchSla();
  }, [slaObject, isObjectSlaForChannelsEnabled]);

  return { executorSla };
};

export default useSlaAndChannelLogic;
