import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { push } from 'connected-react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { DATE_FORMAT, SELECTED_EMPLOYEE } from 'constants/index';

import { getRouterState } from 'store/router';
import {
  getDepartmentEmployeeFactory,
  setNeedRefetchDepartments,
  updateDepartmentEmployee
} from 'store/team/departments';
import {
  fetchEmployeeLocal,
  getEmployeeFactory,
  updateEmployee
} from 'store/team/employees';
import { getUserEmployee, updateWorkspaceUser } from 'store/workspace';

import useModalsService from 'services/modals';
import { useQueryParam } from 'hooks';
import getHasOwnerRole from 'utils/get-has-owner-role';
import { showNoticeMessage } from 'services/notice';
import { reconnectSockets } from 'socket';

const useEditor = () => {
  const dispatch = useDispatch();

  const { departmentId, employeeId, isFromDepartment } = useSelector(
    getRouterState
  );
  const departmentEmployee = useSelector(state =>
    getDepartmentEmployeeFactory(state)(departmentId, employeeId)
  );
  const selectedEmployee = useSelector(state =>
    getEmployeeFactory(state)(employeeId)
  );
  const userEmployee = useSelector(getUserEmployee);

  const [isLoading, setIsLoading] = useState(false);
  const [visibleOnboardingModal, setVisibleOnboardingModal] = useState(false);

  const [defaultValues, setDefaultValues] = useState(null);

  // const [visiblePhoneConfirmation, setVisiblePhoneConfirmation] = useState(
  //   false
  // );

  // const [submitData, setSubmitData] = useState({});

  const location = useLocation();
  const modals = useModalsService();
  const id = Number(useQueryParam(SELECTED_EMPLOYEE));

  const isOwner = getHasOwnerRole(userEmployee.roles);
  const { isAfterRegister, isCreateTeam } = location.state || {};

  const handleDefaultValues = async () => {
    let employeeData =
      (isFromDepartment ? departmentEmployee : selectedEmployee) || {};

    if (id && isEmpty(employeeData)) {
      employeeData = await dispatch(fetchEmployeeLocal({ id }));
    }

    if (!id) {
      employeeData = userEmployee;
    }

    const {
      email,
      phone,
      position,
      contractDuration,
      departmentId: dpId
    } = employeeData;
    const formattedContractDuration = contractDuration
      ? moment(contractDuration, DATE_FORMAT).toDate()
      : null;

    setDefaultValues({
      email,
      phone,
      position,
      departmentId: dpId,
      contractDuration: formattedContractDuration,
      isRegistrationDataSelect: false
    });
  };

  const onSubmit = async values => {
    const { email, phone, position, contractDuration } = values;
    const formattedContractDuration = contractDuration
      ? moment(contractDuration).format(DATE_FORMAT)
      : null;

    try {
      setIsLoading(true);

      if (id) {
        await dispatch(
          updateEmployee({
            employee: {
              id,
              email,
              phone,
              position,
              contractDuration: formattedContractDuration
            },
            departmentId: defaultValues.departmentId
          })
        ).then(data => dispatch(updateDepartmentEmployee(data)));
      }

      if (!id && userEmployee) {
        await dispatch(
          updateWorkspaceUser({
            user: {
              id: userEmployee.id,
              email,
              phone,
              position,
              contractDuration: formattedContractDuration,
              afterRegistration: isAfterRegister
            }
          })
        );
      }

      dispatch(setNeedRefetchDepartments(true));

      showNoticeMessage();

      modals.close(location.state);

      if (isAfterRegister) {
        reconnectSockets();
      }

      if (isAfterRegister && isCreateTeam && isOwner) {
        setVisibleOnboardingModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseOnboardingModal = () => {
    setVisibleOnboardingModal(false);
    dispatch(push({ isAfterRegister: false }));
  };

  // eslint-disable-next-line arrow-body-style
  const handleSubmit = newValues => {
    // if (
    //   !workspace.isNotVerified &&
    //   employee.isPhoneVerified &&
    //   newValues.phone.replace(/[- )(]/g, '') !== employee.phone &&
    //   id
    // ) {
    //   setSubmitData(newValues);
    //   return setVisiblePhoneConfirmation(true);
    // }

    return onSubmit(newValues);
  };

  return {
    defaultValues,
    isAfterRegister,
    isLoading,
    visibleOnboardingModal,
    onCloseOnboardingModal,
    handleDefaultValues,
    setDefaultValues,
    handleSubmit
  };
};

export default useEditor;
