import { useLayoutEffect, useRef } from 'react';

export const useHistoryActions = ({
  messagesRef,
  sortedMessages,
  historyActionsState,
  setHistoryActionsState,
  isAllHistoryActionCollapsed,
  isLoading,
  historyActionRefs
}) => {
  const messageToHistoryActionMap = useRef({});

  const toggleHistoryAction = ({ key, lastLog }) => {
    const groupId = key;
    setHistoryActionsState(prevState => {
      const newState = {
        ...prevState,
        [groupId]: !prevState[groupId]
      };

      const isLastMessageLogInChat =
        sortedMessages[sortedMessages.length - 1].uuid === lastLog.uuid;

      if (newState[groupId] && messagesRef.current && isLastMessageLogInChat) {
        setTimeout(() => {
          messagesRef.current.scrollTo({
            top: messagesRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }, 300);
      }

      return newState;
    });
  };

  useLayoutEffect(() => {
    if (historyActionRefs?.current && !isLoading) {
      const newGroups = {};
      Object.keys(historyActionRefs.current).forEach(key => {
        if (historyActionsState?.[key] === undefined) {
          newGroups[key] = isAllHistoryActionCollapsed;
        }
      });

      if (Object.keys(newGroups).length > 0) {
        setHistoryActionsState(prevState => ({
          ...prevState,
          ...newGroups
        }));
      }
    }
  }, [isLoading, historyActionRefs?.current, isAllHistoryActionCollapsed]);

  return {
    toggleHistoryAction,
    messageToHistoryActionMap
  };
};
