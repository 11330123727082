import { useMemo } from 'react';

import { getDateEndState, getSlaSource } from '../helpers';

export const useDateEndChangeWithSla = (
  executorSla,
  isFromChat,
  isCopy,
  defaultValues
) => {
  const source = getSlaSource(isFromChat, isCopy);

  return useMemo(
    () =>
      getDateEndState({
        source,
        executorSla,
        defaultValues
      }),
    [executorSla]
  );
};
export default useDateEndChangeWithSla;
