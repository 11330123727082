import { TYPE_ASSET, TYPE_ORDER, TYPE_ORDER_STATUS } from 'constants/index';

export const getTooltipTitle = ({ type, title, fullPath, objectId }) => {
  switch (type) {
    case TYPE_ASSET:
      return `${title} ${fullPath || ''}`;
    case TYPE_ORDER:
    case TYPE_ORDER_STATUS:
      return title;
    default:
      return `${objectId} ${title}`;
  }
};
