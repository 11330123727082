import convertToMinutes from 'utils/convert-to-minutes';

export const transformSlaConfigValues = (editorValues = {}) => {
  const convertToHoursAndMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  };

  const operatorSla = editorValues
    ? convertToHoursAndMinutes(editorValues.operatorSla || 0)
    : { hours: 0, minutes: 0 };

  const executorSla = editorValues
    ? convertToHoursAndMinutes(editorValues.executorSla || 0)
    : { hours: 0, minutes: 0 };

  return {
    isActive: editorValues && !!editorValues.isActive,
    autoreplyText: editorValues?.autoreplyText || null,
    operatorSlaHours: operatorSla.hours || null,
    operatorSlaMinutes: operatorSla.minutes || null,
    executorSlaHours: executorSla.hours || null,
    executorSlaMinutes: executorSla.minutes || null
  };
};

export const transformSubmitSlaConfigValues = values => {
  const {
    operatorSlaHours,
    operatorSlaMinutes,
    executorSlaHours,
    executorSlaMinutes
  } = values;

  return {
    isActive: values.isActive,
    autoreplyText: values.autoreplyText || null,
    operatorSla: convertToMinutes({
      hours: operatorSlaHours,
      minutes: operatorSlaMinutes
    }),
    executorSla: convertToMinutes({
      hours: executorSlaHours,
      minutes: executorSlaMinutes
    })
  };
};
